import React from 'react';
import { Redirect } from 'react-router-dom';
import { firebaseAuth } from '../firebase';
import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';
import { Grid } from '@material-ui/core';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useAuth } from '../AuthContext';

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
    },
    'apple.com',
  ],
};

export default function Login() {
  const { authUser } = useAuth();
  if (authUser) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <Grid sm={1} />
      <Grid item xs={12} sm={10}>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebaseAuth}
        />
      </Grid>
    </>
  );
}
