import { combineReducers } from 'redux';

import settings from './settings';

const rootReducer = combineReducers({
  settings
});

export type ReduxState = ReturnType<typeof rootReducer>;

export default rootReducer;