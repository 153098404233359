import React, { useState } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

interface Props extends Omit<ButtonProps, 'onClick'> {
  onSubmit: () => Promise<string|undefined>;
}

export default function SubmitButton({ children, disabled, onSubmit, ...otherProps}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const classes = useStyles();
  const handleClick = async () => {
    setSubmitting(true);
    try {
      const successMessage = await onSubmit();
      if (successMessage) {
        enqueueSnackbar(successMessage, { variant: 'success', autoHideDuration: 3000 });
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 8000 });
    }
    setSubmitting(false);
  };
  return (
    <Button
      {...otherProps}
      disabled={disabled || submitting}
      onClick={handleClick}
      className={classes.wrapper}
    >
      { children }
      {submitting && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
    </Button>
  );
}