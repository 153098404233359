import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Button, Checkbox, FormControlLabel, FormControl, Grid, InputLabel, Paper, Select, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { add as addDate, format as formatDate } from 'date-fns';
import { t } from 'ttag.macro';
import { apiFunction } from '../lib/api';
import { useAuth } from '../AuthContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: 350,
  },
}));

const SUPPORT_PRIVATE_EVENTS = false;

function CreateEventForm() {
  const classes = useStyles();
  const [eventRedirect, setEventRedirect] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [discordWebhook, setDiscordWebhook] = useState('');
  const [error, setError] = useState('');
  const [publicEvent, setPublicEvent] = useState(true);
  const [name, setName] = useState('');
  const [eventType, setEventType] = useState<'blob' | 'war' | 'blobg'>('blob');
  const [description, setDescription] = useState('');
  const [password, setPassword] = useState('');
  const [scheduledStart, setScheduledStart] = useState(addDate(new Date(), { days: 7 }));
  const handleSubmit = async (e: React.FormEvent) => {
    setSubmitting(true);
    if (error) {
      setError('');
    }
    e.preventDefault();
    const createEvent = apiFunction('event-createEvent');
    const data = {
      name,
      scheduledStart: scheduledStart.getTime(),
      description,
      publicEvent,
      password,
      discordWebhook,
      eventType,
    };
    const result = await createEvent(data);
    const {
      eventId,
    } = result.data;
    if (result.data.error) {
      setError(result.data.error);
    } else {
      setEventRedirect(eventId);
    }
  };
  const handleEventTypeChange = (event: React.ChangeEvent<{ value: any}>) => {
    setEventType(event.target.value);
  };
  const handlePublicEvent = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPublicEvent(checked);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleDiscordWebhookChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscordWebhook(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduledStart(new Date(Date.parse(event.target.value)));
  };

  if (eventRedirect) {
    return (
      <Redirect to={`/event/${eventRedirect}`} />
    );
  }

  return (
    <>
      <Grid item sm={1} />
      <Grid item xs={12} sm={10}>
        <Box color="text.primary"  component="span" m={1} justifyContent="center">
          <Paper>
            <Box p={2}>
              <Typography variant="h4" gutterBottom>
                { t`Host your own Epic Multiplayer event`}
              </Typography>
              <Typography variant="body1" gutterBottom>
                { t`This site allows you to host an Epic Multiplayer events for Arkham Horror: The Card Game.` }
              </Typography>
              <Typography variant="body1" gutterBottom>
                { t`As the organizer, you will be need to setup communication channels with your players (we suggest Discord).` }
              </Typography>
              <Typography variant="body1" gutterBottom>
                { t`This site will allow players to:`}
              </Typography>
              <ul>
                <li>{ t`Pre-register for the event with tentative table size.` }</li>
                <li>{ t`Confirm registration up to an hour before the event.` }</li>
                <li>{ t`Start the game and keep track of time, while also reporting shared game state.` }</li>
              </ul>
              <form className={classes.container} onSubmit={handleSubmit}>
                <FormControl>
                  <InputLabel required className={classes.textField}>
                    { t`Event scenario` }
                  </InputLabel>
                  <Select
                    value={eventType}
                    className={classes.textField}
                    onChange={handleEventTypeChange}
                  >
                    <MenuItem value="blob">
                      <em>{ t`The Blob That Ate Everything` }</em>
                    </MenuItem>
                    <MenuItem value="war">
                      <em>{ t`War of the Outer Gods` }</em>
                    </MenuItem>
                    <MenuItem value="blobg">
                      <em>{ t`The Blob That Ate Greenville` }</em>
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  id="name"
                  label={t`Event name`}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={name}
                  onChange={handleNameChange}
                />
                { SUPPORT_PRIVATE_EVENTS && (
                  <FormControlLabel
                    className={classes.textField}
                    control={<Checkbox checked={publicEvent} onChange={handlePublicEvent} name="public" />}
                    label={t`Public event`}
                  />
                ) }
                { SUPPORT_PRIVATE_EVENTS && !publicEvent && (
                  <TextField
                    margin="normal"
                    required={!publicEvent}
                    fullWidth
                    id="password"
                    label={t`Registration password`}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={password}
                    onChange={handlePasswordChange}
                  />
                ) }
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  id="description"
                  label={t`Description (supports markdown)`}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="discordWebhook"
                  label={t`Discord webhook (for announcements)`}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  value={discordWebhook}
                  onChange={handleDiscordWebhookChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="scheduledStart"
                  label={t`Scheduled start time`}
                  type="datetime-local"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formatDate(scheduledStart, 'yyyy-MM-dd\'T\'HH:mm')}
                  onChange={handleDateChange}
                />
                { !!error && <h6>{error}</h6> }
                <Box mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.textField}
                    disabled={submitting}
                  >
                    { t`Create event`}
                  </Button>
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </>
  );
}

export default function CreateEvent() {
  const { loading, authUser } = useAuth();
  if (loading) {
    return null;
  }
  if (!authUser) {
    return <Redirect to="/login" />;
  }
  return <CreateEventForm />;
}