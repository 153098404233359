
import React, { useEffect, useState, useMemo, useCallback, useRef, useContext } from 'react';
import { filter, forEach, map, reverse, sortBy } from 'lodash';
import { Box, Divider, Grid, IconButton, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { ref, child, set, remove, onChildAdded, Unsubscribe } from 'firebase/database';
import { useSnackbar, SnackbarKey } from 'notistack';
import { t } from 'ttag.macro';

import { firebaseDatabase } from '../../../firebase';
import { timeString } from '../../../lib/date';
import { ArkhamEvent, BasicGameState, GameAnnouncement, GameAnnouncementCode, PersonalWarAnnouncement, PersonalWarAnnouncementType } from '../../../lib/schema';
import MediaCard from '../../core/MediaCard';
import SubmitButton from '../../core/SubmitButton';
import useStyles from './styles';
import CollapseList from './CollapseList';
import { apiFunction } from '../../../lib/api';
import { LocaleContext } from '../../core/LocaleContext';

interface Props {
  eventId: string;
  event: ArkhamEvent;
  isOrganizer: boolean;
  gameState: BasicGameState;
  uid?: string;
  cardWidth?: 12 | 6 | 4;
  children?: React.ReactNode | React.ReactNode[];
}


async function deleteAnnouncement(eventId: string, gameState: BasicGameState, gameAnnouncement: GameAnnouncement) {
  const announcements = filter(gameState.announcements || [], a => (
    a.text !== gameAnnouncement.text || a.time !== gameAnnouncement.time || a.code !== gameAnnouncement.code
  ));
  if (announcements.length) {
    await set(child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'announcements'), announcements);
  } else {
    await remove(child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'announcements'));
  }
}

function gameAnnouncementText(announcement: GameAnnouncement, warGoo?: string): string {
  if (!announcement.code) {
    return announcement.text;
  }
  switch (announcement.code) {
    case GameAnnouncementCode.BLOB_DEAD:
      return t`Ding dong the Blob is dead!`;
    case GameAnnouncementCode.WAR_ADVANCE_RED_1:
      return t`Red agenda 1a has advanced. Please advance to red agenda 1b during your next Mythos Phase`;
    case GameAnnouncementCode.WAR_ADVANCE_RED_2:
      return t`Red agenda 2a has advanced. Please advance to red agenda 2b during your next Mythos Phase`;
    case GameAnnouncementCode.WAR_ADVANCE_RED_3:
      return t`Red agenda 3a has advanced. Please advance to red agenda 3b during your next Mythos Phase and prepare for the arrival of Ezel-zen-rezl!`
    case GameAnnouncementCode.WAR_ADVANCE_GREEN_1:
      return t`Green agenda 1a has advanced. Please advance to green agenda 1b during your next Mythos Phase`;
    case GameAnnouncementCode.WAR_ADVANCE_GREEN_2:
      return t`Green agenda 2a has advanced. Please advance to green agenda 2b during your next Mythos Phase`;
    case GameAnnouncementCode.WAR_ADVANCE_GREEN_3:
      return t`Green agenda 3a has advanced. Please advance to green agenda 3b during your next Mythos Phase and prepare for the arrival of Magh’an Ark’at!`
    case GameAnnouncementCode.WAR_ADVANCE_BLUE_1:
      return t`Blue agenda 1a has advanced. Please advance to blue agenda 1b during your next Mythos Phase`;
    case GameAnnouncementCode.WAR_ADVANCE_BLUE_2:
      return t`Blue agenda 2a has advanced. Please advance to blue agenda 2b during your next Mythos Phase`;
    case GameAnnouncementCode.WAR_ADVANCE_BLUE_3:
      return t`Blue agenda 3a has advanced. Please advance to blue agenda 3b during your next Mythos Phase and prepare for the arrival of Silenus!`
    case GameAnnouncementCode.WAR_GOO_DEAD:
      if (warGoo) {
        switch (warGoo) {
          case 'red':
            return t`Ezel-zen-rezl has been defeated! The investigators win the game!`;
          case 'green':
            return t`Magh’an Ark’at has been defeated! The investigators win the game!`;
          case 'blue':
            return `Silenus has been defeated! The investigators win the game!`;
        }
      }
      return 'Unknown';
  }
}

function AnnouncementItem({ isOrganizer, announcement, eventId, event }: {
  isOrganizer: boolean;
  eventId: string;
  announcement: GameAnnouncement;
  event: ArkhamEvent;
}) {
  const { locale } = useContext(LocaleContext);
  const deleteAnnouncementClick = useCallback(() => {
    if (event.gameState) {
      return deleteAnnouncement(eventId, event.gameState, announcement);
    }
  }, [eventId, announcement, event.gameState]);
  const time = useMemo(() => announcement.time && new Date(announcement.time), [announcement.time]);
  const warGoo = event.gameType === 'war' ? event.gameState?.goo : undefined;
  const text = useMemo(() => gameAnnouncementText(announcement, warGoo), [announcement, warGoo]);
  return (
    <ListItem>
      <ListItemText
        primary={text}
        secondary={time && timeString(time, locale)}
      />
      { isOrganizer && (
      <ListItemSecondaryAction>
        <IconButton aria-label="delete" onClick={deleteAnnouncementClick}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
      ) }
    </ListItem>
  );
}

function AddAnnouncementControl({ eventId }: { eventId: string }) {
  const [announcement, setAnnouncement] = useState('');

  const handleAnnouncementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnnouncement(event.target.value);
  };

  const onSendAnnouncement = async () => {
    const sendAnnouncementFunction = apiFunction('event-sendAnnouncement');
    await sendAnnouncementFunction({ eventId, message: announcement });
    setAnnouncement('');
    return t`Announcement sent to players.`;
  };

  return (
    <Box mb={2}>
      <Box my={2}>
        <Divider />
      </Box>
      <Typography color="textSecondary">
        { t`Send Announcement` }
      </Typography>
      <TextField
        margin="normal"
        fullWidth
        id="announcement"
        InputLabelProps={{
          shrink: true,
        }}
        value={announcement}
        onChange={handleAnnouncementChange}
      />
      { announcement && (
        <SubmitButton
          variant="outlined"
          color="secondary"
          onSubmit={onSendAnnouncement}
          disabled={announcement.length < 5}
        >
          { t`Send Announcement` }
        </SubmitButton>
      ) }
    </Box>
  );
}


function SnackbarAction({ key }: { key: SnackbarKey }) {
  const { closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const onClick = () => closeSnackbar(key);
  return (
    <IconButton aria-label="close" onClick={onClick} className={classes.closeButton}>
      <CloseIcon />
    </IconButton>
  );
};

function renderSnackbarAction(key: SnackbarKey) {
  return <SnackbarAction key={key} />;
}

function personalAnnouncementMessage(type: PersonalWarAnnouncementType, groupName?: string): string {
  switch (type) {
    case PersonalWarAnnouncementType.CLUE:
      return groupName ?
          t`Received a clue from ${groupName}. Please add to your copy of Hub Dimension.` :
          t`Received a clue to add to your copy of Hub Dimension.`;
    case PersonalWarAnnouncementType.HELP_NY:
      return groupName ?
        t`Received help in New York from ${groupName}. Deal 3 damage to a non-Ancient One enemy at a "New York" location.` :
        t`Received help in New York. Deal 3 damage to a non-Ancient One enemy at a "New York" location.`;
    case PersonalWarAnnouncementType.HELP_MN:
      return groupName ?
        t`Received help in Montréal from ${groupName}. Deal 3 damage to a non-Ancient One enemy at a "Montréal" location.` :
        t`Received help in Montréal. Deal 3 damage to a non-Ancient One enemy at a "Montréal" location.`;
    case PersonalWarAnnouncementType.HELP_PV:
      return groupName ?
        t`Received help in Providence from ${groupName}. Deal 3 damage to a non-Ancient One enemy at a "Providence" location.` :
        t`Received help in Providence. Deal 3 damage to a non-Ancient One enemy at a "Providence" location.`;
  }
}

export default function AnnouncementBlock({ event, eventId, gameState, isOrganizer, uid, cardWidth=6, children }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const participantMap = useMemo(() => {
    const participantMap: { [key: string]: string | undefined } = {};
    forEach(event.participants || {}, (p, id) => {
      participantMap[p.user] = p.groupName;
    });
    return participantMap;
  }, [event.participants]);
  const personalAnnouncementAdded = useCallback((startTime: Date, announcement: PersonalWarAnnouncement) => {
    if (announcement.to === uid && announcement.time > startTime.getTime()) {
      const groupName = participantMap[announcement.from];
      const text = personalAnnouncementMessage(announcement.type, groupName);
      enqueueSnackbar(text, { variant: 'info', persist: true, action: renderSnackbarAction });
    }
  }, [participantMap, uid, enqueueSnackbar]);
  const personalAnnouncementAddedRef = useRef(personalAnnouncementAdded);
  useEffect(() => {
    personalAnnouncementAddedRef.current = personalAnnouncementAdded;
  }, [personalAnnouncementAdded]);

  const warGoo = event.gameType === 'war' ? event.gameState?.goo : undefined;
  const announcementAdded = useCallback((startTime: Date, announcement: GameAnnouncement) => {
    if (isOrganizer) {
      return;
    }
    if (announcement.time > startTime.getTime()) {
      const text = gameAnnouncementText(announcement, warGoo);
      enqueueSnackbar(text, { variant: 'info', persist: true, action: renderSnackbarAction });
    }
  }, [isOrganizer, enqueueSnackbar, warGoo]);
  const announcementAddedRef = useRef(announcementAdded);
  useEffect(() => {
    announcementAddedRef.current = announcementAdded;
  }, [announcementAdded]);

  useEffect(() => {
    const startTime = new Date();
    const listeners: Unsubscribe[] = [];
    const refAnnouncement = child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'announcements');
    listeners.push(
      onChildAdded(refAnnouncement, child => {
        const announcement: GameAnnouncement = child.val();
        announcementAddedRef.current(startTime, announcement);
      })
    );
    if (event.gameType === 'war') {
      const warAnnouncementRef = child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'warAnnouncements');
      listeners.push(
        onChildAdded(warAnnouncementRef, child => {
          const announcement: PersonalWarAnnouncement = child.val();
          personalAnnouncementAddedRef.current(startTime, announcement);
        })
      );
    }
    return () => {
      forEach(listeners, unsubscribe => {
        unsubscribe();
      });
    };
  }, [eventId, event.gameType, announcementAddedRef, personalAnnouncementAddedRef]);

  const gameAnnouncements = useMemo(() => {
    if (event.gameType === 'war' && event.gameState) {
      return map(
        filter(event.gameState.warAnnouncements, announcement => announcement.to === uid),
        announcement => {
          const groupName = participantMap[announcement.from];
          return {
            text: personalAnnouncementMessage(announcement.type, groupName),
            time: announcement.time,
          };
        }
      );
    }
    return [];
  }, [uid, event.gameState, event.gameType, participantMap]);
  const allAnnouncements = useMemo(() => {
    return sortBy([...(gameState.announcements || []), ...gameAnnouncements], e => e.time);
  }, [gameState.announcements, gameAnnouncements])
  return (
    <Grid item xs={12} sm={12} md={cardWidth}>
      <Box m={2}>
        <MediaCard
          image={event.gameType === 'war' ? '/img/war_of_the_outer_gods.jpg' : '/img/blob_car.png' }
          imageTitle={event.gameType === 'war' ?
            t`A worried man and women look towards the sky.` :
            t`A man and women runs from a looming blob.`
          }
        >
          <CollapseList title={t`Announcements`}>
            {reverse(map(allAnnouncements, (announcement, idx) => (
              <AnnouncementItem
                key={idx}
                eventId={eventId}
                event={event}
                announcement={announcement}
                isOrganizer={isOrganizer}
              />
            ))) }
          </CollapseList>
          { isOrganizer && (
            <AddAnnouncementControl eventId={eventId} />
          ) }
          { !!children && children }
        </MediaCard>
      </Box>
    </Grid>
  );
}