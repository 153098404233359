import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find, sumBy, forEach, partition, map } from 'lodash';
import { ref, child, set } from 'firebase/database';
import { Box, Button,  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Divider, Grid,
  ListItem, ListItemText, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ngettext, msgid } from 'ttag.macro';
import { add as addDate, differenceInMinutes } from 'date-fns';
import { t } from 'ttag.macro';

import { firebaseDatabase } from '../../../firebase';
import { ArkhamEvent, Participant, BlobGameState, WarGameState, EventSecret } from '../../../lib/schema';
import SubmitButton from '../../core/SubmitButton';
import GroupAndPlayerCounts from '../GroupAndPlayerCounts';
import CollapseList from './CollapseList';
import { useObjectVal } from 'react-firebase-hooks/database';
import { apiFunction } from '../../../lib/api';

interface Props {
  eventId: string;
  event: ArkhamEvent;
  uid?: string;
}

function playerString(playerCount: number): string {
  return ngettext(
    msgid`${playerCount} player`,
    `${playerCount} players`,
    playerCount
  );
}

const useStyles = makeStyles((theme) => ({
  row: {
    flexDirection: 'row',
  },
  card: {
    minWidth: 350,
    maxWidth: 400,
  },
  dialog: {
    minWidth: 350,
  },
  media: {
    height: 140,
  },
  paper: {
    position: 'absolute',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    minWidth: 200,
  },
}));

function RegisterGroupButton({ event, eventId, uid }: Props) {
  const classes = useStyles();
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [groupSize, setGroupSize] = useState(2);
  const [discordHandle,  setDiscordHandle] = useState('');
  const [groupName, setGroupName] = useState('');
  const handleDiscordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscordHandle(event.target.value);
  };
  const showRegisterModal = () => {
    setRegisterModalOpen(true);
  };
  const hideRegisterModal = () => {
    setRegisterModalOpen(false);
  };

  const handleGroupSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupSize(parseInt(event.target.value, 10));
  };

  const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  const registerGroup = async () => {
    const joinEvent = apiFunction('event-joinEvent');
    const data = {
      eventId,
      groupSize,
      groupName,
      discordHandle,
    };
    const result = await joinEvent(data);
    const {
      error,
    } = result.data;
    if (error) {
      throw new Error(error);
    }
    hideRegisterModal();
    return t`Registration saved.`;
  };

  if (!uid) {
    return (
      <Box mt={2}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/login"
          onClick={showRegisterModal}
        >
          { t`Login to register`}
        </Button>
      </Box>
    );
  }

  const dialog = (
    <Dialog
      open={registerModalOpen}
      onClose={hideRegisterModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogTitle>
        { t`Register group` }
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          { t`Please confirm your registration an hour before the event starts, so the event organizer has an accurate player count.`}
        </DialogContentText>
        <Box mb={1}>
          <TextField
            margin="normal"
            label={t`Player Count`}
            type="number"
            fullWidth
            className={classes.textField}
            InputProps={{
              inputProps: {
                min: 1,
                max: 4,
              }
            }}
            value={groupSize}
            onChange={handleGroupSizeChange}
          />
        </Box>
        <Box mb={1}>
          <TextField
            margin="normal"
            label={t`Group name`}
            fullWidth
            required
            className={classes.textField}
            value={groupName}
            onChange={handleGroupNameChange}
          />
        </Box>
        <Box mb={1}>
          <TextField
            margin="normal"
            label={t`Discord handle (or other identifier for organizer)`}
            fullWidth
            required
            className={classes.textField}
            value={discordHandle}
            onChange={handleDiscordChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <SubmitButton
          fullWidth
          variant="contained"
          color="secondary"
          onSubmit={registerGroup}
          type="submit"
          disabled={event.signUpLocked || groupSize > 4 || groupSize < 1 || !discordHandle || !groupName}
        >
          {event.signUpLocked ? t`Sign-up has been disabled` : t`Register`}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={showRegisterModal}
      >
        { t`Register group` }
      </Button>
      {dialog}
    </>
  );
}

function EditRegistrationSection({ event, eventId, uid, registration }: Props & {
  registration: Participant;
}) {
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [groupSize, setGroupSize] = useState(registration.groupSize);
  const [groupName, setGroupName] = useState(registration.groupName);
  const showCancelModal = () => {
    setCancelModalOpen(true);
  };
  const hideCancelModal = () => {
    setCancelModalOpen(false);
  };

  const showConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const hideConfirmModal = async () => {
    setConfirmModalOpen(false);
  };

  const handleGroupSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupSize(parseInt(event.target.value, 10));
  };

  const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  const updateGroupSize = async () => {
    const joinEvent = apiFunction('event-joinEvent');
    const data = {
      eventId,
      groupSize,
    };
    const result = await joinEvent(data);
    const {
      error,
    } = result.data;
    if (error) {
      throw new Error(error);
    }
    return undefined;
  };

  const cancelRegistration = async () => {
    const leaveEvent = apiFunction('event-leaveEvent');
    const data = {
      eventId,
    };
    const result = await leaveEvent(data);
    const {
      error,
    } = result.data;
    if (error) {
      throw new Error(error);
    } else {
      hideCancelModal();
    }
    return undefined;
  };

  const confirmRegistration = async () => {
    const confirmEventParticipant = apiFunction('event-confirmEventParticipant');
    const data = {
      eventId,
      groupSize: registration.groupSize,
    };
    const result = await confirmEventParticipant(data);
    const {
      error,
    } = result.data;
    if (error) {
      throw new Error(error);
    } else {
      hideConfirmModal();
    }
    return undefined;
  };

  const cancelDialog = (
    <Dialog
      open={cancelModalOpen}
      onClose={hideCancelModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogTitle>
        { t`Confirm cancellation` }
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          { t`Are you sure you want to cancel your registration for this event?` }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SubmitButton
          fullWidth
          variant="contained"
          color="secondary"
          onSubmit={cancelRegistration}
          type="submit"
        >
          { t`Cancel registration` }
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
  const now = new Date();
  const startingSoon = differenceInMinutes(new Date(event.scheduledStart), now) < 60;
  const groupSizeString = playerString(registration.groupSize);
  const confirmDialog = (
    <Dialog
      open={confirmModalOpen}
      onClose={hideConfirmModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {
        startingSoon ? (
          <>
            <DialogTitle>
              { t`Confirm registration` }
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                { t`Your current investigator count is ${groupSizeString}. You may update this before confirming if that is incorrect.` }.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SubmitButton
                fullWidth
                variant="contained"
                color="secondary"
                onSubmit={confirmRegistration}
                type="submit"
              >
                { t`Confirm registration` }
              </SubmitButton>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>
              { t`Try again later.`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                { t`Registration can only be confirmed an hour before the event starts.`}
              </DialogContentText>
              <DialogContentText>
                { t`Please try again closer to the scheduled start time.` }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={hideConfirmModal}
                type="submit"
              >
                { t`Okay` }
              </Button>
            </DialogActions>
          </>
        )
      }
    </Dialog>
  );


  return (
    <>
      <Divider />
      <Box m={2}>
        <Grid container alignItems="center" direction="row" spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="groupName"
              label={t`Group name`}
              InputLabelProps={{
                shrink: true,
              }}
              value={groupName}
              onChange={handleGroupNameChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="groupSize"
              label={t`Your group's player count`}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 4,
                }
              }}
              value={groupSize}
              onChange={handleGroupSizeChange}
            />
          </Grid>
          { (groupSize !== registration.groupSize || groupName !== registration.groupName) && (
            <Grid item xs={12} sm={6}>
              <SubmitButton
                variant="contained"
                color="secondary"
                onSubmit={updateGroupSize}
                disabled={groupSize > 4 || groupSize < 1 || !groupName}
              >
                { t`Update registration` }
              </SubmitButton>
            </Grid>
          ) }
        </Grid>
        { !registration.confirmedAt && (
          <>
            <Box mt={2}>
              <Typography>{ startingSoon ?
                t`Group confirmation is now open, you can confirm your group's player count now.`:
                t`You will need to confirm your group one hour before the scheduled start time.`}</Typography>
              <Typography>{ t`If the event starts before you confirm, it will not be possible to join late.` }</Typography>
            </Box>
            <Box mt={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={showConfirmModal}
              >
                { t`Confirm registration` }
              </Button>
            </Box>
          </>
        ) }
        <Box mt={2}>
          <Button
            variant="outlined"
            color="default"
            onClick={showCancelModal}
          >
            { t`Cancel registration` }
          </Button>
        </Box>
        {confirmDialog}
        {cancelDialog}
      </Box>
    </>
  );
}


function StartGameControls({ event, eventId }: Props) {
  const [eventSecret] = useObjectVal<EventSecret>(
    child(ref(firebaseDatabase, '/blob_secrets/'), eventId)
  );

  const renderParticipant = useCallback((participant: Participant) => {
    const contactInfo = ((eventSecret && eventSecret.participantContact) || {})[participant.user] || 'No contact info';
    return (
      <ListItem key={participant.user}>
        <ListItemText primary={`${participant.groupName} (${contactInfo})`} />
        <ListItemText secondary={ngettext(msgid`${participant.groupSize} player`, `${participant.groupSize} players`, participant.groupSize)} />
      </ListItem>
    );
  }, [eventSecret]);
  const classes = useStyles();
  const [startModalOpen, setStartModalOpen] = useState(false);
  const [durationMinutes, setDurationMinutes] = useState(180);
  const [confirmedGroups, unconfirmedGroups] = useMemo(() => {
    return partition(event.participants || [], p => !!p.confirmedAt);
  }, [event.participants]);
  const confirmedGroupCount = confirmedGroups.length;
  const confirmedPlayerCount = sumBy(confirmedGroups, p => p.groupSize);

  const [playerCount, setPlayerCount] = useState(confirmedPlayerCount);
  const [groupCount, setGroupCount] = useState(confirmedGroupCount);

  useEffect(() => {
    setGroupCount(confirmedGroups.length)
    setPlayerCount(sumBy(confirmedGroups, p => p.groupSize));
  }, [confirmedGroups]);

  const showStartModal = () => {
    setPlayerCount(confirmedPlayerCount);
    setStartModalOpen(true);
  };
  const hideStartModal = () => {
    setStartModalOpen(false);
  };

  const handlePlayerCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlayerCount(parseInt(event.target.value, 10));
  };
  const handleGroupCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupCount(parseInt(event.target.value, 10));
  };
  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDurationMinutes(parseInt(event.target.value, 10));
  };

  const startEvent = () => {
    if (!event.gameState) {
      switch (event.gameType) {
        case 'blobg':
        case 'blob': {
          const now = new Date();
          const gameState: BlobGameState = {
            start: now.getTime(),
            end: addDate(now, { minutes: durationMinutes }).getTime(),
            health: event.gameType === 'blobg' ? (100 + 50 * playerCount) : (15 * playerCount),
            damage: 0,
            playerCount,
            countermeasures: Math.ceil(playerCount / 2),
            clueGoal: {
              goal: playerCount * 2,
              clues: 0,
            },
            completedClueGoal: [],
            announcements: [],
          };

          set(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), gameState).catch(error => {
            if (error) {
              // TODO: handle error
            } else {
              hideStartModal();
            }
          });
          break;
        }
        case 'war': {
          const now = new Date();
          const groupStatus: {
            [key: string]: number;
          } = {};
          forEach(confirmedGroups, p => {
            groupStatus[p.user] = 1;
          });
          const gameState: WarGameState = {
            start: now.getTime(),
            end: addDate(now, { minutes: durationMinutes }).getTime(),
            healthLimit: 8 * playerCount,
            damage: 0,
            doom: [0, 0, 0],
            doomLimit: 6 * groupCount,
            announcements: [],
            groupStatus,
            warAnnouncements: [],
            helpNeeded: {
              ny: [],
              pv: [],
              mn: [],
            },
          };

          set(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), gameState).catch(error => {
            if (error) {
              // TODO: handle error
            } else {
              hideStartModal();
            }
          });
          break;
        }
      }
    }
  };

  const dialog = (
    <Dialog open={startModalOpen} onClose={hideStartModal}>
      <DialogTitle>{ t`Start event` }</DialogTitle>
      <DialogContent className={classes.dialog}>
        <GroupAndPlayerCounts event={event} />
        <Box mb={1}>
          { !!unconfirmedGroups.length ? (
            <>
              <CollapseList title={t`Unconfirmed groups`}>
                { map(unconfirmedGroups, renderParticipant) }
              </CollapseList>
              <Box mt={1}>
                <Typography>
                  { t`Warning: unconfirmed players will not be able to join the game once it has begun.` }
                </Typography>
              </Box>
            </>
          ) : (
            <Typography>
              { t`All registered groups are confirmed` }
            </Typography>
          ) }
        </Box>
        <Box mb={1}>
          <TextField
            margin="normal"
            required={true}
            label={t`Player count`}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={playerCount}
            onChange={handlePlayerCountChange}
          />
        </Box>
        <Box mb={1}>
          <TextField
            margin="normal"
            required={true}
            label={t`Group count`}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={groupCount}
            onChange={handleGroupCountChange}
          />
        </Box>
        <Box mb={1}>
          <TextField
            margin="normal"
            required={true}
            label={t`Duration (minutes)`}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            value={durationMinutes}
            onChange={handleDurationChange}
          />
        </Box>

      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={startEvent}
          disabled={playerCount <= 0 || groupCount <= 0}
        >
          { t`Start event` }
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Box m={2} pb={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={showStartModal}
        >
          { t`Start event` }
        </Button>
      </Box>
      { dialog }
    </>
  );
}

export default function PendingGameControls({ event, eventId, uid, isOrganizer }: Props & {
  isOrganizer: boolean;
}) {
  const participants = event.participants || [];
  const registration = find(participants, p => p.user === uid);

  if (uid === event.organizer) {
    return (
      <StartGameControls event={event} eventId={eventId} />
    );
  }
  return registration ? (
    <EditRegistrationSection
      event={event}
      eventId={eventId}
      uid={uid}
      registration={registration}
    />
  ) : (
    <Box m={2} pb={2}>
      <RegisterGroupButton event={event} eventId={eventId} uid={uid} />
    </Box>
  );
}
