import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
// @ts-ignore
import { Variant } from '@material-ui/styles/createTypography';

import { BlobGameState } from '../../lib/schema';

interface RemainingTime {
  hours: number;
  minutes: number;
  seconds: number;
}

export default function CountdownTimer({ title, variant, gameState }: { title?: string; variant?: Variant; gameState: BlobGameState }) {
  const calculateTimeLeft = (): RemainingTime | undefined => {
    const now = new Date();
    const difference = gameState.end - now.getTime();
    if (difference > 0) {
      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return undefined;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });
  if (!timeLeft) {
    return (
      <Typography variant={variant} gutterBottom>
        {title || ''} Out of Time!
      </Typography>
    );
  }
  if (timeLeft.hours > 0 || timeLeft.minutes > 10) {
    let minutes = timeLeft.minutes.toString();
    while (minutes.length < 2) {
      minutes = "0" + minutes;
    }
    return (
      <Typography variant={variant} gutterBottom>
        {title || ''} {timeLeft.hours}:{minutes}
      </Typography>
    );
  }
  let seconds = timeLeft.seconds.toString();
    while (seconds.length < 2) {
      seconds = "0" + seconds;
    }
  return (
    <Typography variant={variant} gutterBottom>
      {title || ''} {timeLeft.minutes}:{seconds}
    </Typography>
  );
}
