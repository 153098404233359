import React, { useState, useCallback } from 'react';
import { Box, Grid, Typography, List, Switch, FormControlLabel } from '@material-ui/core';
import { t } from 'ttag.macro';

import useStyles from './styles';

export default function CollapseList({ title, children }: { title: string; children: React.ReactNode | React.ReactNode[] }) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const toggleExpanded = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setExpanded(event.target.checked);
  }, [setExpanded]);

  return (
    <>
      <Box mt={2}>
        <Grid item container direction="row" justify="space-between" alignItems="center">
          <Typography gutterBottom color="textSecondary">
            { title }
          </Typography>
          <FormControlLabel control={<Switch onChange={toggleExpanded} checked={expanded} />} label={t`Show all`} />
        </Grid>
      </Box>
      <List className={expanded ? undefined : classes.truncatedList}>
        { children }
      </List>
    </>
  );
}