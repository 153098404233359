import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';

import { ApolloProvider } from '@apollo/client';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { deDE, esES, frFR, enUS, itIT, ruRU, koKR } from '@material-ui/core/locale';
import 'fontsource-roboto';
import { ko, ru, de, it, fr, es, enUS as en } from 'date-fns/locale';

import App from './App';
import apolloClient from './apollo';
import configureStore from './configureStore';
import * as serviceWorker from './serviceWorker';
import { ReduxState } from './reducers';
import './index.css';
import 'react-smartbanner/dist/main.css';
import { LocaleContext } from './components/core/LocaleContext';
import { AuthUserProvider } from './AuthContext';

const { store } = configureStore();

function LocaleProvider({ children }: { children: React.ReactNode }) {
  const lang = useSelector((state: ReduxState) => state.settings.lang || 'en');
  const locale = useMemo(() => {
    switch (lang) {
      case 'de': return de;
      case 'fr': return fr;
      case 'es': return es;
      case 'it': return it;
      case 'ru': return ru;
      case 'ko': return ko;
      case 'ru': return ru;
      case 'en':
      default:
        return en;
    }
  }, [lang]);

  return (
    <LocaleContext.Provider value={{ locale }}>
      { children }
    </LocaleContext.Provider>
  );
}

function LocalizedThemeProvider({ children }: { children: React.ReactNode}) {
  const lang = useSelector((state: ReduxState) => state.settings.lang || 'en');
  const elementLang = useMemo(() => {
    switch (lang) {
      case 'de': return deDE;
      case 'fr': return frFR;
      case 'es': return esES;
      case 'it': return itIT;
      case 'ru': return ruRU;
      case 'ko': return koKR;
      case 'ru': return ruRU;
      case 'en':
      default:
        return enUS;
    }
  }, [lang]);
  const theme = useMemo(() => {
    return createMuiTheme({
      palette: {
        primary: {
          main: '#3a627a',
        },
        secondary: {
          main: '#3ea31f',
        },
      },
    }, elementLang);
  }, [elementLang]);
  return (
    <ThemeProvider theme={theme}>
      { children }
    </ThemeProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <LocalizedThemeProvider>
          <LocaleProvider>
            <AuthUserProvider>
              <SnackbarProvider maxSnack={4}>
                <App />
              </SnackbarProvider>
            </AuthUserProvider>
          </LocaleProvider>
        </LocalizedThemeProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
