import React from 'react';
import { Grid } from '@material-ui/core';

export default function DeleteAccount() {
  return (
    <>
      <Grid sm={1} />
      <Grid item xs={12} sm={10}>
        <h4>How to delete your account</h4>
        <p>
          In order to delete your account, you must use the Arkham Cards app. From the settings page, choose Logout. This will open up a dialog with an option to delete.
        </p>
        <p>
          Deleting your account is permanent and cannot be undone.  All data, including decks and campaigns you have made, will be deleted.
        </p>
        <p>
          If you have any questions, you can email me at <a href="mailto:arkhamcards@gmail.com">arkhamcards@gmail.com</a>.
        </p>
      </Grid>
    </>
  );
}
