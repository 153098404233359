import React from 'react';
import { sumBy } from 'lodash';
import { DialogContentText } from '@material-ui/core';
import { t } from 'ttag';

import { ArkhamEvent, ParticipantStatus } from '../../lib/schema';

interface Props {
  event: ArkhamEvent;
}

export default function GroupAndPlayerCounts({ event }: Props) {
  const confirmedGroupCount = sumBy(event.participants || [], p => p.status === ParticipantStatus.CONFIRMED ? 1 : 0);
  const pendingGroupCount = sumBy(event.participants || [], p => p.status === ParticipantStatus.REGISTERED ? 1 : 0);
  const confirmedPlayerCount = sumBy(event.participants || [], p => p.status === ParticipantStatus.CONFIRMED ? p.groupSize : 0);
  const pendingPlayerCount = sumBy(event.participants || [], p => p.status === ParticipantStatus.REGISTERED  ? p.groupSize : 0);
  return (
    <>
      <DialogContentText>
        { t`Groups:` } {confirmedGroupCount} / {confirmedGroupCount + pendingGroupCount}
      </DialogContentText>
      <DialogContentText>
        { t`Players:`} {confirmedPlayerCount} / {confirmedPlayerCount + pendingPlayerCount}
      </DialogContentText>
    </>
  );
}