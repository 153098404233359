import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useLocation,
  Redirect,
} from 'react-router-dom';

import './App.css';
import About from './components/About';
import EventView from './components/Event';
import BlobStats from './components/BlobStats'
import Home from './components/Home';
import Login from './components/Login';
import DeleteAccount from './components/DeleteAccount';
import PrivacyPolicy from './components/PrivacyPolicy';
import NavBar from './components/NavBar';
import Profile from './components/Profile';
import CreateEvent from './components/CreateEvent'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function EventDetail() {
  const { eventId } = useParams<{ eventId: string }>();
  return <EventView eventId={eventId} />;
}

function EventDetailStats() {
  const { eventId } = useParams<{ eventId: string }>();
  return <BlobStats eventId={eventId} />;
}

function ProfileUserDetail() {
  const query = useQuery();
  const uid = query.get('uid');
  if (!uid) {
    return <Redirect to="/home" />;
  }
  return <Profile uid={uid} />
}

function ProfileHandleDetail() {
  const { handle } = useParams<{ handle: string }>();
  return <Profile handle={handle} />;
}

function App() {
  return (
    <Router>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Helmet>
      <NavBar />
      <Grid container alignItems="center" spacing={3}>
        <Switch>
          <Route path="/p/privacy-policy.html">
            <PrivacyPolicy />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/delete-account">
            <DeleteAccount />
          </Route>
          <Route path="/u/:handle">
            <ProfileHandleDetail />
          </Route>
          <Route path="/u">
            <ProfileUserDetail />
          </Route>
          <Route path="/event/create">
            <CreateEvent />
          </Route>
          <Route path="/event/:eventId/stats">
            <EventDetailStats />
          </Route>
          <Route path="/blob/:eventId/stats">
            <EventDetailStats />
          </Route>
          <Route path="/blob/:eventId">
            <EventDetail />
          </Route>
          <Route path="/event/:eventId">
            <EventDetail />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Grid>
    </Router>
  );
}

export default App;
