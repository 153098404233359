import React, { useEffect, useState, useMemo, useContext } from 'react';
import { concat, filter, forEach, map, values, find } from 'lodash';
import { ref, query, orderByChild, onValue } from 'firebase/database';
import { Container, Card, CardActionArea, CardContent, CardMedia, Grid, Box, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import AddCircle from '@material-ui/icons/AddCircle';
import { Link } from 'react-router-dom';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Helmet } from 'react-helmet';
import { t, jt } from 'ttag.macro';

import { firebaseDatabase } from '../firebase';
import { User, UpcomingEvent } from '../lib/schema';
import ArkhamIcon from './core/ArkhamIcon';
import { dateString, timeString } from '../lib/date';
import Loading from './core/Loading';
import { LocaleContext } from './core/LocaleContext';
import { useAuth } from '../AuthContext';
// import { apiFunction } from '../lib/api';

interface UpcomingEvents {
  [key: string]: UpcomingEvent | undefined
}

const UNDER_CONSTRUCTION = false;

function EventListItem({ blobId, blob }: { blobId: string; blob?: UpcomingEvent }) {
  const { locale } = useContext(LocaleContext);
  const startTime = blob ? new Date(blob.scheduledStart) : undefined;
  const start = startTime ? `${dateString(startTime, locale)} at ${timeString(startTime, locale)}` : undefined;
  if (!blob || blob.archived) {
    return null;
  }
  return (
    <ListItem button component={Link} to={`/event/${blobId}`}>
      <ListItemIcon>
        <ArkhamIcon icon={blob.gameType === 'war' ? 'war_of_the_outer_gods' : 'blob'} size={48} color="#000" />
      </ListItemIcon>
      <ListItemText
        primary={blob ? blob.name : blobId}
        secondary={start}
      />
    </ListItem>
  );
}

function EventList({ eventIds, events, hideEmpty }: {
  eventIds: string[];
  events: UpcomingEvents;
  hideEmpty?: boolean;
}) {
  if (!eventIds.length) {
    if (hideEmpty) {
      return null;
    }
    return (
      <ListItem>
        <ListItemText primary="None" />
      </ListItem>
    );
  }
  return (
    <>
      {map(eventIds, eventId => (
        <EventListItem key={eventId} blobId={eventId} blob={events[eventId]} />
      ))}
    </>
  );
}

function UserEvents({ uid, upcomingEvents, allEventIds }: {
  uid?: string;
  allEventIds: string[];
  upcomingEvents: UpcomingEvents;
}) {
  const [user, loading, error] = useObjectVal<User>(
    ref(firebaseDatabase, `/users/${uid}`),
    { keyField: uid }
  );
  const upcomingEventIds: string[] = useMemo(() => values(user ? user.upcomingBlobs : {}), [user]);
  const organizerEventIds: string[]= useMemo(() => values(user ? user.organizerBlobs : {}), [user]);
  const alreadyListedEventIds = useMemo(() => new Set(concat(upcomingEventIds, organizerEventIds)), [upcomingEventIds, organizerEventIds]);
  const otherEventIds = filter(allEventIds, blobId => !alreadyListedEventIds.has(blobId));
  const createEventsSection = useMemo(() => {
    return (
      <>
        <ListSubheader disableSticky>
          { t`Events you are hosting` }
        </ListSubheader>
        <EventList
          eventIds={organizerEventIds}
          events={upcomingEvents}
          hideEmpty
        />
        <ListItem
          button
          color="secondary"
          component={Link}
          to="/event/create"
        >
          <ListItemIcon>
            <AddCircle color="secondary" />
          </ListItemIcon>
          <ListItemText>
            { t`Schedule an event` }
          </ListItemText>
        </ListItem>
      </>
    );
  }, [organizerEventIds, upcomingEvents]);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      { !!error && <Alert severity="error">{ error }</Alert> }
      <List>
        { organizerEventIds.length > 0 && createEventsSection }
        { upcomingEventIds && upcomingEventIds.length && find(upcomingEventIds, blobId => !upcomingEvents[blobId]?.archived) ? (
          <>
            <ListSubheader disableSticky>
              { t`Upcoming event you are registered for` }
            </ListSubheader>
            <EventList eventIds={upcomingEventIds} events={upcomingEvents} />
          </>
        ) : null }
        { otherEventIds.length > 0 && (
          <>
            <ListSubheader disableSticky>
              { t`Upcoming events` }
            </ListSubheader>
            <EventList
              eventIds={otherEventIds}
              events={upcomingEvents}
            />
          </>
        ) }
        { organizerEventIds.length === 0 && createEventsSection }
      </List>
    </>
  );
}

const homeStyles = makeStyles((theme) => ({
  iosButton: {
    paddingTop: 12,
    paddingBottom: 12,
    width: 160,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 16,
    },
  },
  androidButton: {
    maxWidth: 200,
  },
  media: {
    height: 250,
    [theme.breakpoints.only('xs')]: {
      height: 125,
    },
    [theme.breakpoints.up('lg')]: {
      height: 300,
    },
    [theme.breakpoints.up('xl')]: {
      height: 325,
    },
  },
  card: {
    maxWidth: 512,
  },
}));

function MainHome({ uid }: { uid?: string }) {
  const styles = homeStyles();
  const [upcomingEvents, setUpcomingEvens] = useState({});
  const [allEventIds, setAllEventIds] = useState<string[]>([]);
  useEffect(() => {
    onValue(
      query(ref(firebaseDatabase, 'upcoming_blobs'), orderByChild('scheduledStart')),
      (theBlobs) => {
        const blobIds: string[] = [];
        const blobs: { [key: string]: UpcomingEvent } = {};
        forEach(theBlobs.val(), (blob, key) => {
          blobs[key] = blob;
          blobIds.push(key);
        });
        setUpcomingEvens(blobs);
        setAllEventIds(blobIds);
      },
      {
        onlyOnce: true,
      }
    );
  }, []);
  const eventsList = useMemo(() => {
    if (uid) {
      return (
        <UserEvents
          uid={uid}
          allEventIds={allEventIds}
          upcomingEvents={upcomingEvents}
        />
      );
    }
    return (
      <List>
        <ListSubheader disableSticky>
          { t`Scheduled events` }
        </ListSubheader>
        <EventList
          eventIds={allEventIds}
          events={upcomingEvents}
        />
      </List>
    );
  }, [uid, allEventIds, upcomingEvents]);
  if (UNDER_CONSTRUCTION) {
    return (
      <Typography variant="h4">
        { t`Site under construction while we prepare for War of the Outer Gods. Check back soon!`}
      </Typography>
    );
  }
  const blobVideoLink = <a href="https://www.youtube.com/watch?v=X3juSHTvIgk">{t`(video walkthrough of the interface)`}</a>;
  const warVideoLink = <a href="https://www.youtube.com/watch?v=-hzeqnkebo4">{t`(video walkthrough of the interface)`}</a>;
  const arkhamDbLink = <a href="arkhamdb.com">ArkhamDB</a>;
  return (
    <Box m={2}>
    <Grid container spacing={3} direction="row" wrap="wrap">
      <Grid item xs={12} sm={8} md={5}>
        <Box m={2}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6">
                { t`Arkham Horror: Epic Multiplayer Events` }
              </Typography>
              <Typography variant="body1">
                { jt`This website allows you to participate in Arkham Horror scenarios that utilize the epic multiplayer format with players around the world. These are timed events where you can play an Arkham game as you normally do, while using the website to share progress and receive updates from the organizers. The website currently supports two scenarios:`}
              </Typography>
              <Typography variant="body1">
                { jt`• The Blob That Ate Everything ${blobVideoLink}` }
              </Typography>
              <Typography variant="body1" gutterBottom>
                { jt`• War of the Outer Gods ${warVideoLink}.` }
                </Typography>
              <Typography variant="body1" gutterBottom>
                { t`You are more than welcome to use this site to host your own events.` }
              </Typography>
              { eventsList }
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={7}>
        <Box m={2}>
          <Card>
            <CardActionArea>
              <CardMedia
                className={styles.media}
                image="/img/app_promo.svg"
                title="Arkham Cards"
              />
            </CardActionArea>
            <CardContent>
              <Typography variant="h6">
                { t`Use the Arkham Cards app to manage your decks and campaigns.`}
              </Typography>
              <Typography variant="body1">
                { jt`• Keep decks locally on your phone, or integrate directly with decks on ${arkhamDbLink}.`}
              </Typography>
              <Typography variant="body1">
                {t`• Handles all scenario setup and resolution, while maintaining the campaign log and chaos bag automatically.`}
              </Typography>
              <Typography variant="body1">
                {t`• Edit and upgrade your investigator's decks.`}
              </Typography>
              <Typography variant="body1">
                {t`• Draw tokens from the digital chaos bag, and calculate the odds of success before you do.`}
              </Typography>
              <Typography variant="body1">
                {t`• Use the advanced card search to search by trait, health, shroud etc.`}
              </Typography>
              <Typography variant="body1">
                {t`• Completely free to download and use, with zero ads.`}
              </Typography>
              <Grid container direction="row">
                <Grid item xs={12} sm={6}>
                  <Box>
                    <a href='https://apps.apple.com/us/app/arkham-cards/id1424000351'>
                      <img
                        className={styles.iosButton}
                        alt={t`Download on the App Store`}
                        src="/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <a href="https://play.google.com/store/apps/details?id=com.arkhamcards">
                      <img
                        className={styles.androidButton}
                        alt={t`Get it on Google Play`}
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      />
                    </a>
                  </Box>
                </Grid>
              </Grid>
              <Typography variant="body2">
                {t`The app does not yet support Epic Multiplayer events, so you will still need to use the website. App support is coming soon though.`}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
    </Box>
  )
}

const mainClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: 'calc(100vh - 72px)',
  },
  footer: {
    padding: theme.spacing(1, 1),
    marginTop: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));
export default function Home() {
  // const updateEmail = apiFunction('admin-changeEmail');

  const classes = mainClasses();
  const { loading, authUser } = useAuth();
  return (
    <div className={classes.root}>
      <Helmet>
        <meta name="apple-itunes-app" content="app-id=1424000351" />
        <meta name="google-play-app" content="app-id=com.arkhamcards" />
        <link rel="apple-touch-icon" href="/img/icon.png" />
        <link rel="android-touch-icon" href="/img/icon.png" />
        <script async src="https://c6.patreon.com/becomePatronButton.bundle.js" />
      </Helmet>
      { loading ? <Container><Loading /></Container> : <MainHome uid={authUser?.uid} /> }
      <footer className={classes.footer}>
        <Container maxWidth={false}>
          <Box mt={2} mb={1}>
            <a href="https://www.patreon.com/bePatron?u=47090078" data-patreon-widget-type="become-patron-button">
              {t`Become a Patron!`}
            </a>
          </Box>
          <Typography variant="caption">
            {t`Hosting costs for this site and the Arkham Cards app comes from the generous support of fans on Patreon.`}
          </Typography>
        </Container>
      </footer>
    </div>
  );
}

