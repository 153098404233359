import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ref, child } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Box, Card, CardContent, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { t } from 'ttag.macro';

import { firebaseDatabase } from '../../firebase';
import { ADMIN_USER } from '../../lib/schema';
import Loading from '../core/Loading';
import SubmitButton from '../core/SubmitButton';
import FriendStatusComponent from './FriendStatusComponent';
import FriendRequestsComponent from './FriendRequestsComponent';
import { apiFunction } from '../../lib/api';
import { useGetProfileQuery, UserProfileFragment } from '../../graphql/schema';
import { useAuth } from '../../AuthContext';

interface Props {
  handle?: string;
  uid?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    maxWidth: 500,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: 350,
  },
}));

function ProfileDetails({ profile, userId, editable, authUserId, refreshProfile }: {
  profile?: UserProfileFragment;
  authUserId?: string;
  userId: string;
  editable?: boolean;
  refreshProfile: () => void;
}) {
  const classes = useStyles();
  const [handle, setHandle] = useState(profile?.handle);
  const [handleError, setHandleError] = useState<string | undefined>();
  const updateHandle = apiFunction('social-updateHandle');
  const submit = useCallback(async (value: string) => {
    const response = await updateHandle({ handle: value, userId });
    if (response.data.error) {
      setHandleError(response.data.error);
    }
    return response.data.message;
  }, [updateHandle, userId]);
  const onHandleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHandle(event.target.value);
    setHandleError(undefined);
  }, [setHandle, setHandleError]);
  const onHandleSubmit = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.target.value);
    event.preventDefault();
  }, [submit]);
  const submitPressed = useCallback(async() => {
    if (handle) {
      return await submit(handle);
    } else {
      setHandleError(t`Blank handles are not allowed`);
    }
  }, [handle, submit, setHandleError]);
  return (
    <Grid item xs={12}>
      <Box m={2}>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            { editable ? (
              <Grid item container direction="row" alignItems="flex-end" spacing={4}>
                <Grid item xs={12} sm={8}>
                  <Box>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="handle"
                      label={t`Account name`}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={handle}
                      placeholder={t`Choose a handle`}
                      onChange={onHandleChange}
                      onSubmit={onHandleSubmit}
                      error={!!handleError}
                      helperText={handleError}
                    />
                  </Box>
                </Grid>
                { (handle !== profile?.handle) && (
                  <Grid item xs={12} sm={4}>
                    <SubmitButton
                      onSubmit={submitPressed}
                      disabled={!handle}
                      variant="outlined"
                      color="secondary"
                    >
                      {t`Update`}
                    </SubmitButton>
                  </Grid>
                  )}
                </Grid>
              ) : (
                <>
                  <Box mb={2}>
                    <Typography variant="body2">{t`Handle`}</Typography>
                    <Typography variant="body1">{profile?.handle || t`No handle chosen yet.`}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">{t`Friends`}</Typography>
                    <Typography variant="body1">{profile?.friends.length || 0}</Typography>
                  </Box>
                </>
              )
            }
            { !!authUserId && (
              (authUserId === userId) ? (
                <FriendRequestsComponent
                  profile={profile}
                  refreshProfile={refreshProfile}
                />
              ) : (
                <Box mt={2}>
                  <FriendStatusComponent
                    authUserId={authUserId}
                    userId={userId}
                    profile={profile}
                    refreshProfile={refreshProfile}
                  />
                </Box>
              )
            ) }
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
}

export default function Profile({ handle, uid }: Props) {
  const [handleUid, loadingHandle] = useObjectVal<string>(handle ? child(child(ref(firebaseDatabase, '/handles'), handle.toLowerCase()), 'uid') : undefined);
  const userId = uid || handleUid;
  const { data, loading: loadingUser, refetch  } = useGetProfileQuery({
    variables: {
      userId: userId || '',
    },
    skip: !userId,
  });
  const refreshProfile = useCallback(() => {
    if (userId) {
      console.log('refetching profile');
      refetch({ userId });
    }
  }, [userId, refetch]);
  const profile = data?.users_by_pk || undefined;
  const pageTitle = profile?.handle ? `Arkham Cards - ${profile.handle}` : 'Arkham Cards';
  useEffect(() => {
    if (profile && profile.handle) {
      const path = `/u/${profile.handle.toLowerCase()}${uid ? `?uid=${uid}` : ''}`;
      window.history.replaceState(null, `Arkham Cards - ${profile.handle}`, path);
    }
  }, [profile, userId, uid]);
  const { authUser, loading } = useAuth();
  if (loadingHandle || loadingUser) {
    return <Loading />;
  }
  if (!userId) {
    return <Redirect to="/home" />;
  }
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ProfileDetails
        profile={profile}
        userId={userId}
        authUserId={authUser?.uid}
        editable={authUser?.uid === userId || authUser?.uid === ADMIN_USER}
        refreshProfile={refreshProfile}
      />
    </>
  );
}