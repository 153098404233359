import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 500,
  },
  media: {
    height: 110,
  },
  content: {
    'background-color': ({ backgroundColor }: { backgroundColor?: string }) => backgroundColor,
  },
}));

interface Props {
  image: string;
  imageTitle?: string;
  title?: string;
  children: React.ReactNode;
  backgroundColor?: string;
}

export default function MediaCard({ image, imageTitle, title, children, backgroundColor }: Props) {
  const classes = useStyles({ backgroundColor });
  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image={image}
        title={imageTitle}
      />
      <CardContent className={classes.content}>
        { !!title && (
          <Typography gutterBottom color="textSecondary">
            { title }
          </Typography>
        ) }
        { children }
      </CardContent>
    </Card>
  );
}