import { format, Locale } from 'date-fns';

export function dateString(date: Date, locale: Locale): string {
  if (date instanceof Date && !isNaN(date.getTime())) {
    return format(date, 'yyyy-MM-dd', { locale });
  }
  return 'Unknown date';
}

export function timeString(date: Date, locale: Locale): string {
  if (date instanceof Date && !isNaN(date.getTime())) {
    return format(date, 'hh:mm a', { locale });
  }
  return 'Unknown date';
}