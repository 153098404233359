import React, { useCallback, useMemo, useState } from 'react';
import { find, filter, map, values, max, flatMap, partition, sumBy } from 'lodash';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Divider, Grid, TextField, Typography, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { t, ngettext, msgid } from 'ttag.macro';

import { WarGameState, GroupWarUpdateType, WarEvent, WarFaction, Participant, PersonalWarAnnouncementType } from '../../../lib/schema';
import MediaCard from '../../core/MediaCard';
import SubmitButton from '../../core/SubmitButton';
import Announcements from './Announcements';
import CollapseList from './CollapseList';
import useStyles from './styles';
import { apiFunction } from '../../../lib/api';

interface Props {
  eventId: string;
  event: WarEvent;
  isOrganizer: boolean;
  gameState: WarGameState;
  uid?: string;
}

interface ConfirmState {
  title: string;
  body: string;
  updateType: GroupWarUpdateType;
  value?: number;
  targetUser?: string;
}

type ShowConfirmDialog = (options: ConfirmState) => void;

function useConfirmDialog(eventId: string): [React.ReactNode, ShowConfirmDialog] {
  const [confirmState, setConfirmState] = useState<ConfirmState | undefined>();
  const hideConfirmModal = useCallback(() => setConfirmState(undefined), [setConfirmState]);
  const sendUpdate = useCallback(async() => {
    if (confirmState) {
      const { updateType, value, targetUser } = confirmState;
      const data = {
        eventId,
        updateType,
        value,
        targetUser,
      };
      const recordGroupWarProgress = apiFunction('event-recordGroupWarProgress');
      const result = await recordGroupWarProgress(data);
      const {
        error,
        message,
      } = result.data;
      if (error) {
        throw new Error(error);
      }
      setConfirmState(undefined);
      return message;
    }
    setConfirmState(undefined);
    return undefined;
  }, [eventId, confirmState, setConfirmState]);
  const confirmDialog = useMemo(() => (
    <Dialog
      open={!!confirmState}
      onClose={hideConfirmModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      { !!confirmState && <DialogTitle>{ confirmState.title }</DialogTitle> }
      <DialogContent>
        { !!confirmState && (
          <DialogContentText>
            { confirmState.body }
          </DialogContentText>
        ) }
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={hideConfirmModal}
          type="reset"
        >
          { t`Cancel` }
        </Button>
        { !!confirmState && (
          <SubmitButton
            variant="contained"
            color="secondary"
            onSubmit={sendUpdate}
          >
            { t`Yes` }
          </SubmitButton>
        ) }
      </DialogActions>
    </Dialog>
  ), [confirmState, hideConfirmModal, sendUpdate]);

  return [confirmDialog, setConfirmState];
}

function GameInputButton({ label, disabled, halfWidth, variant, submit }: {
  label: string;
  variant: 'outlined' | 'contained';
  disabled: boolean;
  submit: () => Promise<string | undefined>;
  halfWidth?: boolean;
}) {
  return (
    <Grid item xs={12} sm={halfWidth ? 6 : 12}>
      <SubmitButton
        variant={variant}
        color="secondary"
        onSubmit={submit}
        fullWidth
        disabled={disabled}
      >
        { label }
      </SubmitButton>
    </Grid>
  );
}

function GameInputControl({ eventId, label, updateType, disabled, fixedValue, noDivider, variant }: {
  eventId: string;
  label: string;
  updateType: GroupWarUpdateType;
  disabled?: boolean;
  fixedValue?: number;
  noDivider?: boolean;
  variant?: 'outlined' | 'contained'
}) {
  const [value, setValue] = useState(0);
  const send = async () => {
    const data = {
      eventId,
      updateType,
      value: fixedValue !== undefined ? fixedValue : value,
    };
    const recordGroupWarProgress = apiFunction('event-recordGroupWarProgress');
    const result = await recordGroupWarProgress(data);
    const {
      error,
      message,
    } = result.data;
    if (error) {
      throw new Error(error);
    }
    setValue(0);
    return message;
  };
  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value, 10));
  };
  return (
    <Box mb={2} pt={noDivider ? 2 : 0}>
      { !noDivider && <Box mt={2} mb={3}><Divider /></Box> }
      <Grid container alignItems="flex-start" direction="column" spacing={0}>
        { fixedValue === undefined && (
          <Grid item xs={12} sm={6}>
            <Typography color="textSecondary">
              { label }
            </Typography>
          </Grid>
        ) }
        <Grid item container direction="row" alignItems="flex-end" spacing={4}>
          { fixedValue === undefined && (
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={value}
                onChange={handleFormChange}
              />
            </Grid>
          ) }
          <GameInputButton
            variant={variant || (fixedValue !== undefined ? 'outlined' : 'contained')}
            submit={send}
            disabled={disabled || (fixedValue === undefined && value === 0)}
            label={fixedValue !== undefined ? label : t`Send`}
            halfWidth={fixedValue === undefined}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function factionInfo(faction: WarFaction) {
  switch (faction) {
    case 'green':
      return {
        index: 1,
        color: "#cae0d4",
        name: t`Cult of Magh’an Ark’at`,
        goo: t`Magh’an Ark’at`,
        image: '/img/green_cult.png',
        doomType: GroupWarUpdateType.DOOM_GREEN,
      };
    case 'blue':
      return {
        index: 0,
        color: "#cbe1f2",
        name: t`Cult of Silenus`,
        goo: t`Silenus`,
        image: '/img/blue_cult.png',
        doomType: GroupWarUpdateType.DOOM_BLUE,
      };
    case 'red':
      return {
        index: 2,
        color: "#dbc6c5",
        name: t`Cult of Ezel-zen-rezl`,
        goo: t`Ezel-zen-rezl`,
        image: '/img/red_cult.png',
        doomType: GroupWarUpdateType.DOOM_RED,
      };
  }
}

const FACTION_NAMES = [
  t`Cult of Magh’an Ark’at (Green)`,
  t`Cult of Silenus (Blue)`,
  t`Cult of Ezel-zen-rezl (Red)`,
];

function leadFactions(doom: number[]): string[] {
  const leadDoom = max(doom);
  return flatMap(doom, (d, idx) => {
    if (d === leadDoom) {
      return FACTION_NAMES[idx];
    }
    return [];
  });
}

function DoomBlock({
  eventId,
  gameState,
  uid,
}: { eventId: string; gameState: WarGameState; uid?: string }) {
  const classes = useStyles();
  const leadFactionMessage = useMemo(() => {
    const leadingFactions = leadFactions(gameState.doom);
    switch (leadingFactions.length) {
      case 1:
        return leadingFactions[0];
      case 2:
        return t`${leadingFactions[0]} and ${leadingFactions[1]} are tied. If needed, use whichever one has more enemies on your board as the "Faction in the lead".`;
      case 3:
        return t`All factions are tied. If needed, use whichever one has more enemies on your board as the "Faction in the lead".`;
      default:
        return 'Unknown';
    }
  }, [gameState.doom]);
  const renderDoomSection = useCallback((offset: number) => {
    const doom = gameState.doom[offset] % gameState.doomLimit;
    const doomLimit = gameState.doomLimit;
    const agendaNumber = Math.floor(gameState.doom[offset] / gameState.doomLimit) + 1;
    const classNames = [
      classes.greenContent,
      classes.blueContent,
      classes.redContent,
    ];
    const inputLabels = [
      t`Add Green Doom`,
      t`Add Blue Doom`,
      t`Add Red Doom`,
    ];
    const updateTypes = [
      GroupWarUpdateType.DOOM_GREEN,
      GroupWarUpdateType.DOOM_BLUE,
      GroupWarUpdateType.DOOM_RED,
    ];
    return (
      <Box p={2} className={classNames[offset]} key={offset}>
        <Typography variant="h6">
          {FACTION_NAMES[offset]}
        </Typography>
        <Typography>
          { ngettext(msgid`Agenda ${agendaNumber}`, `Agenda ${agendaNumber}`, agendaNumber) }
        </Typography>
        <Typography>
          { t`Doom ${doom} / ${doomLimit}` }
        </Typography>
        { !!uid && (
          <GameInputControl
            eventId={eventId}
            label={inputLabels[offset]}
            updateType={updateTypes[offset]}
            disabled={gameState.doom[offset] >= gameState.doomLimit * 3}
          />
        ) }
      </Box>
    );
  }, [classes, eventId, gameState.doom, gameState.doomLimit, uid]);
  if (gameState.goo) {
    return null;
  }
  return (
    <Grid item xs={12} sm={12} md={4}>
      <Box p={2}>
        <MediaCard
          image="/img/green_cult.png"
          imageTitle="A threatening cultist"
          title={t`Factions`}
        >
          <Typography variant="h6">
            {t`Faction in the Lead:`}
          </Typography>
          <Typography>
            { leadFactionMessage }
          </Typography>
          <GameInputControl
            eventId={eventId}
            label={t`Place Mythos Doom (1 per faction)`}
            updateType={GroupWarUpdateType.DOOM_ALL}
            fixedValue={1}
          />
          <Box mt={2} mb={3}><Divider /></Box>
          { renderDoomSection(0) }
          <Box mt={2} mb={3}><Divider /></Box>
          { renderDoomSection(1) }
          <Box mt={2} mb={3}><Divider /></Box>
          { renderDoomSection(2) }
        </MediaCard>
      </Box>
    </Grid>
  );
}

function GreatOldOneBlock({
  eventId,
  gameState,
  uid,
  faction,
}: Props & {
  faction: WarFaction;
}) {
  const {
    image,
    goo,
    color,
  } = factionInfo(faction);
  const eliminated = (uid && gameState.groupStatus && gameState.groupStatus[uid] === -1);
  return (
    <Grid item xs={12} sm={12} md={4}>
      <Box m={2}>
        <MediaCard
          image={image}
          imageTitle={goo}
          title={goo}
          backgroundColor={color}
        >
          <Typography>
            { t`Damage: ${gameState.damage} / ${gameState.healthLimit}` }
          </Typography>
          { uid && !eliminated && (
            <GameInputControl
              eventId={eventId}
              label={t`Deal damage`}
              updateType={GroupWarUpdateType.DAMAGE}
              disabled={gameState.damage >= gameState.healthLimit}
            />
          ) }
        </MediaCard>
      </Box>
    </Grid>
  );
}

function GroupAction({
  uid,
  uidAct,
  isOrganizer,
  participant,
  participantAct,
  showConfirmDialog,
  gooFight,
}: {
  uid: string;
  uidAct: number;
  isOrganizer: boolean;
  participant: Participant;
  participantAct: number;
  showConfirmDialog: ShowConfirmDialog;
  gooFight: boolean;
}) {
  const shareClueClick = useCallback(() => {
    showConfirmDialog({
      title: t`Share a clue with this group`,
      body: t`Are you sure you want to share a clue with this group using the abiliity on Act 3?`,
      updateType: GroupWarUpdateType.GIVE_CLUE,
      targetUser: participant.user
    });
  }, [showConfirmDialog, participant]);
  const advanceClick = useCallback(() => {
    const nextAct = participantAct + 1;
    showConfirmDialog({
      title: t`Advance group`,
      body: isOrganizer ? t`Advance this group to Act ${nextAct}` : t`Is your group ready to advance to Act ${nextAct}?`,
      updateType: GroupWarUpdateType.ACT,
      value: nextAct,
      targetUser: participant.user,
    });
  }, [isOrganizer, showConfirmDialog, participant, participantAct]);
  const deleteClick = useCallback(() => {
    const questionPrompt = isOrganizer ? t`Mark this group as eliminated?` : t`Has your entire group been eliminated?`;
    const doomPrompt = uidAct === 1 || uidAct === 2 ? t`Because you are still in Act 1 / Act 2, this will add ${participant.groupSize} doom to the faction in the lead equal.` : undefined;
    showConfirmDialog({
      title: t`Elminate group`,
      body: doomPrompt ? `${questionPrompt}\n\n${doomPrompt}` : questionPrompt,
      updateType: GroupWarUpdateType.ACT,
      value: -1,
      targetUser: participant.user
    });
  }, [isOrganizer, showConfirmDialog, participant, uidAct]);
  return (
    <ListItemSecondaryAction>
      { (isOrganizer || uidAct === 3) && (participantAct === 3 || participantAct === 2) && !gooFight && (
        <IconButton edge="end" aria-label={t`share clue`} onClick={shareClueClick}>
          <PlusIcon />
        </IconButton>
      ) }
      { isOrganizer && participantAct !== -1 && participantAct < 3 && !gooFight && (
        <IconButton edge="end" aria-label={t`advance`} onClick={advanceClick}>
          <ArrowUpwardIcon />
        </IconButton>
      ) }
      { isOrganizer && participantAct !== -1 && (
        <IconButton edge="end" aria-label={t`eliminate group`} onClick={deleteClick}>
          <DeleteIcon />
        </IconButton>
      ) }
    </ListItemSecondaryAction>
  );
}

function GroupSegment({
  uid,
  sectionHeader,
  participants,
  secondaryLabel,
  children,
}: {
  uid?: string;
  sectionHeader: string;
  participants: Participant[];
  secondaryLabel?: (p: Participant) => string | undefined;
  children?: (p: Participant) => JSX.Element | null;
}) {
  if (!participants.length) {
    return null;
  }
  return (
    <>
      <ListSubheader disableSticky>
        { sectionHeader }
      </ListSubheader>
      { map(participants, p => {
        const label = secondaryLabel ? secondaryLabel(p) : undefined;
        return (
          <ListItem key={p.user}>
            <ListItemText primary={(p.user === uid ? '*' : '') + p.groupName} />
            { !!label && <ListItemText secondary={label} /> }
            { !!children && children(p) }
          </ListItem>
        );
      }) }
    </>
  );
}

function cityToHelpInfo(city: 'mn' | 'ny' | 'pv' | 'clues') {
  switch (city) {
    case 'mn': return {
      announcement: PersonalWarAnnouncementType.HELP_MN,
      updateType: GroupWarUpdateType.GIVE_HELP_MN,
      section: t`Requested help in Montéal`,
      title: t`Give help in Montéal`,
      body: t`Are you ready to help this group using the action ability on the "Streets of Montéal" location (group limit once per game)?`,
    };
    case 'ny': return {
      announcement: PersonalWarAnnouncementType.HELP_NY,
      updateType: GroupWarUpdateType.GIVE_HELP_NY,
      section: t`Requested help in New York`,
      title: t`Give help in New York`,
      body: t`Are you ready to help this group using the action ability on the "Streets of New York" location (group limit once per game)?`,
    };
    case 'pv': return {
      announcement: PersonalWarAnnouncementType.HELP_PV,
      updateType: GroupWarUpdateType.GIVE_HELP_PV,
      section: t`Requested help in Providence`,
      title: t`Give help in Providence`,
      body: t`Are you ready to help this group using the action ability on the "Streets of Providence" location (group limit once per game)?`,
    };
    case 'clues': return {
      announcement: PersonalWarAnnouncementType.CLUE,
      updateType: GroupWarUpdateType.GIVE_CLUE,
      section: t`Requested help closing Hub Dimension`,
      title: t`Share a clue with this group`,
      body: t`Are you sure you want to share a clue with this group using the abiliity on Act 3?`,
    };
  }
};

function HelpGroupSegment({ aliveParticipants, gameState, city, uid, isOrganizer, showConfirmDialog }: {
  aliveParticipants: Participant[];
  gameState: WarGameState;
  city: 'mn' | 'pv' | 'ny' | 'clues';
  uid?: string;
  isOrganizer: boolean;
  showConfirmDialog: ShowConfirmDialog;
}) {
  const eliminated = !!(uid && gameState.groupStatus && gameState.groupStatus[uid] === -1);
  const help = useMemo(() => new Set(gameState.helpNeeded?.[city]), [gameState.helpNeeded, city]);
  const {
    announcement,
    title,
    body,
    section,
    updateType,
  } = cityToHelpInfo(city);
  const gaveHelp = useMemo(() => {
    if (!uid || isOrganizer) {
      return false;
    }
    return !!find(gameState.warAnnouncements, a => a.type === announcement && a.from === uid);
  }, [uid, isOrganizer, gameState.warAnnouncements, announcement]);
  const participants = useMemo(() => filter(aliveParticipants, p => help.has(p.user)), [aliveParticipants, help]);
  const renderHelpControls = useCallback((p: Participant) => {
    if (!uid || eliminated || p.user === uid || gaveHelp) {
      return null;
    }
    const giveHelp = () => {
      showConfirmDialog({
        title,
        body,
        updateType,
        value: 1,
        targetUser: p.user,
      });
    };
    return (
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label={title} onClick={giveHelp}>
          <PlusIcon />
        </IconButton>
      </ListItemSecondaryAction>
    );
  }, [eliminated, uid, showConfirmDialog, gaveHelp, title, body, updateType]);
  const secondaryLabel = useCallback((p: Participant) => {
    if (!uid || eliminated || p.user === uid || gaveHelp) {
      return undefined;
    }
    return city === 'clues' ? t`Give clue` : t`Give help`;
  }, [uid, city, eliminated, gaveHelp]);
  return (
    <GroupSegment
      uid={uid}
      sectionHeader={section}
      participants={participants}
      secondaryLabel={secondaryLabel}
    >
      { renderHelpControls }
    </GroupSegment>
  );
}
function GroupControls({
  eventId,
  event,
  gameState,
  uid,
  isOrganizer,
  showConfirmDialog,
}: Props & {
  showConfirmDialog: ShowConfirmDialog;
}) {
  const act = (uid && gameState.groupStatus && gameState.groupStatus[uid]) || 1;
  const eliminated = (uid && gameState.groupStatus && gameState.groupStatus[uid] === -1);
  const gooFight = !!gameState.goo;
  const {
    eliminatedGroups,
    act1Groups,
    act2Groups,
    act3Groups,
    nonEliminatedGroups,
  } = useMemo(() => {
    const confirmedParticipants = filter(event.participants, p => !!p.confirmedAt);
    const [eliminatedGroups, nonEliminatedGroups] = partition(
      confirmedParticipants,
      p => (gameState.groupStatus && gameState.groupStatus[p.user]) === -1
    );
    return {
      eliminatedGroups,
      act1Groups: filter(nonEliminatedGroups, p => (gameState.groupStatus && gameState.groupStatus[p.user]) === 1),
      act2Groups: filter(nonEliminatedGroups, p => (gameState.groupStatus && gameState.groupStatus[p.user]) === 2),
      act3Groups: filter(nonEliminatedGroups, p => (gameState.groupStatus && gameState.groupStatus[p.user]) === 3),
      nonEliminatedGroups,
    };
  }, [gameState.groupStatus, event.participants]);
  const renderParticipantControls = useCallback((p: Participant) => {
    const participantAct = (gameState.groupStatus && gameState.groupStatus[p.user]) || 1;
    if (!uid || eliminated) {
      return null;
    }
    return (
      <GroupAction
        showConfirmDialog={showConfirmDialog}
        isOrganizer={isOrganizer}
        uid={uid}
        uidAct={act}
        participant={p}
        participantAct={participantAct}
        gooFight={gooFight}
      />
    )
  }, [act, gameState, showConfirmDialog, eliminated, uid, isOrganizer, gooFight]);
  const clueSecondaryLabel = useCallback((p: Participant) => {
    if (isOrganizer || !uid || uid === p.user || act !== 3) {
      return undefined;
    }
    return t`Give clue`;
  }, [isOrganizer, uid, act]);

  return (
    <CollapseList title={t`Groups`}>
      { (act === 3) && (
        <HelpGroupSegment
          uid={uid}
          isOrganizer={isOrganizer}
          city="clues"
          showConfirmDialog={showConfirmDialog}
          gameState={gameState}
          aliveParticipants={nonEliminatedGroups}
        />
      ) }
      <HelpGroupSegment
        uid={uid}
        isOrganizer={isOrganizer}
        city="ny"
        showConfirmDialog={showConfirmDialog}
        gameState={gameState}
        aliveParticipants={nonEliminatedGroups}
      />
      <HelpGroupSegment
        uid={uid}
        isOrganizer={isOrganizer}
        city="mn"
        showConfirmDialog={showConfirmDialog}
        gameState={gameState}
        aliveParticipants={nonEliminatedGroups}
      />
      <HelpGroupSegment
        uid={uid}
        isOrganizer={isOrganizer}
        city="pv"
        showConfirmDialog={showConfirmDialog}
        gameState={gameState}
        aliveParticipants={nonEliminatedGroups}
      />
      { gooFight ? (
        <GroupSegment uid={uid} sectionHeader={t`Still fighting`} participants={nonEliminatedGroups}>
          { renderParticipantControls }
        </GroupSegment>
      ) : (
        <>
          <GroupSegment uid={uid} sectionHeader={t`Act 1`} participants={act1Groups}>
            { renderParticipantControls }
          </GroupSegment>
          <GroupSegment
            uid={uid}
            sectionHeader={t`Act 2`}
            participants={act2Groups}
            secondaryLabel={clueSecondaryLabel}
          >
            { renderParticipantControls }
          </GroupSegment>
          <GroupSegment uid={uid} sectionHeader={t`Act 3`} participants={act3Groups}>
            { renderParticipantControls }
          </GroupSegment>
        </>
      ) }
      <GroupSegment uid={uid} sectionHeader={t`Eliminated`} participants={eliminatedGroups} />
    </CollapseList>
  );
}

function YourGroupControls({
  eventId,
  gameState,
  uid,
  isOrganizer,
  showConfirmDialog,
}: Props & {
  showConfirmDialog: ShowConfirmDialog;
}) {
  const act = (uid && gameState.groupStatus && gameState.groupStatus[uid]) || 1;
  const gooFight = !!gameState.goo;
  const eliminated = (uid && gameState.groupStatus && gameState.groupStatus[uid] === -1);
  const receivedClues = sumBy(gameState.warAnnouncements || [], c => c.to === uid && c.type === PersonalWarAnnouncementType.CLUE ? 1 : 0);
  const requestedNyHelp = find(gameState.helpNeeded?.ny, x => x === uid);
  const requestedMnHelp = find(gameState.helpNeeded?.mn, x => x === uid);
  const requestedPvHelp = find(gameState.helpNeeded?.pv, x => x === uid);
  const requestedClueHelp = find(gameState.helpNeeded?.clues, x => x === uid);
  const nextAct = act + 1;

  const advanceAct = useCallback(async (): Promise<string | undefined> => {
    showConfirmDialog({
      title: t`Advance to Act ${nextAct}`,
      body: t`Is your group ready to advance to Act ${nextAct}?`,
      updateType: GroupWarUpdateType.ACT,
      value: nextAct,
    });
    return undefined;
  }, [showConfirmDialog, nextAct]);
  const eliminateGroup = useCallback(async (): Promise<string | undefined> => {
    showConfirmDialog({
      title: t`Group eliminated?`,
      body: t`Has your entire party been eliminated? If you are in Act 1 or Act 2, this will automatically add doom to handle your elimination.`,
      updateType: GroupWarUpdateType.ACT,
      value: -1,
    });
    return undefined;
  }, [showConfirmDialog]);
  return (
    <Grid item xs={12} sm={12} md={4}>
      <Box m={2}>
        <MediaCard
          image="/img/red_cult.jpg"
          imageTitle="a red cultist"
          title={t`Your group`}
        >
          { !isOrganizer && !!uid && (
            <>
              { !gameState.goo && !eliminated && (
                <GameInputControl
                  eventId={eventId}
                  label={t`Place Mythos Phase Doom (1 per faction)`}
                  updateType={GroupWarUpdateType.DOOM_ALL}
                  fixedValue={1}
                  variant="outlined"
                  noDivider
                />
              ) }
              <Box mt={2} mb={3}><Divider /></Box>
              { eliminated && (
                <Box mb={1}>
                  <Typography>
                    { t`Your group has been eliminated.` }
                  </Typography>
                </Box>
              ) }
              { !eliminated && !gooFight && (
                <Box mb={1}>
                  <Typography>
                    { t`Group act progress: Act ${act}a` }
                  </Typography>
                  { !!uid && (act === 3 || act === 2) && (
                    <>
                      <Typography>
                        { t`Hub Dimension clues received from other groups: ${receivedClues}` }
                      </Typography>
                    </>
                  ) }
                  { !eliminated && act === 2 && (
                    <>
                      <Box mt={2} mb={3}><Divider /></Box>
                      <GameInputControl
                        eventId={eventId}
                        fixedValue={requestedClueHelp ? 0 : 1}
                        label={requestedClueHelp ? t`Cancel request for help closing Hub Dimension` : t`Request help closing Hub Dimension`}
                        updateType={GroupWarUpdateType.REQUEST_HELP_CLUES}
                        variant={requestedClueHelp ? 'contained' : 'outlined'}
                        noDivider
                      />
                    </>
                  ) }
                </Box>
              ) }
              { act < 3 && !eliminated && !gooFight && (
                <Box mb={2}>
                  <GameInputButton
                    label={t`Advance to Act ${nextAct}`}
                    submit={advanceAct}
                    variant="outlined"
                    disabled={false}
                  />
                </Box>
              ) }
              { !eliminated && (
                <Box mb={2}>
                  <GameInputButton
                    label={t`Group eliminated`}
                    submit={eliminateGroup}
                    variant="outlined"
                    disabled={false}
                  />
                </Box>
              ) }
              { !eliminated && (
                <>
                  <Box mt={2} mb={3}><Divider /></Box>
                  <Typography>
                    { t`Ask for help from other groups to damage enemies at these locations.` }
                  </Typography>
                  <GameInputControl
                    eventId={eventId}
                    fixedValue={requestedMnHelp ? 0 : 1}
                    label={requestedMnHelp ? t`Cancel request for help in Montréal` : t`Request help in Montréal`}
                    updateType={GroupWarUpdateType.REQUEST_HELP_MN}
                    variant={requestedMnHelp ? 'contained' : 'outlined'}
                    noDivider
                  />
                  <GameInputControl
                    eventId={eventId}
                    fixedValue={requestedNyHelp ? 0 : 1}
                    label={requestedNyHelp ? t`Cancel request for help in New York` : t`Request help in New York`}
                    updateType={GroupWarUpdateType.REQUEST_HELP_NY}
                    variant={requestedNyHelp ? 'contained' : 'outlined'}
                    noDivider
                  />
                  <GameInputControl
                    eventId={eventId}
                    fixedValue={requestedPvHelp ? 0 : 1}
                    label={requestedPvHelp ? t`Cancel request for help in Providence` : t`Request help in Providence`}
                    updateType={GroupWarUpdateType.REQUEST_HELP_PV}
                    variant={requestedPvHelp ? 'contained' : 'outlined'}
                    noDivider
                  />
                </>
              ) }
            </>
          ) }
        </MediaCard>
      </Box>
    </Grid>
  );
}

export default function WarGameControls({ event, eventId, gameState, isOrganizer, uid }: Props) {
  const isParticipant = !!find(values(event.participants || {}), participant => participant.user === uid);
  const participantUid = isParticipant || isOrganizer ? uid : undefined;
  const [confirmDialog, showConfirmDialog] = useConfirmDialog(eventId);
  return (
    <Grid container>
      <Announcements
        event={event}
        eventId={eventId}
        gameState={gameState}
        isOrganizer={isOrganizer}
        uid={participantUid}
        cardWidth={4}
      >
        <GroupControls
          event={event}
          eventId={eventId}
          gameState={gameState}
          isOrganizer={isOrganizer}
          uid={participantUid}
          showConfirmDialog={showConfirmDialog}
        />
      </Announcements>
      { !isOrganizer && !!participantUid && (
        <YourGroupControls
          event={event}
          eventId={eventId}
          gameState={gameState}
          isOrganizer={isOrganizer}
          showConfirmDialog={showConfirmDialog}
          uid={participantUid}
        />
      ) }
      { gameState.goo ? (
        <GreatOldOneBlock
          event={event}
          eventId={eventId}
          gameState={gameState}
          faction={gameState.goo}
          isOrganizer={isOrganizer}
          uid={uid}
        />
      ): (
        <DoomBlock
          eventId={eventId}
          gameState={gameState}
          uid={participantUid}
        />
      ) }
      { confirmDialog }
    </Grid>
  );
}
