import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { ref, child } from 'firebase/database';
import { Box, Grid, Typography } from '@material-ui/core';
import { useObjectVal } from 'react-firebase-hooks/database';

import { firebaseDatabase } from '../firebase';

import { ArkhamEvent, BlobEvent } from '../lib/schema';
import CountdownTimer from './core/CountdownTimer';
import MediaCard from './core/MediaCard';

interface Props {
  eventId: string;
}

function BlobStatsCard({ blob }: { blob: BlobEvent }) {
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Box m={2}>
        <MediaCard image="/img/blob_header.png" imageTitle="A blob consuming a car and a house." title="">
          { blob.gameState ?
          <CountdownTimer variant="h5" title="Time remaining:" gameState={blob.gameState} /> :
          <Typography variant="h5"  gutterBottom>Time remaining: Not started</Typography>
          }
          <Typography variant="h5" gutterBottom>
            8L-08 Damage: {blob.gameState ? `${blob.gameState.damage} / ${blob.gameState.health}` : '???'}
          </Typography>
          <Typography variant="h5" gutterBottom>
            Countermeasures: {blob.gameState ? blob.gameState.countermeasures : '???'}
          </Typography>
          <Typography variant="h5" gutterBottom>
            Clues: {blob.gameState && blob.gameState.clueGoal ? `${blob.gameState.clueGoal.clues} / ${blob.gameState.clueGoal.goal}` : '???'}
          </Typography>
        </MediaCard>
      </Box>
    </Grid>
  );
}

export default function BlobStats({ eventId }: Props) {
  const [event, loading] = useObjectVal<ArkhamEvent>(
    child(ref(firebaseDatabase, '/blobs'), eventId)
  );
  return (
    <>
      { event && (
        <Helmet>
          <title>{event.name}</title>
          <meta property="og:title" content={event.name} />
          { event.description && <meta property="og:description" content={event.description} /> }
        </Helmet>
      ) }
      { !loading && !event && <Redirect to="/" /> }
      <Grid item sm={1} />
      <Grid item xs={12} sm={12}>
        { !loading && event && event.gameType !== 'war' && <BlobStatsCard blob={event} />}
      </Grid>
    </>
  );
}