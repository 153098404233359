import {
  SET_LANGUAGE,
  SetLanguageAction,
} from '../actions/types';

interface SettingsState {
  lang?: string;
}

const DEFAULT_SETTINGS_STATE: SettingsState = {
};

type SettingAction = SetLanguageAction;

function settingsReducer(
  state: SettingsState = DEFAULT_SETTINGS_STATE,
  action: SettingAction
): SettingsState {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.lang,
      };
    default: {
      return state;
    }
  }
}

export default settingsReducer;
