import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { t, jt } from 'ttag';

export default function About() {
  const patreon = <a href="https://www.patreon.com/bePatron?u=47090078">Patreon</a>;
  return (
    <Grid item xs={12}>
      <Box m={2}>
        <Typography variant="body1" gutterBottom>
          { t`This website is dedicated to providing a way for players of the Arkham Horror LCG to connect when far apart.` }
        </Typography>
        <Typography variant="body1" gutterBottom>
          { jt`Costs to maintain the site are provided by the generous support of fans on ${patreon}. If you like what we are doing, consider supporting us.` }
        </Typography>
        <Typography variant="body1" gutterBottom>
          The information presented on this site about Arkham Horror: The Card Game, both literal and graphical, is copyrighted by Fantasy Flight Games. This website is not produced, endorsed, supported, or affiliated with Fantasy Flight Games.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Arkham Cards logo by <a href="https://t.me/sarnetsky">Eugene Sarnetsky</a>
        </Typography>
      </Box>
    </Grid>
  );
}