import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { map, partition, sumBy } from 'lodash';
import { Redirect } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { ref, child, set } from 'firebase/database';
import { Box, Button,
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
  List, ListItem, ListItemText, ListSubheader,
  Divider, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useObjectVal } from 'react-firebase-hooks/database';
import { ngettext, msgid, t } from 'ttag.macro';
import { add as addDate, format as formatDate } from 'date-fns';

import { firebaseDatabase } from '../../firebase';
import { dateString, timeString } from '../../lib/date';
import { ADMIN_USER, ArkhamEvent, BasicGameState, EventSecret, Participant, ParticipantStatus } from '../../lib/schema';
import Loading from '../core/Loading';
import SubmitButton from '../core/SubmitButton';
import GroupAndPlayerCounts from '../Event/GroupAndPlayerCounts';
import GameControls from './GameControls';
import { apiFunction } from '../../lib/api';
import { LocaleContext } from '../core/LocaleContext';
import { useAuth } from '../../AuthContext';

interface Props {
  eventId: string;
}

interface ItemProps {
  eventId: string;
  event: ArkhamEvent;
  isOrganizer: boolean;
}

function EventName({ eventId, event, isOrganizer }: ItemProps) {
  const [name, setName] = useState(event.name);

  const saveName = async () => {
    const updateEvent = apiFunction('event-updateEvent');
    await updateEvent({ eventId, name });
    return undefined;
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  if (!isOrganizer || event.gameState) {
    return (
      <Typography gutterBottom variant="h6">
        { event.name }
      </Typography>
    );
  }
  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          id="name"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          value={name}
          onChange={handleNameChange}
        />
      </Grid>
      { (name !== event.name) && (
        <Grid item xs={12} sm={6}>
          <SubmitButton
            variant="outlined"
            color="secondary"
            onSubmit={saveName}
          >
            Update Name
          </SubmitButton>
        </Grid>
      ) }
    </Grid>
  );
}


const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: 350,
  },
  participantList: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 155,
  },
  description: {
    'white-space': 'pre-line',
  },
}));

function EventDescription({ eventId, event, isOrganizer }: ItemProps) {
  const [description, setDescription] = useState(event.description || '');
  const saveDescription = async () => {
    await set(child(
      child(ref(firebaseDatabase, '/blobs'), eventId),
      'description'
    ), description);
    return undefined;
  };
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  if (!isOrganizer || event.gameState) {
    return (
      <Box pb={2}>
        { !!event.description && (
          <ReactMarkdown children={event.description} />
        ) }
      </Box>
    );
  }
  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12} sm={9}>
        <TextField
          margin="normal"
          id="description"
          label={t`Description (supports markdown)`}
          fullWidth
          multiline
          value={description}
          onChange={handleDescriptionChange}
        />
      </Grid>
      { (description !== event.description) && (
        <Grid item xs={12} sm={3}>
          <SubmitButton
            variant="outlined"
            color="secondary"
            onSubmit={saveDescription}
          >
            { t`Update Description` }
          </SubmitButton>
        </Grid>
      ) }
    </Grid>
  );
}

function EventStartTime({ event, isOrganizer, eventId }: ItemProps) {
  const { locale } = useContext(LocaleContext);
  const [scheduledStart, setScheduledStart] = useState(new Date(event.scheduledStart));
  const saveScheduledStart = async () => {
    const updateEvent = apiFunction('event-updateEvent');
    await updateEvent({ eventId, scheduledStart: scheduledStart.getTime() });
    return undefined;
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduledStart(new Date(Date.parse(event.target.value)));
  };

  if (event.gameState) {
    const start = new Date(event.gameState.start);
    const date = dateString(start, locale);
    const time = timeString(start, locale);
      return (
      <Box pb={1}>
        <Typography variant="body1" color="textSecondary">
          { t`Start time` }
        </Typography>
        <Typography display="inline" variant="body1" color="textPrimary" gutterBottom>
          { t`${date} at ${time}` }
        </Typography>
      </Box>
    );
  }

  if (!isOrganizer) {
    return (
      <Box pb={1}>
        <Typography variant="body1" color="textSecondary">
          { t`Event start` }
        </Typography>
        <Typography display="inline" variant="body1" color="textPrimary" gutterBottom>
          {dateString(scheduledStart, locale)} at {timeString(scheduledStart, locale)}
        </Typography>
      </Box>
    );
  }
  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="normal"
          id="scheduledStart"
          label={t`Scheduled start time`}
          type="datetime-local"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={formatDate(scheduledStart, 'yyyy-MM-dd\'T\'HH:mm')}
          onChange={handleDateChange}
        />
      </Grid>
      { (scheduledStart.getTime() !== event.scheduledStart) && (
        <Grid item xs={12} sm={6}>
          <SubmitButton
            variant="outlined"
            color="secondary"
            onSubmit={saveScheduledStart}
          >
            { t`Update scheduled start` }
          </SubmitButton>
        </Grid>
      ) }
    </Grid>
  );
}

function EventEndTime({ eventId, gameState }: { eventId: string; gameState: BasicGameState }) {
  const [end, setEnd] = useState(new Date(gameState.end));
  const [extraMinutes, setExtraMinutes] = useState(0);
  const saveScheduledEnd = async () => {
    await set(
      child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'end'),
      end.getTime());
    return undefined;
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnd(new Date(Date.parse(event.target.value)));
  };
  const handleExtraMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExtraMinutes(parseInt(event.target.value, 10));
  };
  const addExtraMinutes = async () => {
    const end = new Date(gameState.end);
    const newEnd = addDate(end, { minutes: extraMinutes });
    await set(
      child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'end'), newEnd.getTime());
    setEnd(newEnd);
    setExtraMinutes(0);
    return undefined;
  };

  return (
    <Box pb={1}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            type="datetime-local"
            label="Event End Time"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={formatDate(end, 'yyyy-MM-dd\'T\'HH:mm')}
            onChange={handleDateChange}
          />
        </Grid>
        { (end.getTime() !== gameState.end) && (
          <Grid item xs={12} sm={6}>
            <SubmitButton
              variant="outlined"
              color="secondary"
              onSubmit={saveScheduledEnd}
              disabled={end.getTime() < gameState.start}
            >
              Update End Time
            </SubmitButton>
          </Grid>
        ) }
      </Grid>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            type="number"
            label="Add Extra Minutes"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={extraMinutes}
            onChange={handleExtraMinutesChange}
          />
        </Grid>
        { !!extraMinutes && (
          <Grid item xs={12} sm={6}>
            <SubmitButton
              variant="outlined"
              color="secondary"
              onSubmit={addExtraMinutes}
            >
              Add Extra Minutes
            </SubmitButton>
          </Grid>
        ) }
      </Grid>
    </Box>
  );
}
interface RemainingTime {
  hours: number;
  minutes: number;
  seconds: number;
}

function CountdownTimer({ gameState }: { gameState: BasicGameState }) {
  const calculateTimeLeft = (): RemainingTime | undefined => {
    const now = new Date();
    const difference = gameState.end - now.getTime();
    if (difference > 0) {
      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return undefined;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });
  if (!timeLeft) {
    return (
      <Typography gutterBottom>
        Out of Time!
      </Typography>
    );
  }
  if (timeLeft.hours > 0 || timeLeft.minutes > 10) {
    let minutes = timeLeft.minutes.toString();
    while (minutes.length < 2) {
      minutes = "0" + minutes;
    }
    return (
      <Typography gutterBottom>
        {timeLeft.hours}:{minutes}
      </Typography>
    );
  }
  let seconds = timeLeft.seconds.toString();
    while (seconds.length < 2) {
      seconds = "0" + seconds;
    }
  return (
    <Typography gutterBottom>
      {timeLeft.minutes}:{seconds}
    </Typography>
  );
}

function EventDiscord({ eventId, discordWebhook }: { eventId: string; discordWebhook?: string }) {
  const [webhook, setWebhook] = useState(discordWebhook || '');
  const saveWebhook = async () => {
    const updateEvent = apiFunction('event-updateEvent');
    await updateEvent({ eventId, discordWebhook: webhook });
    return undefined;
  };
  const handleWebhookChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWebhook(event.target.value);
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12} sm={9}>
        <TextField
          margin="normal"
          id="webhook"
          label="Discord Webhook"
          fullWidth
          multiline
          value={webhook}
          onChange={handleWebhookChange}
        />
      </Grid>
      { webhook !== (discordWebhook || '') && (
        <Grid item xs={12} sm={3}>
          <SubmitButton
            variant="outlined"
            color="secondary"
            onSubmit={saveWebhook}
          >
            {t`Update Webhook`}
          </SubmitButton>
        </Grid>
      ) }
    </Grid>
  );
}

function EventSecretSection({ eventId, event }: { eventId: string; event: ArkhamEvent }) {
  const [eventSecret, loading, error] = useObjectVal<EventSecret>(
    child(ref(firebaseDatabase, '/blob_secrets/'), eventId)
  );
  const [participantsOpen, setParticipantsOpen] = useState(false);
  const classes = useStyles();
  const showParticipantDialog = () => {
    setParticipantsOpen(true);
  };
  const hideParticipantsDialog = () => {
    setParticipantsOpen(false);
  };
  if (loading) {
    return null;
  }
  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }
  const discordWebhook = eventSecret && eventSecret.discordWebhook;
  const [confirmedParticipants, unconfirmedParticipants] = partition(event.participants, p => p.status === ParticipantStatus.CONFIRMED)

  const renderParticipant = (participant: Participant) => {
    const contactInfo = ((eventSecret && eventSecret.participantContact) || {})[participant.user] || 'No contact info';
    return (
      <ListItem key={participant.user}>
        <ListItemText primary={`${participant.groupName} (${contactInfo})`} />
        <ListItemText secondary={ngettext(msgid`${participant.groupSize} player`, `${participant.groupSize} players`, participant.groupSize)} />
      </ListItem>
    );
  };

  const dialog = (
    <Dialog open={participantsOpen} onClose={hideParticipantsDialog}>
      <DialogTitle>{t`Participants`}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <GroupAndPlayerCounts event={event} />
        <List className={classes.participantList}>
          { !!confirmedParticipants.length && (
            <>
              <ListSubheader disableSticky>
                { t`Confirmed groups`}
              </ListSubheader>
              { map(confirmedParticipants, renderParticipant) }
            </>
          )}
          { unconfirmedParticipants.length ? (
            <>
              <ListSubheader disableSticky>
                { t`Unconfirmed groups`}
              </ListSubheader>
              { map(unconfirmedParticipants, renderParticipant) }
            </>
          ) : (
            <ListItem>
              <ListItemText primary={t`All groups are confirmed.`} />
            </ListItem>
          ) }
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={hideParticipantsDialog}
        >
          { t`Done` }
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {dialog}
      <Grid item>
        <EventDiscord eventId={eventId} discordWebhook={discordWebhook} />
      </Grid>
      <Grid item>
        <Button
          onClick={showParticipantDialog}
          variant="outlined"
          color="secondary"
        >
          { t`View participants` }
        </Button>
      </Grid>
    </>
  );
}


function EventDetails({ event, isOrganizer, eventId }: ItemProps) {
  const participants = event.participants || [];
  const groupCount = participants.length
  const playerCount = sumBy(participants, p => (!event.gameState || p.confirmedAt) ? p.groupSize : 0);
  return (
    <Grid container>
      <Grid item xs={12} sm={8} container direction="column">
        <Grid item>
          <EventDescription event={event} eventId={eventId} isOrganizer={isOrganizer} />
        </Grid>
        { (!event.gameState || isOrganizer) && (
          <>
            <Grid item>
              <EventStartTime event={event} eventId={eventId} isOrganizer={isOrganizer} />
            </Grid>
          </>
        ) }
        { event.gameState && (
          <>
            { isOrganizer && (
              <Grid item>
                <EventEndTime eventId={eventId} gameState={event.gameState} />
              </Grid>
            ) }
            <Grid item>
              <Typography variant="body1" color="textSecondary">
                { t`Time remaining` }
              </Typography>
              <CountdownTimer gameState={event.gameState} />
            </Grid>
          </>
        ) }
        { isOrganizer && (
          <EventSecretSection eventId={eventId} event={event} />
        ) }

        <Grid item>
          <Box pt={2} pb={1}>
            <Typography variant="body1" color="textSecondary">
              { event.gameState ? t`Groups` : t`Registered groups` }
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {ngettext(msgid`${groupCount} group`, `${groupCount} groups`, groupCount)}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box pb={1}>
            <Typography variant="body1" color="textSecondary">
              { t`Players` }
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {ngettext(msgid`${playerCount} player`, `${playerCount} players`, playerCount)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}


function DeleteEventButton({ eventId, event }: { eventId: string; event: ArkhamEvent }) {
  const [modalOpen, setModalOpen] = useState(false);
  const showDeleteModal = () => {
    setModalOpen(true);
  };
  const hideDeleteModal = () => {
    setModalOpen(false);
  };
  const deleteEvent = async () => {
    const doDeleteEvent = apiFunction('event-deleteEvent');
    const data = {
      eventId,
    };
    await doDeleteEvent(data);
    return undefined;
  };
  const classes = useStyles();
  const dialog = (
    <Dialog open={modalOpen} onClose={hideDeleteModal}>
      <DialogTitle>{t`Permanently Delete Event`}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <DialogContentText>
          {t`Are you sure you want to delete this event?`}
        </DialogContentText>
        <DialogContentText>
          {t`This action cannot be undone and all data will be removed.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SubmitButton
          fullWidth
          variant="contained"
          color="secondary"
          onSubmit={deleteEvent}
        >
          {t`Delete Event`}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Box m={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={showDeleteModal}
        >
          {t`Delete Event`}
        </Button>
      </Box>
      {dialog}
    </>
  );
}


function ArchiveEventButton({ eventId, event }: { eventId: string; event: ArkhamEvent }) {
  const [modalOpen, setModalOpen] = useState(false);
  const showArchiveModal = () => {
    setModalOpen(true);
  };
  const hideArchiveModal = () => {
    setModalOpen(false);
  };
  const archiveEvent = async () => {
    const doArchiveEvent = apiFunction('event-archiveEvent');
    const data = {
      eventId,
    };
    await doArchiveEvent(data);
    return undefined;
  };
  const classes = useStyles();
  const dialog = (
    <Dialog open={modalOpen} onClose={hideArchiveModal}>
      <DialogTitle>{t`Archive Event`}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <DialogContentText>
          {t`Are you sure you want to archive this event?`}
        </DialogContentText>
        <DialogContentText>
          {t`The event will no longer be visible on the homepage, but it can still be visited by URL or have its stats accessed by the admins.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SubmitButton
          fullWidth
          variant="contained"
          color="secondary"
          onSubmit={archiveEvent}
        >
          {t`Archive Event`}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Box m={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={showArchiveModal}
        >
          {t`Archive Event`}
        </Button>
      </Box>
      {dialog}
    </>
  );
}

export default function EventView({ eventId }: Props) {
  const [event, loading, error] = useObjectVal<ArkhamEvent>(
    child(ref(firebaseDatabase, '/blobs'), eventId)
  );
  const { authUser } = useAuth();
  const formContent = useMemo(() => {
    const isOrganizer = !!(authUser && event && event.organizer === authUser.uid);
    if (loading || !event) {
      return (
        <Box p={4}>
          <Loading />
        </Box>
      );
    }
    if (error) {
      return <strong>Error: {error}</strong>;
    }
    return (
      <Paper>
        <Box pb={2}>
          <Box m={2} pt={2}>
            <EventName
              eventId={eventId}
              event={event}
              isOrganizer={isOrganizer || authUser?.uid === ADMIN_USER}
            />
          </Box>
          <Divider />
          <Box mt={2} mx={2}>
            <EventDetails
              event={event}
              eventId={eventId}
              isOrganizer={isOrganizer || authUser?.uid === ADMIN_USER}
            />
          </Box>
          <Box my={2}>
            <Box>
              <GameControls
                event={event}
                eventId={eventId}
                isOrganizer={isOrganizer}
                uid={authUser?.uid}
              />
            </Box>
          </Box>
          { (isOrganizer || authUser?.uid === ADMIN_USER) && (
            <>
              <DeleteEventButton event={event} eventId={eventId} />
              <ArchiveEventButton event={event} eventId={eventId} />
            </>
          ) }
        </Box>
      </Paper>
    );
  }, [authUser, event, eventId, error, loading])
  return (
    <>
      { event && (
        <Helmet>
          <title>{event.name}</title>
          <meta property="og:title" content={event.name} />
          { event.description && <meta property="og:description" content={event.description} /> }
        </Helmet>
      ) }
      { !loading && !event && <Redirect to="/" /> }
      <Grid item sm={1} />
      <Grid item xs={12} sm={10}>
        { formContent }
      </Grid>
    </>
  );
}