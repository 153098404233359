import { firebaseFunctions } from '../firebase';
import { httpsCallable } from 'firebase/functions';

function getLang() {
  const split = window.location.host.split('.');
  if (split.length < 3) {
    return 'en';
  }
  return split[0];
}

export function apiFunction(functionName: string) {
  const lang = getLang();
  return (request: any) => {
    return httpsCallable<any, any>(firebaseFunctions, functionName)({ ...request, locale: lang });
  };
}
