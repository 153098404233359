import React, { useCallback, useMemo } from 'react';
import { List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { flatMap, map } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { UserInfoFragment, UserProfileFragment } from '../../graphql/schema';

import { t } from 'ttag.macro';

import { apiFunction } from '../../lib/api';

interface Props {
  profile?: UserProfileFragment;
  refreshProfile: () => void;
}

interface FriendAction {
  title: string;
  icon: 'check' | 'remove';
  onPress: (userId: string) => void;
}

function FriendActionButton({ userId, action: { onPress, title, icon } }: { userId: string; action: FriendAction }) {
  const onClick = useCallback(() => onPress(userId), [onPress, userId]);
  return (
    <IconButton aria-label={title} onClick={onClick}>
      { icon === 'check' ? <CheckIcon /> : <CloseIcon /> }
    </IconButton>
  );
}

function FriendSection({ users, title, actions }: {
  users: UserInfoFragment[];
  title: string;
  actions: FriendAction[];
}) {
  if (!users.length) {
    return null;
  }
  return (
    <>
      <ListSubheader>
        { title }
      </ListSubheader>
      { map(users, user => (
        <ListItem key={user.id}>
          <ListItemText primary={user.handle || user.id} />
          <ListItemSecondaryAction>
            { map(actions, (a, idx) => <FriendActionButton key={idx} userId={user.id} action={a} /> ) }
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </>
  )
}

export default function FriendRequestsComponent({ profile, refreshProfile }: Props) {
  const updateFriendRequest = apiFunction('social-updateFriendRequest');
  const onSubmit = useCallback(async (userId: string, action: 'request' | 'revoke') => {
    const response = await updateFriendRequest({ userId, action });
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    refreshProfile();
    return undefined;
  }, [updateFriendRequest, refreshProfile]);
  const sendFriendRequest = useCallback((userId: string) => {
    return onSubmit(userId, 'request');
  }, [onSubmit])
  const revokeFriendRequest = useCallback((userId: string) => {
    return onSubmit(userId, 'revoke');
  }, [onSubmit])
  const [receivedRequests, sentRequests, friends] = useMemo(() => {
    if (!profile || !profile.friends) {
      return [[], [], [], []];
    }
    const received = flatMap(profile.received_requests, friend => {
      if (friend.user) {
        return [friend.user];
      }
      return [];
    });
    const sent = flatMap(profile.sent_requests, friend => {
      if (friend.user) {
        return [friend.user];
      }
      return [];
    });
    const friends= flatMap(profile.friends, friend => {
      if (friend.user) {
        return [friend.user];
      }
      return [];
    });
    return [received, sent, friends];
  }, [profile]);
  if (!profile) {
    return null;
  }
  return (
    <List>
      <FriendSection
        key="received"
        title={t`Friend Requests`}
        users={receivedRequests}
        actions={[{
          title: t`Accept`,
          onPress: sendFriendRequest,
          icon: 'check',
        }, {
          title: t`Reject`,
          onPress: revokeFriendRequest,
          icon: 'remove',
        }]}
      />
      <FriendSection
        key="sent"
        title={t`Pending Requests`}
        users={sentRequests}
        actions={[{
          title: t`Revoke`,
          onPress: revokeFriendRequest,
          icon: 'remove',
        }]}
      />
      <FriendSection
        key="friends"
        title={t`Friends`}
        users={friends}
        actions={[{
          title: t`Revoke`,
          onPress: revokeFriendRequest,
          icon: 'remove',
        }]}
      />
    </List>
  )
}
