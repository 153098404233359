import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "base_decks" */
export type Base_Decks = {
  __typename?: 'base_decks';
  /** An object relationship */
  campaign?: Maybe<Campaign>;
  campaign_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  deck?: Maybe<Campaign_Deck>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "base_decks" */
export type Base_Decks_Aggregate = {
  __typename?: 'base_decks_aggregate';
  aggregate?: Maybe<Base_Decks_Aggregate_Fields>;
  nodes: Array<Base_Decks>;
};

/** aggregate fields of "base_decks" */
export type Base_Decks_Aggregate_Fields = {
  __typename?: 'base_decks_aggregate_fields';
  avg?: Maybe<Base_Decks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Base_Decks_Max_Fields>;
  min?: Maybe<Base_Decks_Min_Fields>;
  stddev?: Maybe<Base_Decks_Stddev_Fields>;
  stddev_pop?: Maybe<Base_Decks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Base_Decks_Stddev_Samp_Fields>;
  sum?: Maybe<Base_Decks_Sum_Fields>;
  var_pop?: Maybe<Base_Decks_Var_Pop_Fields>;
  var_samp?: Maybe<Base_Decks_Var_Samp_Fields>;
  variance?: Maybe<Base_Decks_Variance_Fields>;
};


/** aggregate fields of "base_decks" */
export type Base_Decks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Base_Decks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "base_decks" */
export type Base_Decks_Aggregate_Order_By = {
  avg?: InputMaybe<Base_Decks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Base_Decks_Max_Order_By>;
  min?: InputMaybe<Base_Decks_Min_Order_By>;
  stddev?: InputMaybe<Base_Decks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Base_Decks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Base_Decks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Base_Decks_Sum_Order_By>;
  var_pop?: InputMaybe<Base_Decks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Base_Decks_Var_Samp_Order_By>;
  variance?: InputMaybe<Base_Decks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "base_decks" */
export type Base_Decks_Arr_Rel_Insert_Input = {
  data: Array<Base_Decks_Insert_Input>;
};

/** aggregate avg on columns */
export type Base_Decks_Avg_Fields = {
  __typename?: 'base_decks_avg_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "base_decks" */
export type Base_Decks_Avg_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "base_decks". All fields are combined with a logical 'AND'. */
export type Base_Decks_Bool_Exp = {
  _and?: InputMaybe<Array<Base_Decks_Bool_Exp>>;
  _not?: InputMaybe<Base_Decks_Bool_Exp>;
  _or?: InputMaybe<Array<Base_Decks_Bool_Exp>>;
  campaign?: InputMaybe<Campaign_Bool_Exp>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  deck?: InputMaybe<Campaign_Deck_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "base_decks" */
export type Base_Decks_Inc_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "base_decks" */
export type Base_Decks_Insert_Input = {
  campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  deck?: InputMaybe<Campaign_Deck_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Base_Decks_Max_Fields = {
  __typename?: 'base_decks_max_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "base_decks" */
export type Base_Decks_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Base_Decks_Min_Fields = {
  __typename?: 'base_decks_min_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "base_decks" */
export type Base_Decks_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "base_decks" */
export type Base_Decks_Mutation_Response = {
  __typename?: 'base_decks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Decks>;
};

/** Ordering options when selecting data from "base_decks". */
export type Base_Decks_Order_By = {
  campaign?: InputMaybe<Campaign_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  deck?: InputMaybe<Campaign_Deck_Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "base_decks" */
export enum Base_Decks_Select_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "base_decks" */
export type Base_Decks_Set_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Base_Decks_Stddev_Fields = {
  __typename?: 'base_decks_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "base_decks" */
export type Base_Decks_Stddev_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Base_Decks_Stddev_Pop_Fields = {
  __typename?: 'base_decks_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "base_decks" */
export type Base_Decks_Stddev_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Base_Decks_Stddev_Samp_Fields = {
  __typename?: 'base_decks_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "base_decks" */
export type Base_Decks_Stddev_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Base_Decks_Sum_Fields = {
  __typename?: 'base_decks_sum_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "base_decks" */
export type Base_Decks_Sum_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Base_Decks_Var_Pop_Fields = {
  __typename?: 'base_decks_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "base_decks" */
export type Base_Decks_Var_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Base_Decks_Var_Samp_Fields = {
  __typename?: 'base_decks_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "base_decks" */
export type Base_Decks_Var_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Base_Decks_Variance_Fields = {
  __typename?: 'base_decks_variance_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "base_decks" */
export type Base_Decks_Variance_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "campaign" */
export type Campaign = {
  __typename?: 'campaign';
  /** An array relationship */
  access: Array<Campaign_Access>;
  /** An aggregate relationship */
  access_aggregate: Campaign_Access_Aggregate;
  archived?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  base_decks: Array<Base_Decks>;
  /** An aggregate relationship */
  base_decks_aggregate: Base_Decks_Aggregate;
  campaignNotes?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  campaign_guide?: Maybe<Campaign_Guide>;
  chaosBag?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  chaos_bag_result: Array<Chaos_Bag_Result>;
  /** An aggregate relationship */
  chaos_bag_result_aggregate: Chaos_Bag_Result_Aggregate;
  created_at: Scalars['timestamptz'];
  cycleCode?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  difficulty?: Maybe<Scalars['String']>;
  guide_version?: Maybe<Scalars['Int']>;
  guided?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** An array relationship */
  investigator_data: Array<Investigator_Data>;
  /** An aggregate relationship */
  investigator_data_aggregate: Investigator_Data_Aggregate;
  /** An array relationship */
  investigators: Array<Campaign_Investigator>;
  /** An aggregate relationship */
  investigators_aggregate: Campaign_Investigator_Aggregate;
  /** An array relationship */
  latest_decks: Array<Latest_Decks>;
  /** An aggregate relationship */
  latest_decks_aggregate: Latest_Decks_Aggregate;
  /** An object relationship */
  link_a_campaign: Campaign;
  link_a_campaign_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  link_b_campaign: Campaign;
  link_b_campaign_id?: Maybe<Scalars['Int']>;
  link_campaign_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  linked_campaign: Campaign;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  owner: Users;
  owner_id: Scalars['String'];
  scenarioResults?: Maybe<Scalars['jsonb']>;
  showInterludes?: Maybe<Scalars['Boolean']>;
  standaloneId?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
  uuid: Scalars['String'];
  weaknessSet?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "campaign" */
export type CampaignAccessArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Access_Order_By>>;
  where?: InputMaybe<Campaign_Access_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignAccess_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Access_Order_By>>;
  where?: InputMaybe<Campaign_Access_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignBase_DecksArgs = {
  distinct_on?: InputMaybe<Array<Base_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Base_Decks_Order_By>>;
  where?: InputMaybe<Base_Decks_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignBase_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Base_Decks_Order_By>>;
  where?: InputMaybe<Base_Decks_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignCampaignNotesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "campaign" */
export type CampaignChaosBagArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "campaign" */
export type CampaignChaos_Bag_ResultArgs = {
  distinct_on?: InputMaybe<Array<Chaos_Bag_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chaos_Bag_Result_Order_By>>;
  where?: InputMaybe<Chaos_Bag_Result_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignChaos_Bag_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chaos_Bag_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chaos_Bag_Result_Order_By>>;
  where?: InputMaybe<Chaos_Bag_Result_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignInvestigator_DataArgs = {
  distinct_on?: InputMaybe<Array<Investigator_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investigator_Data_Order_By>>;
  where?: InputMaybe<Investigator_Data_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignInvestigator_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investigator_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investigator_Data_Order_By>>;
  where?: InputMaybe<Investigator_Data_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignInvestigatorsArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Investigator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Investigator_Order_By>>;
  where?: InputMaybe<Campaign_Investigator_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignInvestigators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Investigator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Investigator_Order_By>>;
  where?: InputMaybe<Campaign_Investigator_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignLatest_DecksArgs = {
  distinct_on?: InputMaybe<Array<Latest_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Latest_Decks_Order_By>>;
  where?: InputMaybe<Latest_Decks_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignLatest_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Latest_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Latest_Decks_Order_By>>;
  where?: InputMaybe<Latest_Decks_Bool_Exp>;
};


/** columns and relationships of "campaign" */
export type CampaignScenarioResultsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "campaign" */
export type CampaignStandaloneIdArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "campaign" */
export type CampaignWeaknessSetArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "campaign_access" */
export type Campaign_Access = {
  __typename?: 'campaign_access';
  campaign_id: Scalars['Int'];
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "campaign_access" */
export type Campaign_Access_Aggregate = {
  __typename?: 'campaign_access_aggregate';
  aggregate?: Maybe<Campaign_Access_Aggregate_Fields>;
  nodes: Array<Campaign_Access>;
};

/** aggregate fields of "campaign_access" */
export type Campaign_Access_Aggregate_Fields = {
  __typename?: 'campaign_access_aggregate_fields';
  avg?: Maybe<Campaign_Access_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Campaign_Access_Max_Fields>;
  min?: Maybe<Campaign_Access_Min_Fields>;
  stddev?: Maybe<Campaign_Access_Stddev_Fields>;
  stddev_pop?: Maybe<Campaign_Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Campaign_Access_Stddev_Samp_Fields>;
  sum?: Maybe<Campaign_Access_Sum_Fields>;
  var_pop?: Maybe<Campaign_Access_Var_Pop_Fields>;
  var_samp?: Maybe<Campaign_Access_Var_Samp_Fields>;
  variance?: Maybe<Campaign_Access_Variance_Fields>;
};


/** aggregate fields of "campaign_access" */
export type Campaign_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "campaign_access" */
export type Campaign_Access_Aggregate_Order_By = {
  avg?: InputMaybe<Campaign_Access_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaign_Access_Max_Order_By>;
  min?: InputMaybe<Campaign_Access_Min_Order_By>;
  stddev?: InputMaybe<Campaign_Access_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Campaign_Access_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Campaign_Access_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Campaign_Access_Sum_Order_By>;
  var_pop?: InputMaybe<Campaign_Access_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Campaign_Access_Var_Samp_Order_By>;
  variance?: InputMaybe<Campaign_Access_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "campaign_access" */
export type Campaign_Access_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Access_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Campaign_Access_On_Conflict>;
};

/** aggregate avg on columns */
export type Campaign_Access_Avg_Fields = {
  __typename?: 'campaign_access_avg_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "campaign_access" */
export type Campaign_Access_Avg_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "campaign_access". All fields are combined with a logical 'AND'. */
export type Campaign_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Access_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Access_Bool_Exp>>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  hidden?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_access" */
export enum Campaign_Access_Constraint {
  /** unique or primary key constraint */
  CampaignAccessPkey = 'campaign_access_pkey',
  /** unique or primary key constraint */
  CampaignAccessUserIdCampaignIdKey = 'campaign_access_user_id_campaign_id_key'
}

/** input type for incrementing numeric columns in table "campaign_access" */
export type Campaign_Access_Inc_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "campaign_access" */
export type Campaign_Access_Insert_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Campaign_Access_Max_Fields = {
  __typename?: 'campaign_access_max_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "campaign_access" */
export type Campaign_Access_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Access_Min_Fields = {
  __typename?: 'campaign_access_min_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "campaign_access" */
export type Campaign_Access_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaign_access" */
export type Campaign_Access_Mutation_Response = {
  __typename?: 'campaign_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Access>;
};

/** on conflict condition type for table "campaign_access" */
export type Campaign_Access_On_Conflict = {
  constraint: Campaign_Access_Constraint;
  update_columns: Array<Campaign_Access_Update_Column>;
  where?: InputMaybe<Campaign_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_access". */
export type Campaign_Access_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  hidden?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_access */
export type Campaign_Access_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "campaign_access" */
export enum Campaign_Access_Select_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "campaign_access" */
export type Campaign_Access_Set_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Campaign_Access_Stddev_Fields = {
  __typename?: 'campaign_access_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "campaign_access" */
export type Campaign_Access_Stddev_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Campaign_Access_Stddev_Pop_Fields = {
  __typename?: 'campaign_access_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "campaign_access" */
export type Campaign_Access_Stddev_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Campaign_Access_Stddev_Samp_Fields = {
  __typename?: 'campaign_access_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "campaign_access" */
export type Campaign_Access_Stddev_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Campaign_Access_Sum_Fields = {
  __typename?: 'campaign_access_sum_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "campaign_access" */
export type Campaign_Access_Sum_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "campaign_access" */
export enum Campaign_Access_Update_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Campaign_Access_Var_Pop_Fields = {
  __typename?: 'campaign_access_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "campaign_access" */
export type Campaign_Access_Var_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Campaign_Access_Var_Samp_Fields = {
  __typename?: 'campaign_access_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "campaign_access" */
export type Campaign_Access_Var_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Campaign_Access_Variance_Fields = {
  __typename?: 'campaign_access_variance_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "campaign_access" */
export type Campaign_Access_Variance_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregated selection of "campaign" */
export type Campaign_Aggregate = {
  __typename?: 'campaign_aggregate';
  aggregate?: Maybe<Campaign_Aggregate_Fields>;
  nodes: Array<Campaign>;
};

/** aggregate fields of "campaign" */
export type Campaign_Aggregate_Fields = {
  __typename?: 'campaign_aggregate_fields';
  avg?: Maybe<Campaign_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Campaign_Max_Fields>;
  min?: Maybe<Campaign_Min_Fields>;
  stddev?: Maybe<Campaign_Stddev_Fields>;
  stddev_pop?: Maybe<Campaign_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Campaign_Stddev_Samp_Fields>;
  sum?: Maybe<Campaign_Sum_Fields>;
  var_pop?: Maybe<Campaign_Var_Pop_Fields>;
  var_samp?: Maybe<Campaign_Var_Samp_Fields>;
  variance?: Maybe<Campaign_Variance_Fields>;
};


/** aggregate fields of "campaign" */
export type Campaign_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Append_Input = {
  campaignNotes?: InputMaybe<Scalars['jsonb']>;
  chaosBag?: InputMaybe<Scalars['jsonb']>;
  scenarioResults?: InputMaybe<Scalars['jsonb']>;
  standaloneId?: InputMaybe<Scalars['jsonb']>;
  weaknessSet?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Campaign_Avg_Fields = {
  __typename?: 'campaign_avg_fields';
  guide_version?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  link_a_campaign_id?: Maybe<Scalars['Float']>;
  link_b_campaign_id?: Maybe<Scalars['Float']>;
  link_campaign_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "campaign". All fields are combined with a logical 'AND'. */
export type Campaign_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Bool_Exp>>;
  access?: InputMaybe<Campaign_Access_Bool_Exp>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  base_decks?: InputMaybe<Base_Decks_Bool_Exp>;
  campaignNotes?: InputMaybe<Jsonb_Comparison_Exp>;
  campaign_guide?: InputMaybe<Campaign_Guide_Bool_Exp>;
  chaosBag?: InputMaybe<Jsonb_Comparison_Exp>;
  chaos_bag_result?: InputMaybe<Chaos_Bag_Result_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  cycleCode?: InputMaybe<String_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  difficulty?: InputMaybe<String_Comparison_Exp>;
  guide_version?: InputMaybe<Int_Comparison_Exp>;
  guided?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investigator_data?: InputMaybe<Investigator_Data_Bool_Exp>;
  investigators?: InputMaybe<Campaign_Investigator_Bool_Exp>;
  latest_decks?: InputMaybe<Latest_Decks_Bool_Exp>;
  link_a_campaign?: InputMaybe<Campaign_Bool_Exp>;
  link_a_campaign_id?: InputMaybe<Int_Comparison_Exp>;
  link_b_campaign?: InputMaybe<Campaign_Bool_Exp>;
  link_b_campaign_id?: InputMaybe<Int_Comparison_Exp>;
  link_campaign_id?: InputMaybe<Int_Comparison_Exp>;
  linked_campaign?: InputMaybe<Campaign_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<String_Comparison_Exp>;
  scenarioResults?: InputMaybe<Jsonb_Comparison_Exp>;
  showInterludes?: InputMaybe<Boolean_Comparison_Exp>;
  standaloneId?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uuid?: InputMaybe<String_Comparison_Exp>;
  weaknessSet?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign" */
export enum Campaign_Constraint {
  /** unique or primary key constraint */
  CampaignDataPkey = 'campaign_data_pkey'
}

/** columns and relationships of "campaign_deck" */
export type Campaign_Deck = {
  __typename?: 'campaign_deck';
  arkhamdb_id?: Maybe<Scalars['Int']>;
  arkhamdb_user?: Maybe<Scalars['Int']>;
  base?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  campaign: Campaign;
  campaign_id: Scalars['Int'];
  content?: Maybe<Scalars['jsonb']>;
  content_hash?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  investigator: Scalars['String'];
  /** An object relationship */
  investigator_data?: Maybe<Investigator_Data>;
  local_uuid?: Maybe<Scalars['String']>;
  /** An object relationship */
  next_deck?: Maybe<Campaign_Deck>;
  next_deck_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  other_decks: Array<Campaign_Deck>;
  /** An aggregate relationship */
  other_decks_aggregate: Campaign_Deck_Aggregate;
  /** An object relationship */
  owner: Users;
  owner_id: Scalars['String'];
  /** An object relationship */
  previous_deck?: Maybe<Campaign_Deck>;
  /** An array relationship */
  previous_decks: Array<Campaign_Deck>;
  /** An aggregate relationship */
  previous_decks_aggregate: Campaign_Deck_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "campaign_deck" */
export type Campaign_DeckContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "campaign_deck" */
export type Campaign_DeckOther_DecksArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};


/** columns and relationships of "campaign_deck" */
export type Campaign_DeckOther_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};


/** columns and relationships of "campaign_deck" */
export type Campaign_DeckPrevious_DecksArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};


/** columns and relationships of "campaign_deck" */
export type Campaign_DeckPrevious_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};

/** aggregated selection of "campaign_deck" */
export type Campaign_Deck_Aggregate = {
  __typename?: 'campaign_deck_aggregate';
  aggregate?: Maybe<Campaign_Deck_Aggregate_Fields>;
  nodes: Array<Campaign_Deck>;
};

/** aggregate fields of "campaign_deck" */
export type Campaign_Deck_Aggregate_Fields = {
  __typename?: 'campaign_deck_aggregate_fields';
  avg?: Maybe<Campaign_Deck_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Campaign_Deck_Max_Fields>;
  min?: Maybe<Campaign_Deck_Min_Fields>;
  stddev?: Maybe<Campaign_Deck_Stddev_Fields>;
  stddev_pop?: Maybe<Campaign_Deck_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Campaign_Deck_Stddev_Samp_Fields>;
  sum?: Maybe<Campaign_Deck_Sum_Fields>;
  var_pop?: Maybe<Campaign_Deck_Var_Pop_Fields>;
  var_samp?: Maybe<Campaign_Deck_Var_Samp_Fields>;
  variance?: Maybe<Campaign_Deck_Variance_Fields>;
};


/** aggregate fields of "campaign_deck" */
export type Campaign_Deck_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "campaign_deck" */
export type Campaign_Deck_Aggregate_Order_By = {
  avg?: InputMaybe<Campaign_Deck_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaign_Deck_Max_Order_By>;
  min?: InputMaybe<Campaign_Deck_Min_Order_By>;
  stddev?: InputMaybe<Campaign_Deck_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Campaign_Deck_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Campaign_Deck_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Campaign_Deck_Sum_Order_By>;
  var_pop?: InputMaybe<Campaign_Deck_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Campaign_Deck_Var_Samp_Order_By>;
  variance?: InputMaybe<Campaign_Deck_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Deck_Append_Input = {
  content?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "campaign_deck" */
export type Campaign_Deck_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Deck_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Campaign_Deck_On_Conflict>;
};

/** aggregate avg on columns */
export type Campaign_Deck_Avg_Fields = {
  __typename?: 'campaign_deck_avg_fields';
  arkhamdb_id?: Maybe<Scalars['Float']>;
  arkhamdb_user?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  next_deck_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "campaign_deck" */
export type Campaign_Deck_Avg_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "campaign_deck". All fields are combined with a logical 'AND'. */
export type Campaign_Deck_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Deck_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Deck_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Deck_Bool_Exp>>;
  arkhamdb_id?: InputMaybe<Int_Comparison_Exp>;
  arkhamdb_user?: InputMaybe<Int_Comparison_Exp>;
  base?: InputMaybe<Boolean_Comparison_Exp>;
  campaign?: InputMaybe<Campaign_Bool_Exp>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<Jsonb_Comparison_Exp>;
  content_hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investigator?: InputMaybe<String_Comparison_Exp>;
  investigator_data?: InputMaybe<Investigator_Data_Bool_Exp>;
  local_uuid?: InputMaybe<String_Comparison_Exp>;
  next_deck?: InputMaybe<Campaign_Deck_Bool_Exp>;
  next_deck_id?: InputMaybe<Int_Comparison_Exp>;
  other_decks?: InputMaybe<Campaign_Deck_Bool_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<String_Comparison_Exp>;
  previous_deck?: InputMaybe<Campaign_Deck_Bool_Exp>;
  previous_decks?: InputMaybe<Campaign_Deck_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_deck" */
export enum Campaign_Deck_Constraint {
  /** unique or primary key constraint */
  DeckArkhamdbIdCampaignIdKey = 'deck_arkhamdb_id_campaign_id_key',
  /** unique or primary key constraint */
  DeckLocalUuidCampaignIdKey = 'deck_local_uuid_campaign_id_key',
  /** unique or primary key constraint */
  DeckPkey = 'deck_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Campaign_Deck_Delete_At_Path_Input = {
  content?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Campaign_Deck_Delete_Elem_Input = {
  content?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Campaign_Deck_Delete_Key_Input = {
  content?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "campaign_deck" */
export type Campaign_Deck_Inc_Input = {
  arkhamdb_id?: InputMaybe<Scalars['Int']>;
  arkhamdb_user?: InputMaybe<Scalars['Int']>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  next_deck_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "campaign_deck" */
export type Campaign_Deck_Insert_Input = {
  arkhamdb_id?: InputMaybe<Scalars['Int']>;
  arkhamdb_user?: InputMaybe<Scalars['Int']>;
  base?: InputMaybe<Scalars['Boolean']>;
  campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['jsonb']>;
  content_hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  investigator?: InputMaybe<Scalars['String']>;
  investigator_data?: InputMaybe<Investigator_Data_Obj_Rel_Insert_Input>;
  local_uuid?: InputMaybe<Scalars['String']>;
  next_deck?: InputMaybe<Campaign_Deck_Obj_Rel_Insert_Input>;
  next_deck_id?: InputMaybe<Scalars['Int']>;
  other_decks?: InputMaybe<Campaign_Deck_Arr_Rel_Insert_Input>;
  owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['String']>;
  previous_deck?: InputMaybe<Campaign_Deck_Obj_Rel_Insert_Input>;
  previous_decks?: InputMaybe<Campaign_Deck_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Campaign_Deck_Max_Fields = {
  __typename?: 'campaign_deck_max_fields';
  arkhamdb_id?: Maybe<Scalars['Int']>;
  arkhamdb_user?: Maybe<Scalars['Int']>;
  campaign_id?: Maybe<Scalars['Int']>;
  content_hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  investigator?: Maybe<Scalars['String']>;
  local_uuid?: Maybe<Scalars['String']>;
  next_deck_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "campaign_deck" */
export type Campaign_Deck_Max_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  content_hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investigator?: InputMaybe<Order_By>;
  local_uuid?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Deck_Min_Fields = {
  __typename?: 'campaign_deck_min_fields';
  arkhamdb_id?: Maybe<Scalars['Int']>;
  arkhamdb_user?: Maybe<Scalars['Int']>;
  campaign_id?: Maybe<Scalars['Int']>;
  content_hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  investigator?: Maybe<Scalars['String']>;
  local_uuid?: Maybe<Scalars['String']>;
  next_deck_id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "campaign_deck" */
export type Campaign_Deck_Min_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  content_hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investigator?: InputMaybe<Order_By>;
  local_uuid?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaign_deck" */
export type Campaign_Deck_Mutation_Response = {
  __typename?: 'campaign_deck_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Deck>;
};

/** input type for inserting object relation for remote table "campaign_deck" */
export type Campaign_Deck_Obj_Rel_Insert_Input = {
  data: Campaign_Deck_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Campaign_Deck_On_Conflict>;
};

/** on conflict condition type for table "campaign_deck" */
export type Campaign_Deck_On_Conflict = {
  constraint: Campaign_Deck_Constraint;
  update_columns: Array<Campaign_Deck_Update_Column>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_deck". */
export type Campaign_Deck_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  base?: InputMaybe<Order_By>;
  campaign?: InputMaybe<Campaign_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  content_hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investigator?: InputMaybe<Order_By>;
  investigator_data?: InputMaybe<Investigator_Data_Order_By>;
  local_uuid?: InputMaybe<Order_By>;
  next_deck?: InputMaybe<Campaign_Deck_Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
  other_decks_aggregate?: InputMaybe<Campaign_Deck_Aggregate_Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  previous_deck?: InputMaybe<Campaign_Deck_Order_By>;
  previous_decks_aggregate?: InputMaybe<Campaign_Deck_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_deck */
export type Campaign_Deck_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Deck_Prepend_Input = {
  content?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "campaign_deck" */
export enum Campaign_Deck_Select_Column {
  /** column name */
  ArkhamdbId = 'arkhamdb_id',
  /** column name */
  ArkhamdbUser = 'arkhamdb_user',
  /** column name */
  Base = 'base',
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  Content = 'content',
  /** column name */
  ContentHash = 'content_hash',
  /** column name */
  Id = 'id',
  /** column name */
  Investigator = 'investigator',
  /** column name */
  LocalUuid = 'local_uuid',
  /** column name */
  NextDeckId = 'next_deck_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "campaign_deck" */
export type Campaign_Deck_Set_Input = {
  arkhamdb_id?: InputMaybe<Scalars['Int']>;
  arkhamdb_user?: InputMaybe<Scalars['Int']>;
  base?: InputMaybe<Scalars['Boolean']>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['jsonb']>;
  content_hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  investigator?: InputMaybe<Scalars['String']>;
  local_uuid?: InputMaybe<Scalars['String']>;
  next_deck_id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Campaign_Deck_Stddev_Fields = {
  __typename?: 'campaign_deck_stddev_fields';
  arkhamdb_id?: Maybe<Scalars['Float']>;
  arkhamdb_user?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  next_deck_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "campaign_deck" */
export type Campaign_Deck_Stddev_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Campaign_Deck_Stddev_Pop_Fields = {
  __typename?: 'campaign_deck_stddev_pop_fields';
  arkhamdb_id?: Maybe<Scalars['Float']>;
  arkhamdb_user?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  next_deck_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "campaign_deck" */
export type Campaign_Deck_Stddev_Pop_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Campaign_Deck_Stddev_Samp_Fields = {
  __typename?: 'campaign_deck_stddev_samp_fields';
  arkhamdb_id?: Maybe<Scalars['Float']>;
  arkhamdb_user?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  next_deck_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "campaign_deck" */
export type Campaign_Deck_Stddev_Samp_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Campaign_Deck_Sum_Fields = {
  __typename?: 'campaign_deck_sum_fields';
  arkhamdb_id?: Maybe<Scalars['Int']>;
  arkhamdb_user?: Maybe<Scalars['Int']>;
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  next_deck_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "campaign_deck" */
export type Campaign_Deck_Sum_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
};

/** update columns of table "campaign_deck" */
export enum Campaign_Deck_Update_Column {
  /** column name */
  ArkhamdbId = 'arkhamdb_id',
  /** column name */
  ArkhamdbUser = 'arkhamdb_user',
  /** column name */
  Base = 'base',
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  Content = 'content',
  /** column name */
  ContentHash = 'content_hash',
  /** column name */
  Id = 'id',
  /** column name */
  Investigator = 'investigator',
  /** column name */
  LocalUuid = 'local_uuid',
  /** column name */
  NextDeckId = 'next_deck_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Campaign_Deck_Var_Pop_Fields = {
  __typename?: 'campaign_deck_var_pop_fields';
  arkhamdb_id?: Maybe<Scalars['Float']>;
  arkhamdb_user?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  next_deck_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "campaign_deck" */
export type Campaign_Deck_Var_Pop_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Campaign_Deck_Var_Samp_Fields = {
  __typename?: 'campaign_deck_var_samp_fields';
  arkhamdb_id?: Maybe<Scalars['Float']>;
  arkhamdb_user?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  next_deck_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "campaign_deck" */
export type Campaign_Deck_Var_Samp_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Campaign_Deck_Variance_Fields = {
  __typename?: 'campaign_deck_variance_fields';
  arkhamdb_id?: Maybe<Scalars['Float']>;
  arkhamdb_user?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  next_deck_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "campaign_deck" */
export type Campaign_Deck_Variance_Order_By = {
  arkhamdb_id?: InputMaybe<Order_By>;
  arkhamdb_user?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_deck_id?: InputMaybe<Order_By>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Campaign_Delete_At_Path_Input = {
  campaignNotes?: InputMaybe<Array<Scalars['String']>>;
  chaosBag?: InputMaybe<Array<Scalars['String']>>;
  scenarioResults?: InputMaybe<Array<Scalars['String']>>;
  standaloneId?: InputMaybe<Array<Scalars['String']>>;
  weaknessSet?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Campaign_Delete_Elem_Input = {
  campaignNotes?: InputMaybe<Scalars['Int']>;
  chaosBag?: InputMaybe<Scalars['Int']>;
  scenarioResults?: InputMaybe<Scalars['Int']>;
  standaloneId?: InputMaybe<Scalars['Int']>;
  weaknessSet?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Campaign_Delete_Key_Input = {
  campaignNotes?: InputMaybe<Scalars['String']>;
  chaosBag?: InputMaybe<Scalars['String']>;
  scenarioResults?: InputMaybe<Scalars['String']>;
  standaloneId?: InputMaybe<Scalars['String']>;
  weaknessSet?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "campaign_guide" */
export type Campaign_Guide = {
  __typename?: 'campaign_guide';
  /** An array relationship */
  access: Array<Campaign_Access>;
  /** An aggregate relationship */
  access_aggregate: Campaign_Access_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  guide_achievements: Array<Guide_Achievement>;
  /** An aggregate relationship */
  guide_achievements_aggregate: Guide_Achievement_Aggregate;
  /** An array relationship */
  guide_inputs: Array<Guide_Input>;
  /** An aggregate relationship */
  guide_inputs_aggregate: Guide_Input_Aggregate;
  id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
};


/** columns and relationships of "campaign_guide" */
export type Campaign_GuideAccessArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Access_Order_By>>;
  where?: InputMaybe<Campaign_Access_Bool_Exp>;
};


/** columns and relationships of "campaign_guide" */
export type Campaign_GuideAccess_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Access_Order_By>>;
  where?: InputMaybe<Campaign_Access_Bool_Exp>;
};


/** columns and relationships of "campaign_guide" */
export type Campaign_GuideGuide_AchievementsArgs = {
  distinct_on?: InputMaybe<Array<Guide_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Achievement_Order_By>>;
  where?: InputMaybe<Guide_Achievement_Bool_Exp>;
};


/** columns and relationships of "campaign_guide" */
export type Campaign_GuideGuide_Achievements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Guide_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Achievement_Order_By>>;
  where?: InputMaybe<Guide_Achievement_Bool_Exp>;
};


/** columns and relationships of "campaign_guide" */
export type Campaign_GuideGuide_InputsArgs = {
  distinct_on?: InputMaybe<Array<Guide_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Input_Order_By>>;
  where?: InputMaybe<Guide_Input_Bool_Exp>;
};


/** columns and relationships of "campaign_guide" */
export type Campaign_GuideGuide_Inputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Guide_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Input_Order_By>>;
  where?: InputMaybe<Guide_Input_Bool_Exp>;
};

/** aggregated selection of "campaign_guide" */
export type Campaign_Guide_Aggregate = {
  __typename?: 'campaign_guide_aggregate';
  aggregate?: Maybe<Campaign_Guide_Aggregate_Fields>;
  nodes: Array<Campaign_Guide>;
};

/** aggregate fields of "campaign_guide" */
export type Campaign_Guide_Aggregate_Fields = {
  __typename?: 'campaign_guide_aggregate_fields';
  avg?: Maybe<Campaign_Guide_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Campaign_Guide_Max_Fields>;
  min?: Maybe<Campaign_Guide_Min_Fields>;
  stddev?: Maybe<Campaign_Guide_Stddev_Fields>;
  stddev_pop?: Maybe<Campaign_Guide_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Campaign_Guide_Stddev_Samp_Fields>;
  sum?: Maybe<Campaign_Guide_Sum_Fields>;
  var_pop?: Maybe<Campaign_Guide_Var_Pop_Fields>;
  var_samp?: Maybe<Campaign_Guide_Var_Samp_Fields>;
  variance?: Maybe<Campaign_Guide_Variance_Fields>;
};


/** aggregate fields of "campaign_guide" */
export type Campaign_Guide_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Guide_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Campaign_Guide_Avg_Fields = {
  __typename?: 'campaign_guide_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "campaign_guide". All fields are combined with a logical 'AND'. */
export type Campaign_Guide_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Guide_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Guide_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Guide_Bool_Exp>>;
  access?: InputMaybe<Campaign_Access_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  guide_achievements?: InputMaybe<Guide_Achievement_Bool_Exp>;
  guide_inputs?: InputMaybe<Guide_Input_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  owner?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uuid?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "campaign_guide" */
export type Campaign_Guide_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "campaign_guide" */
export type Campaign_Guide_Insert_Input = {
  access?: InputMaybe<Campaign_Access_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  guide_achievements?: InputMaybe<Guide_Achievement_Arr_Rel_Insert_Input>;
  guide_inputs?: InputMaybe<Guide_Input_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Campaign_Guide_Max_Fields = {
  __typename?: 'campaign_guide_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Campaign_Guide_Min_Fields = {
  __typename?: 'campaign_guide_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "campaign_guide" */
export type Campaign_Guide_Mutation_Response = {
  __typename?: 'campaign_guide_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Guide>;
};

/** input type for inserting object relation for remote table "campaign_guide" */
export type Campaign_Guide_Obj_Rel_Insert_Input = {
  data: Campaign_Guide_Insert_Input;
};

/** Ordering options when selecting data from "campaign_guide". */
export type Campaign_Guide_Order_By = {
  access_aggregate?: InputMaybe<Campaign_Access_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  guide_achievements_aggregate?: InputMaybe<Guide_Achievement_Aggregate_Order_By>;
  guide_inputs_aggregate?: InputMaybe<Guide_Input_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** select columns of table "campaign_guide" */
export enum Campaign_Guide_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Owner = 'owner',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "campaign_guide" */
export type Campaign_Guide_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  owner?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Campaign_Guide_Stddev_Fields = {
  __typename?: 'campaign_guide_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Campaign_Guide_Stddev_Pop_Fields = {
  __typename?: 'campaign_guide_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Campaign_Guide_Stddev_Samp_Fields = {
  __typename?: 'campaign_guide_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Campaign_Guide_Sum_Fields = {
  __typename?: 'campaign_guide_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Campaign_Guide_Var_Pop_Fields = {
  __typename?: 'campaign_guide_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Campaign_Guide_Var_Samp_Fields = {
  __typename?: 'campaign_guide_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Campaign_Guide_Variance_Fields = {
  __typename?: 'campaign_guide_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "campaign" */
export type Campaign_Inc_Input = {
  guide_version?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  link_a_campaign_id?: InputMaybe<Scalars['Int']>;
  link_b_campaign_id?: InputMaybe<Scalars['Int']>;
  link_campaign_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "campaign" */
export type Campaign_Insert_Input = {
  access?: InputMaybe<Campaign_Access_Arr_Rel_Insert_Input>;
  archived?: InputMaybe<Scalars['Boolean']>;
  base_decks?: InputMaybe<Base_Decks_Arr_Rel_Insert_Input>;
  campaignNotes?: InputMaybe<Scalars['jsonb']>;
  campaign_guide?: InputMaybe<Campaign_Guide_Obj_Rel_Insert_Input>;
  chaosBag?: InputMaybe<Scalars['jsonb']>;
  chaos_bag_result?: InputMaybe<Chaos_Bag_Result_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cycleCode?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  difficulty?: InputMaybe<Scalars['String']>;
  guide_version?: InputMaybe<Scalars['Int']>;
  guided?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  investigator_data?: InputMaybe<Investigator_Data_Arr_Rel_Insert_Input>;
  investigators?: InputMaybe<Campaign_Investigator_Arr_Rel_Insert_Input>;
  latest_decks?: InputMaybe<Latest_Decks_Arr_Rel_Insert_Input>;
  link_a_campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  link_a_campaign_id?: InputMaybe<Scalars['Int']>;
  link_b_campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  link_b_campaign_id?: InputMaybe<Scalars['Int']>;
  link_campaign_id?: InputMaybe<Scalars['Int']>;
  linked_campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['String']>;
  scenarioResults?: InputMaybe<Scalars['jsonb']>;
  showInterludes?: InputMaybe<Scalars['Boolean']>;
  standaloneId?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['String']>;
  weaknessSet?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "campaign_investigator" */
export type Campaign_Investigator = {
  __typename?: 'campaign_investigator';
  /** An object relationship */
  campaign: Campaign;
  campaign_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** A computed field, executes function "campaign_investigator_id" */
  id?: Maybe<Scalars['String']>;
  investigator: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "campaign_investigator" */
export type Campaign_Investigator_Aggregate = {
  __typename?: 'campaign_investigator_aggregate';
  aggregate?: Maybe<Campaign_Investigator_Aggregate_Fields>;
  nodes: Array<Campaign_Investigator>;
};

/** aggregate fields of "campaign_investigator" */
export type Campaign_Investigator_Aggregate_Fields = {
  __typename?: 'campaign_investigator_aggregate_fields';
  avg?: Maybe<Campaign_Investigator_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Campaign_Investigator_Max_Fields>;
  min?: Maybe<Campaign_Investigator_Min_Fields>;
  stddev?: Maybe<Campaign_Investigator_Stddev_Fields>;
  stddev_pop?: Maybe<Campaign_Investigator_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Campaign_Investigator_Stddev_Samp_Fields>;
  sum?: Maybe<Campaign_Investigator_Sum_Fields>;
  var_pop?: Maybe<Campaign_Investigator_Var_Pop_Fields>;
  var_samp?: Maybe<Campaign_Investigator_Var_Samp_Fields>;
  variance?: Maybe<Campaign_Investigator_Variance_Fields>;
};


/** aggregate fields of "campaign_investigator" */
export type Campaign_Investigator_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Campaign_Investigator_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "campaign_investigator" */
export type Campaign_Investigator_Aggregate_Order_By = {
  avg?: InputMaybe<Campaign_Investigator_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Campaign_Investigator_Max_Order_By>;
  min?: InputMaybe<Campaign_Investigator_Min_Order_By>;
  stddev?: InputMaybe<Campaign_Investigator_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Campaign_Investigator_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Campaign_Investigator_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Campaign_Investigator_Sum_Order_By>;
  var_pop?: InputMaybe<Campaign_Investigator_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Campaign_Investigator_Var_Samp_Order_By>;
  variance?: InputMaybe<Campaign_Investigator_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "campaign_investigator" */
export type Campaign_Investigator_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Investigator_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Campaign_Investigator_On_Conflict>;
};

/** aggregate avg on columns */
export type Campaign_Investigator_Avg_Fields = {
  __typename?: 'campaign_investigator_avg_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Avg_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "campaign_investigator". All fields are combined with a logical 'AND'. */
export type Campaign_Investigator_Bool_Exp = {
  _and?: InputMaybe<Array<Campaign_Investigator_Bool_Exp>>;
  _not?: InputMaybe<Campaign_Investigator_Bool_Exp>;
  _or?: InputMaybe<Array<Campaign_Investigator_Bool_Exp>>;
  campaign?: InputMaybe<Campaign_Bool_Exp>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  investigator?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_investigator" */
export enum Campaign_Investigator_Constraint {
  /** unique or primary key constraint */
  CampaignInvestigatorCampaignIdInvestigatorKey = 'campaign_investigator_campaign_id_investigator_key',
  /** unique or primary key constraint */
  CampaignInvestigatorPkey = 'campaign_investigator_pkey'
}

/** input type for incrementing numeric columns in table "campaign_investigator" */
export type Campaign_Investigator_Inc_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "campaign_investigator" */
export type Campaign_Investigator_Insert_Input = {
  campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  investigator?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Campaign_Investigator_Max_Fields = {
  __typename?: 'campaign_investigator_max_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  investigator?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  investigator?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Investigator_Min_Fields = {
  __typename?: 'campaign_investigator_min_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  investigator?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  investigator?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "campaign_investigator" */
export type Campaign_Investigator_Mutation_Response = {
  __typename?: 'campaign_investigator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign_Investigator>;
};

/** on conflict condition type for table "campaign_investigator" */
export type Campaign_Investigator_On_Conflict = {
  constraint: Campaign_Investigator_Constraint;
  update_columns: Array<Campaign_Investigator_Update_Column>;
  where?: InputMaybe<Campaign_Investigator_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign_investigator". */
export type Campaign_Investigator_Order_By = {
  campaign?: InputMaybe<Campaign_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  investigator?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign_investigator */
export type Campaign_Investigator_Pk_Columns_Input = {
  campaign_id: Scalars['Int'];
  investigator: Scalars['String'];
};

/** select columns of table "campaign_investigator" */
export enum Campaign_Investigator_Select_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Investigator = 'investigator',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "campaign_investigator" */
export type Campaign_Investigator_Set_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  investigator?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Campaign_Investigator_Stddev_Fields = {
  __typename?: 'campaign_investigator_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Stddev_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Campaign_Investigator_Stddev_Pop_Fields = {
  __typename?: 'campaign_investigator_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Stddev_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Campaign_Investigator_Stddev_Samp_Fields = {
  __typename?: 'campaign_investigator_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Stddev_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Campaign_Investigator_Sum_Fields = {
  __typename?: 'campaign_investigator_sum_fields';
  campaign_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Sum_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** update columns of table "campaign_investigator" */
export enum Campaign_Investigator_Update_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Investigator = 'investigator',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Campaign_Investigator_Var_Pop_Fields = {
  __typename?: 'campaign_investigator_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Var_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Campaign_Investigator_Var_Samp_Fields = {
  __typename?: 'campaign_investigator_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Var_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Campaign_Investigator_Variance_Fields = {
  __typename?: 'campaign_investigator_variance_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "campaign_investigator" */
export type Campaign_Investigator_Variance_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Campaign_Max_Fields = {
  __typename?: 'campaign_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cycleCode?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['String']>;
  guide_version?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  link_a_campaign_id?: Maybe<Scalars['Int']>;
  link_b_campaign_id?: Maybe<Scalars['Int']>;
  link_campaign_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Campaign_Min_Fields = {
  __typename?: 'campaign_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cycleCode?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['String']>;
  guide_version?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  link_a_campaign_id?: Maybe<Scalars['Int']>;
  link_b_campaign_id?: Maybe<Scalars['Int']>;
  link_campaign_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "campaign" */
export type Campaign_Mutation_Response = {
  __typename?: 'campaign_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Campaign>;
};

/** input type for inserting object relation for remote table "campaign" */
export type Campaign_Obj_Rel_Insert_Input = {
  data: Campaign_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Campaign_On_Conflict>;
};

/** on conflict condition type for table "campaign" */
export type Campaign_On_Conflict = {
  constraint: Campaign_Constraint;
  update_columns: Array<Campaign_Update_Column>;
  where?: InputMaybe<Campaign_Bool_Exp>;
};

/** Ordering options when selecting data from "campaign". */
export type Campaign_Order_By = {
  access_aggregate?: InputMaybe<Campaign_Access_Aggregate_Order_By>;
  archived?: InputMaybe<Order_By>;
  base_decks_aggregate?: InputMaybe<Base_Decks_Aggregate_Order_By>;
  campaignNotes?: InputMaybe<Order_By>;
  campaign_guide?: InputMaybe<Campaign_Guide_Order_By>;
  chaosBag?: InputMaybe<Order_By>;
  chaos_bag_result_aggregate?: InputMaybe<Chaos_Bag_Result_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  cycleCode?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  difficulty?: InputMaybe<Order_By>;
  guide_version?: InputMaybe<Order_By>;
  guided?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investigator_data_aggregate?: InputMaybe<Investigator_Data_Aggregate_Order_By>;
  investigators_aggregate?: InputMaybe<Campaign_Investigator_Aggregate_Order_By>;
  latest_decks_aggregate?: InputMaybe<Latest_Decks_Aggregate_Order_By>;
  link_a_campaign?: InputMaybe<Campaign_Order_By>;
  link_a_campaign_id?: InputMaybe<Order_By>;
  link_b_campaign?: InputMaybe<Campaign_Order_By>;
  link_b_campaign_id?: InputMaybe<Order_By>;
  link_campaign_id?: InputMaybe<Order_By>;
  linked_campaign?: InputMaybe<Campaign_Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  scenarioResults?: InputMaybe<Order_By>;
  showInterludes?: InputMaybe<Order_By>;
  standaloneId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
  weaknessSet?: InputMaybe<Order_By>;
};

/** primary key columns input for table: campaign */
export type Campaign_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Prepend_Input = {
  campaignNotes?: InputMaybe<Scalars['jsonb']>;
  chaosBag?: InputMaybe<Scalars['jsonb']>;
  scenarioResults?: InputMaybe<Scalars['jsonb']>;
  standaloneId?: InputMaybe<Scalars['jsonb']>;
  weaknessSet?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "campaign" */
export enum Campaign_Select_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CampaignNotes = 'campaignNotes',
  /** column name */
  ChaosBag = 'chaosBag',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CycleCode = 'cycleCode',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Difficulty = 'difficulty',
  /** column name */
  GuideVersion = 'guide_version',
  /** column name */
  Guided = 'guided',
  /** column name */
  Id = 'id',
  /** column name */
  LinkACampaignId = 'link_a_campaign_id',
  /** column name */
  LinkBCampaignId = 'link_b_campaign_id',
  /** column name */
  LinkCampaignId = 'link_campaign_id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ScenarioResults = 'scenarioResults',
  /** column name */
  ShowInterludes = 'showInterludes',
  /** column name */
  StandaloneId = 'standaloneId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  WeaknessSet = 'weaknessSet'
}

/** input type for updating data in table "campaign" */
export type Campaign_Set_Input = {
  archived?: InputMaybe<Scalars['Boolean']>;
  campaignNotes?: InputMaybe<Scalars['jsonb']>;
  chaosBag?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cycleCode?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  difficulty?: InputMaybe<Scalars['String']>;
  guide_version?: InputMaybe<Scalars['Int']>;
  guided?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  link_a_campaign_id?: InputMaybe<Scalars['Int']>;
  link_b_campaign_id?: InputMaybe<Scalars['Int']>;
  link_campaign_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['String']>;
  scenarioResults?: InputMaybe<Scalars['jsonb']>;
  showInterludes?: InputMaybe<Scalars['Boolean']>;
  standaloneId?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['String']>;
  weaknessSet?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Campaign_Stddev_Fields = {
  __typename?: 'campaign_stddev_fields';
  guide_version?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  link_a_campaign_id?: Maybe<Scalars['Float']>;
  link_b_campaign_id?: Maybe<Scalars['Float']>;
  link_campaign_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Campaign_Stddev_Pop_Fields = {
  __typename?: 'campaign_stddev_pop_fields';
  guide_version?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  link_a_campaign_id?: Maybe<Scalars['Float']>;
  link_b_campaign_id?: Maybe<Scalars['Float']>;
  link_campaign_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Campaign_Stddev_Samp_Fields = {
  __typename?: 'campaign_stddev_samp_fields';
  guide_version?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  link_a_campaign_id?: Maybe<Scalars['Float']>;
  link_b_campaign_id?: Maybe<Scalars['Float']>;
  link_campaign_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Campaign_Sum_Fields = {
  __typename?: 'campaign_sum_fields';
  guide_version?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  link_a_campaign_id?: Maybe<Scalars['Int']>;
  link_b_campaign_id?: Maybe<Scalars['Int']>;
  link_campaign_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "campaign" */
export enum Campaign_Update_Column {
  /** column name */
  Archived = 'archived',
  /** column name */
  CampaignNotes = 'campaignNotes',
  /** column name */
  ChaosBag = 'chaosBag',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CycleCode = 'cycleCode',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Difficulty = 'difficulty',
  /** column name */
  GuideVersion = 'guide_version',
  /** column name */
  Guided = 'guided',
  /** column name */
  Id = 'id',
  /** column name */
  LinkACampaignId = 'link_a_campaign_id',
  /** column name */
  LinkBCampaignId = 'link_b_campaign_id',
  /** column name */
  LinkCampaignId = 'link_campaign_id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ScenarioResults = 'scenarioResults',
  /** column name */
  ShowInterludes = 'showInterludes',
  /** column name */
  StandaloneId = 'standaloneId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  WeaknessSet = 'weaknessSet'
}

/** aggregate var_pop on columns */
export type Campaign_Var_Pop_Fields = {
  __typename?: 'campaign_var_pop_fields';
  guide_version?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  link_a_campaign_id?: Maybe<Scalars['Float']>;
  link_b_campaign_id?: Maybe<Scalars['Float']>;
  link_campaign_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Campaign_Var_Samp_Fields = {
  __typename?: 'campaign_var_samp_fields';
  guide_version?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  link_a_campaign_id?: Maybe<Scalars['Float']>;
  link_b_campaign_id?: Maybe<Scalars['Float']>;
  link_campaign_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Campaign_Variance_Fields = {
  __typename?: 'campaign_variance_fields';
  guide_version?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  link_a_campaign_id?: Maybe<Scalars['Float']>;
  link_b_campaign_id?: Maybe<Scalars['Float']>;
  link_campaign_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "card" */
export type Card = {
  __typename?: 'card';
  code: Scalars['String'];
  cost?: Maybe<Scalars['Int']>;
  deck_limit?: Maybe<Scalars['Int']>;
  encounter_code?: Maybe<Scalars['String']>;
  encounter_position?: Maybe<Scalars['Int']>;
  exceptional?: Maybe<Scalars['Boolean']>;
  exile?: Maybe<Scalars['Boolean']>;
  faction_code: Scalars['String'];
  health?: Maybe<Scalars['Int']>;
  is_unique?: Maybe<Scalars['Boolean']>;
  myriad?: Maybe<Scalars['Boolean']>;
  pack_code: Scalars['String'];
  pack_position: Scalars['Int'];
  /** An array relationship */
  packs: Array<Card_Pack>;
  /** An aggregate relationship */
  packs_aggregate: Card_Pack_Aggregate;
  permanent?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  quantity: Scalars['Int'];
  real_flavor?: Maybe<Scalars['String']>;
  real_name: Scalars['String'];
  real_pack_name: Scalars['String'];
  real_slot?: Maybe<Scalars['String']>;
  real_subname?: Maybe<Scalars['String']>;
  real_text: Scalars['String'];
  real_traits?: Maybe<Scalars['String']>;
  sanity?: Maybe<Scalars['Int']>;
  skill_agility?: Maybe<Scalars['Int']>;
  skill_combat?: Maybe<Scalars['Int']>;
  skill_intellect?: Maybe<Scalars['Int']>;
  skill_wild?: Maybe<Scalars['Int']>;
  skill_willpower?: Maybe<Scalars['Int']>;
  subtype_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  translations: Array<Card_Text>;
  /** An aggregate relationship */
  translations_aggregate: Card_Text_Aggregate;
  type_code: Scalars['String'];
};


/** columns and relationships of "card" */
export type CardPacksArgs = {
  distinct_on?: InputMaybe<Array<Card_Pack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Pack_Order_By>>;
  where?: InputMaybe<Card_Pack_Bool_Exp>;
};


/** columns and relationships of "card" */
export type CardPacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Card_Pack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Pack_Order_By>>;
  where?: InputMaybe<Card_Pack_Bool_Exp>;
};


/** columns and relationships of "card" */
export type CardTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Card_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Text_Order_By>>;
  where?: InputMaybe<Card_Text_Bool_Exp>;
};


/** columns and relationships of "card" */
export type CardTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Card_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Text_Order_By>>;
  where?: InputMaybe<Card_Text_Bool_Exp>;
};

/** aggregated selection of "card" */
export type Card_Aggregate = {
  __typename?: 'card_aggregate';
  aggregate?: Maybe<Card_Aggregate_Fields>;
  nodes: Array<Card>;
};

/** aggregate fields of "card" */
export type Card_Aggregate_Fields = {
  __typename?: 'card_aggregate_fields';
  avg?: Maybe<Card_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Card_Max_Fields>;
  min?: Maybe<Card_Min_Fields>;
  stddev?: Maybe<Card_Stddev_Fields>;
  stddev_pop?: Maybe<Card_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Card_Stddev_Samp_Fields>;
  sum?: Maybe<Card_Sum_Fields>;
  var_pop?: Maybe<Card_Var_Pop_Fields>;
  var_samp?: Maybe<Card_Var_Samp_Fields>;
  variance?: Maybe<Card_Variance_Fields>;
};


/** aggregate fields of "card" */
export type Card_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Card_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Card_Avg_Fields = {
  __typename?: 'card_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  deck_limit?: Maybe<Scalars['Float']>;
  encounter_position?: Maybe<Scalars['Float']>;
  health?: Maybe<Scalars['Float']>;
  pack_position?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sanity?: Maybe<Scalars['Float']>;
  skill_agility?: Maybe<Scalars['Float']>;
  skill_combat?: Maybe<Scalars['Float']>;
  skill_intellect?: Maybe<Scalars['Float']>;
  skill_wild?: Maybe<Scalars['Float']>;
  skill_willpower?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "card". All fields are combined with a logical 'AND'. */
export type Card_Bool_Exp = {
  _and?: InputMaybe<Array<Card_Bool_Exp>>;
  _not?: InputMaybe<Card_Bool_Exp>;
  _or?: InputMaybe<Array<Card_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  deck_limit?: InputMaybe<Int_Comparison_Exp>;
  encounter_code?: InputMaybe<String_Comparison_Exp>;
  encounter_position?: InputMaybe<Int_Comparison_Exp>;
  exceptional?: InputMaybe<Boolean_Comparison_Exp>;
  exile?: InputMaybe<Boolean_Comparison_Exp>;
  faction_code?: InputMaybe<String_Comparison_Exp>;
  health?: InputMaybe<Int_Comparison_Exp>;
  is_unique?: InputMaybe<Boolean_Comparison_Exp>;
  myriad?: InputMaybe<Boolean_Comparison_Exp>;
  pack_code?: InputMaybe<String_Comparison_Exp>;
  pack_position?: InputMaybe<Int_Comparison_Exp>;
  packs?: InputMaybe<Card_Pack_Bool_Exp>;
  permanent?: InputMaybe<Boolean_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  real_flavor?: InputMaybe<String_Comparison_Exp>;
  real_name?: InputMaybe<String_Comparison_Exp>;
  real_pack_name?: InputMaybe<String_Comparison_Exp>;
  real_slot?: InputMaybe<String_Comparison_Exp>;
  real_subname?: InputMaybe<String_Comparison_Exp>;
  real_text?: InputMaybe<String_Comparison_Exp>;
  real_traits?: InputMaybe<String_Comparison_Exp>;
  sanity?: InputMaybe<Int_Comparison_Exp>;
  skill_agility?: InputMaybe<Int_Comparison_Exp>;
  skill_combat?: InputMaybe<Int_Comparison_Exp>;
  skill_intellect?: InputMaybe<Int_Comparison_Exp>;
  skill_wild?: InputMaybe<Int_Comparison_Exp>;
  skill_willpower?: InputMaybe<Int_Comparison_Exp>;
  subtype_code?: InputMaybe<String_Comparison_Exp>;
  translations?: InputMaybe<Card_Text_Bool_Exp>;
  type_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "card" */
export enum Card_Constraint {
  /** unique or primary key constraint */
  CardPkey = 'card_pkey'
}

/** input type for incrementing numeric columns in table "card" */
export type Card_Inc_Input = {
  cost?: InputMaybe<Scalars['Int']>;
  deck_limit?: InputMaybe<Scalars['Int']>;
  encounter_position?: InputMaybe<Scalars['Int']>;
  health?: InputMaybe<Scalars['Int']>;
  pack_position?: InputMaybe<Scalars['Int']>;
  position?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  sanity?: InputMaybe<Scalars['Int']>;
  skill_agility?: InputMaybe<Scalars['Int']>;
  skill_combat?: InputMaybe<Scalars['Int']>;
  skill_intellect?: InputMaybe<Scalars['Int']>;
  skill_wild?: InputMaybe<Scalars['Int']>;
  skill_willpower?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "card" */
export type Card_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  deck_limit?: InputMaybe<Scalars['Int']>;
  encounter_code?: InputMaybe<Scalars['String']>;
  encounter_position?: InputMaybe<Scalars['Int']>;
  exceptional?: InputMaybe<Scalars['Boolean']>;
  exile?: InputMaybe<Scalars['Boolean']>;
  faction_code?: InputMaybe<Scalars['String']>;
  health?: InputMaybe<Scalars['Int']>;
  is_unique?: InputMaybe<Scalars['Boolean']>;
  myriad?: InputMaybe<Scalars['Boolean']>;
  pack_code?: InputMaybe<Scalars['String']>;
  pack_position?: InputMaybe<Scalars['Int']>;
  packs?: InputMaybe<Card_Pack_Arr_Rel_Insert_Input>;
  permanent?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  real_flavor?: InputMaybe<Scalars['String']>;
  real_name?: InputMaybe<Scalars['String']>;
  real_pack_name?: InputMaybe<Scalars['String']>;
  real_slot?: InputMaybe<Scalars['String']>;
  real_subname?: InputMaybe<Scalars['String']>;
  real_text?: InputMaybe<Scalars['String']>;
  real_traits?: InputMaybe<Scalars['String']>;
  sanity?: InputMaybe<Scalars['Int']>;
  skill_agility?: InputMaybe<Scalars['Int']>;
  skill_combat?: InputMaybe<Scalars['Int']>;
  skill_intellect?: InputMaybe<Scalars['Int']>;
  skill_wild?: InputMaybe<Scalars['Int']>;
  skill_willpower?: InputMaybe<Scalars['Int']>;
  subtype_code?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Card_Text_Arr_Rel_Insert_Input>;
  type_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Card_Max_Fields = {
  __typename?: 'card_max_fields';
  code?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  deck_limit?: Maybe<Scalars['Int']>;
  encounter_code?: Maybe<Scalars['String']>;
  encounter_position?: Maybe<Scalars['Int']>;
  faction_code?: Maybe<Scalars['String']>;
  health?: Maybe<Scalars['Int']>;
  pack_code?: Maybe<Scalars['String']>;
  pack_position?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  real_flavor?: Maybe<Scalars['String']>;
  real_name?: Maybe<Scalars['String']>;
  real_pack_name?: Maybe<Scalars['String']>;
  real_slot?: Maybe<Scalars['String']>;
  real_subname?: Maybe<Scalars['String']>;
  real_text?: Maybe<Scalars['String']>;
  real_traits?: Maybe<Scalars['String']>;
  sanity?: Maybe<Scalars['Int']>;
  skill_agility?: Maybe<Scalars['Int']>;
  skill_combat?: Maybe<Scalars['Int']>;
  skill_intellect?: Maybe<Scalars['Int']>;
  skill_wild?: Maybe<Scalars['Int']>;
  skill_willpower?: Maybe<Scalars['Int']>;
  subtype_code?: Maybe<Scalars['String']>;
  type_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Card_Min_Fields = {
  __typename?: 'card_min_fields';
  code?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  deck_limit?: Maybe<Scalars['Int']>;
  encounter_code?: Maybe<Scalars['String']>;
  encounter_position?: Maybe<Scalars['Int']>;
  faction_code?: Maybe<Scalars['String']>;
  health?: Maybe<Scalars['Int']>;
  pack_code?: Maybe<Scalars['String']>;
  pack_position?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  real_flavor?: Maybe<Scalars['String']>;
  real_name?: Maybe<Scalars['String']>;
  real_pack_name?: Maybe<Scalars['String']>;
  real_slot?: Maybe<Scalars['String']>;
  real_subname?: Maybe<Scalars['String']>;
  real_text?: Maybe<Scalars['String']>;
  real_traits?: Maybe<Scalars['String']>;
  sanity?: Maybe<Scalars['Int']>;
  skill_agility?: Maybe<Scalars['Int']>;
  skill_combat?: Maybe<Scalars['Int']>;
  skill_intellect?: Maybe<Scalars['Int']>;
  skill_wild?: Maybe<Scalars['Int']>;
  skill_willpower?: Maybe<Scalars['Int']>;
  subtype_code?: Maybe<Scalars['String']>;
  type_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "card" */
export type Card_Mutation_Response = {
  __typename?: 'card_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Card>;
};

/** on conflict condition type for table "card" */
export type Card_On_Conflict = {
  constraint: Card_Constraint;
  update_columns: Array<Card_Update_Column>;
  where?: InputMaybe<Card_Bool_Exp>;
};

/** Ordering options when selecting data from "card". */
export type Card_Order_By = {
  code?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  deck_limit?: InputMaybe<Order_By>;
  encounter_code?: InputMaybe<Order_By>;
  encounter_position?: InputMaybe<Order_By>;
  exceptional?: InputMaybe<Order_By>;
  exile?: InputMaybe<Order_By>;
  faction_code?: InputMaybe<Order_By>;
  health?: InputMaybe<Order_By>;
  is_unique?: InputMaybe<Order_By>;
  myriad?: InputMaybe<Order_By>;
  pack_code?: InputMaybe<Order_By>;
  pack_position?: InputMaybe<Order_By>;
  packs_aggregate?: InputMaybe<Card_Pack_Aggregate_Order_By>;
  permanent?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  real_flavor?: InputMaybe<Order_By>;
  real_name?: InputMaybe<Order_By>;
  real_pack_name?: InputMaybe<Order_By>;
  real_slot?: InputMaybe<Order_By>;
  real_subname?: InputMaybe<Order_By>;
  real_text?: InputMaybe<Order_By>;
  real_traits?: InputMaybe<Order_By>;
  sanity?: InputMaybe<Order_By>;
  skill_agility?: InputMaybe<Order_By>;
  skill_combat?: InputMaybe<Order_By>;
  skill_intellect?: InputMaybe<Order_By>;
  skill_wild?: InputMaybe<Order_By>;
  skill_willpower?: InputMaybe<Order_By>;
  subtype_code?: InputMaybe<Order_By>;
  translations_aggregate?: InputMaybe<Card_Text_Aggregate_Order_By>;
  type_code?: InputMaybe<Order_By>;
};

/** columns and relationships of "card_pack" */
export type Card_Pack = {
  __typename?: 'card_pack';
  code: Scalars['String'];
  locale: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "card_pack" */
export type Card_Pack_Aggregate = {
  __typename?: 'card_pack_aggregate';
  aggregate?: Maybe<Card_Pack_Aggregate_Fields>;
  nodes: Array<Card_Pack>;
};

/** aggregate fields of "card_pack" */
export type Card_Pack_Aggregate_Fields = {
  __typename?: 'card_pack_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Card_Pack_Max_Fields>;
  min?: Maybe<Card_Pack_Min_Fields>;
};


/** aggregate fields of "card_pack" */
export type Card_Pack_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Card_Pack_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "card_pack" */
export type Card_Pack_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Card_Pack_Max_Order_By>;
  min?: InputMaybe<Card_Pack_Min_Order_By>;
};

/** input type for inserting array relation for remote table "card_pack" */
export type Card_Pack_Arr_Rel_Insert_Input = {
  data: Array<Card_Pack_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Card_Pack_On_Conflict>;
};

/** Boolean expression to filter rows from the table "card_pack". All fields are combined with a logical 'AND'. */
export type Card_Pack_Bool_Exp = {
  _and?: InputMaybe<Array<Card_Pack_Bool_Exp>>;
  _not?: InputMaybe<Card_Pack_Bool_Exp>;
  _or?: InputMaybe<Array<Card_Pack_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "card_pack" */
export enum Card_Pack_Constraint {
  /** unique or primary key constraint */
  CardPackPkey = 'card_pack_pkey'
}

/** input type for inserting data into table "card_pack" */
export type Card_Pack_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Card_Pack_Max_Fields = {
  __typename?: 'card_pack_max_fields';
  code?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "card_pack" */
export type Card_Pack_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Card_Pack_Min_Fields = {
  __typename?: 'card_pack_min_fields';
  code?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "card_pack" */
export type Card_Pack_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "card_pack" */
export type Card_Pack_Mutation_Response = {
  __typename?: 'card_pack_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Card_Pack>;
};

/** on conflict condition type for table "card_pack" */
export type Card_Pack_On_Conflict = {
  constraint: Card_Pack_Constraint;
  update_columns: Array<Card_Pack_Update_Column>;
  where?: InputMaybe<Card_Pack_Bool_Exp>;
};

/** Ordering options when selecting data from "card_pack". */
export type Card_Pack_Order_By = {
  code?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: card_pack */
export type Card_Pack_Pk_Columns_Input = {
  code: Scalars['String'];
  locale: Scalars['String'];
};

/** select columns of table "card_pack" */
export enum Card_Pack_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "card_pack" */
export type Card_Pack_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "card_pack" */
export enum Card_Pack_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name'
}

/** primary key columns input for table: card */
export type Card_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "card" */
export enum Card_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Cost = 'cost',
  /** column name */
  DeckLimit = 'deck_limit',
  /** column name */
  EncounterCode = 'encounter_code',
  /** column name */
  EncounterPosition = 'encounter_position',
  /** column name */
  Exceptional = 'exceptional',
  /** column name */
  Exile = 'exile',
  /** column name */
  FactionCode = 'faction_code',
  /** column name */
  Health = 'health',
  /** column name */
  IsUnique = 'is_unique',
  /** column name */
  Myriad = 'myriad',
  /** column name */
  PackCode = 'pack_code',
  /** column name */
  PackPosition = 'pack_position',
  /** column name */
  Permanent = 'permanent',
  /** column name */
  Position = 'position',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RealFlavor = 'real_flavor',
  /** column name */
  RealName = 'real_name',
  /** column name */
  RealPackName = 'real_pack_name',
  /** column name */
  RealSlot = 'real_slot',
  /** column name */
  RealSubname = 'real_subname',
  /** column name */
  RealText = 'real_text',
  /** column name */
  RealTraits = 'real_traits',
  /** column name */
  Sanity = 'sanity',
  /** column name */
  SkillAgility = 'skill_agility',
  /** column name */
  SkillCombat = 'skill_combat',
  /** column name */
  SkillIntellect = 'skill_intellect',
  /** column name */
  SkillWild = 'skill_wild',
  /** column name */
  SkillWillpower = 'skill_willpower',
  /** column name */
  SubtypeCode = 'subtype_code',
  /** column name */
  TypeCode = 'type_code'
}

/** input type for updating data in table "card" */
export type Card_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  deck_limit?: InputMaybe<Scalars['Int']>;
  encounter_code?: InputMaybe<Scalars['String']>;
  encounter_position?: InputMaybe<Scalars['Int']>;
  exceptional?: InputMaybe<Scalars['Boolean']>;
  exile?: InputMaybe<Scalars['Boolean']>;
  faction_code?: InputMaybe<Scalars['String']>;
  health?: InputMaybe<Scalars['Int']>;
  is_unique?: InputMaybe<Scalars['Boolean']>;
  myriad?: InputMaybe<Scalars['Boolean']>;
  pack_code?: InputMaybe<Scalars['String']>;
  pack_position?: InputMaybe<Scalars['Int']>;
  permanent?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  real_flavor?: InputMaybe<Scalars['String']>;
  real_name?: InputMaybe<Scalars['String']>;
  real_pack_name?: InputMaybe<Scalars['String']>;
  real_slot?: InputMaybe<Scalars['String']>;
  real_subname?: InputMaybe<Scalars['String']>;
  real_text?: InputMaybe<Scalars['String']>;
  real_traits?: InputMaybe<Scalars['String']>;
  sanity?: InputMaybe<Scalars['Int']>;
  skill_agility?: InputMaybe<Scalars['Int']>;
  skill_combat?: InputMaybe<Scalars['Int']>;
  skill_intellect?: InputMaybe<Scalars['Int']>;
  skill_wild?: InputMaybe<Scalars['Int']>;
  skill_willpower?: InputMaybe<Scalars['Int']>;
  subtype_code?: InputMaybe<Scalars['String']>;
  type_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Card_Stddev_Fields = {
  __typename?: 'card_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  deck_limit?: Maybe<Scalars['Float']>;
  encounter_position?: Maybe<Scalars['Float']>;
  health?: Maybe<Scalars['Float']>;
  pack_position?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sanity?: Maybe<Scalars['Float']>;
  skill_agility?: Maybe<Scalars['Float']>;
  skill_combat?: Maybe<Scalars['Float']>;
  skill_intellect?: Maybe<Scalars['Float']>;
  skill_wild?: Maybe<Scalars['Float']>;
  skill_willpower?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Card_Stddev_Pop_Fields = {
  __typename?: 'card_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  deck_limit?: Maybe<Scalars['Float']>;
  encounter_position?: Maybe<Scalars['Float']>;
  health?: Maybe<Scalars['Float']>;
  pack_position?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sanity?: Maybe<Scalars['Float']>;
  skill_agility?: Maybe<Scalars['Float']>;
  skill_combat?: Maybe<Scalars['Float']>;
  skill_intellect?: Maybe<Scalars['Float']>;
  skill_wild?: Maybe<Scalars['Float']>;
  skill_willpower?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Card_Stddev_Samp_Fields = {
  __typename?: 'card_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  deck_limit?: Maybe<Scalars['Float']>;
  encounter_position?: Maybe<Scalars['Float']>;
  health?: Maybe<Scalars['Float']>;
  pack_position?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sanity?: Maybe<Scalars['Float']>;
  skill_agility?: Maybe<Scalars['Float']>;
  skill_combat?: Maybe<Scalars['Float']>;
  skill_intellect?: Maybe<Scalars['Float']>;
  skill_wild?: Maybe<Scalars['Float']>;
  skill_willpower?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Card_Sum_Fields = {
  __typename?: 'card_sum_fields';
  cost?: Maybe<Scalars['Int']>;
  deck_limit?: Maybe<Scalars['Int']>;
  encounter_position?: Maybe<Scalars['Int']>;
  health?: Maybe<Scalars['Int']>;
  pack_position?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  sanity?: Maybe<Scalars['Int']>;
  skill_agility?: Maybe<Scalars['Int']>;
  skill_combat?: Maybe<Scalars['Int']>;
  skill_intellect?: Maybe<Scalars['Int']>;
  skill_wild?: Maybe<Scalars['Int']>;
  skill_willpower?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "card_text" */
export type Card_Text = {
  __typename?: 'card_text';
  code: Scalars['String'];
  encounter_name?: Maybe<Scalars['String']>;
  flavor?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  name: Scalars['String'];
  slot?: Maybe<Scalars['String']>;
  subname?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  traits?: Maybe<Scalars['String']>;
};

/** aggregated selection of "card_text" */
export type Card_Text_Aggregate = {
  __typename?: 'card_text_aggregate';
  aggregate?: Maybe<Card_Text_Aggregate_Fields>;
  nodes: Array<Card_Text>;
};

/** aggregate fields of "card_text" */
export type Card_Text_Aggregate_Fields = {
  __typename?: 'card_text_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Card_Text_Max_Fields>;
  min?: Maybe<Card_Text_Min_Fields>;
};


/** aggregate fields of "card_text" */
export type Card_Text_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Card_Text_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "card_text" */
export type Card_Text_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Card_Text_Max_Order_By>;
  min?: InputMaybe<Card_Text_Min_Order_By>;
};

/** input type for inserting array relation for remote table "card_text" */
export type Card_Text_Arr_Rel_Insert_Input = {
  data: Array<Card_Text_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Card_Text_On_Conflict>;
};

/** Boolean expression to filter rows from the table "card_text". All fields are combined with a logical 'AND'. */
export type Card_Text_Bool_Exp = {
  _and?: InputMaybe<Array<Card_Text_Bool_Exp>>;
  _not?: InputMaybe<Card_Text_Bool_Exp>;
  _or?: InputMaybe<Array<Card_Text_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  encounter_name?: InputMaybe<String_Comparison_Exp>;
  flavor?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slot?: InputMaybe<String_Comparison_Exp>;
  subname?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  traits?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "card_text" */
export enum Card_Text_Constraint {
  /** unique or primary key constraint */
  CardTextCodeLocaleKey = 'card_text_code_locale_key',
  /** unique or primary key constraint */
  CardTextPkey = 'card_text_pkey'
}

/** input type for inserting data into table "card_text" */
export type Card_Text_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  encounter_name?: InputMaybe<Scalars['String']>;
  flavor?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slot?: InputMaybe<Scalars['String']>;
  subname?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  traits?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Card_Text_Max_Fields = {
  __typename?: 'card_text_max_fields';
  code?: Maybe<Scalars['String']>;
  encounter_name?: Maybe<Scalars['String']>;
  flavor?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slot?: Maybe<Scalars['String']>;
  subname?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  traits?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "card_text" */
export type Card_Text_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  encounter_name?: InputMaybe<Order_By>;
  flavor?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slot?: InputMaybe<Order_By>;
  subname?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  traits?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Card_Text_Min_Fields = {
  __typename?: 'card_text_min_fields';
  code?: Maybe<Scalars['String']>;
  encounter_name?: Maybe<Scalars['String']>;
  flavor?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slot?: Maybe<Scalars['String']>;
  subname?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  traits?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "card_text" */
export type Card_Text_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  encounter_name?: InputMaybe<Order_By>;
  flavor?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slot?: InputMaybe<Order_By>;
  subname?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  traits?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "card_text" */
export type Card_Text_Mutation_Response = {
  __typename?: 'card_text_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Card_Text>;
};

/** on conflict condition type for table "card_text" */
export type Card_Text_On_Conflict = {
  constraint: Card_Text_Constraint;
  update_columns: Array<Card_Text_Update_Column>;
  where?: InputMaybe<Card_Text_Bool_Exp>;
};

/** Ordering options when selecting data from "card_text". */
export type Card_Text_Order_By = {
  code?: InputMaybe<Order_By>;
  encounter_name?: InputMaybe<Order_By>;
  flavor?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slot?: InputMaybe<Order_By>;
  subname?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  traits?: InputMaybe<Order_By>;
};

/** primary key columns input for table: card_text */
export type Card_Text_Pk_Columns_Input = {
  code: Scalars['String'];
  locale: Scalars['String'];
};

/** select columns of table "card_text" */
export enum Card_Text_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  EncounterName = 'encounter_name',
  /** column name */
  Flavor = 'flavor',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  Slot = 'slot',
  /** column name */
  Subname = 'subname',
  /** column name */
  Text = 'text',
  /** column name */
  Traits = 'traits'
}

/** input type for updating data in table "card_text" */
export type Card_Text_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  encounter_name?: InputMaybe<Scalars['String']>;
  flavor?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slot?: InputMaybe<Scalars['String']>;
  subname?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  traits?: InputMaybe<Scalars['String']>;
};

/** update columns of table "card_text" */
export enum Card_Text_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  EncounterName = 'encounter_name',
  /** column name */
  Flavor = 'flavor',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  Slot = 'slot',
  /** column name */
  Subname = 'subname',
  /** column name */
  Text = 'text',
  /** column name */
  Traits = 'traits'
}

/** update columns of table "card" */
export enum Card_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Cost = 'cost',
  /** column name */
  DeckLimit = 'deck_limit',
  /** column name */
  EncounterCode = 'encounter_code',
  /** column name */
  EncounterPosition = 'encounter_position',
  /** column name */
  Exceptional = 'exceptional',
  /** column name */
  Exile = 'exile',
  /** column name */
  FactionCode = 'faction_code',
  /** column name */
  Health = 'health',
  /** column name */
  IsUnique = 'is_unique',
  /** column name */
  Myriad = 'myriad',
  /** column name */
  PackCode = 'pack_code',
  /** column name */
  PackPosition = 'pack_position',
  /** column name */
  Permanent = 'permanent',
  /** column name */
  Position = 'position',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RealFlavor = 'real_flavor',
  /** column name */
  RealName = 'real_name',
  /** column name */
  RealPackName = 'real_pack_name',
  /** column name */
  RealSlot = 'real_slot',
  /** column name */
  RealSubname = 'real_subname',
  /** column name */
  RealText = 'real_text',
  /** column name */
  RealTraits = 'real_traits',
  /** column name */
  Sanity = 'sanity',
  /** column name */
  SkillAgility = 'skill_agility',
  /** column name */
  SkillCombat = 'skill_combat',
  /** column name */
  SkillIntellect = 'skill_intellect',
  /** column name */
  SkillWild = 'skill_wild',
  /** column name */
  SkillWillpower = 'skill_willpower',
  /** column name */
  SubtypeCode = 'subtype_code',
  /** column name */
  TypeCode = 'type_code'
}

/** aggregate var_pop on columns */
export type Card_Var_Pop_Fields = {
  __typename?: 'card_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  deck_limit?: Maybe<Scalars['Float']>;
  encounter_position?: Maybe<Scalars['Float']>;
  health?: Maybe<Scalars['Float']>;
  pack_position?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sanity?: Maybe<Scalars['Float']>;
  skill_agility?: Maybe<Scalars['Float']>;
  skill_combat?: Maybe<Scalars['Float']>;
  skill_intellect?: Maybe<Scalars['Float']>;
  skill_wild?: Maybe<Scalars['Float']>;
  skill_willpower?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Card_Var_Samp_Fields = {
  __typename?: 'card_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  deck_limit?: Maybe<Scalars['Float']>;
  encounter_position?: Maybe<Scalars['Float']>;
  health?: Maybe<Scalars['Float']>;
  pack_position?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sanity?: Maybe<Scalars['Float']>;
  skill_agility?: Maybe<Scalars['Float']>;
  skill_combat?: Maybe<Scalars['Float']>;
  skill_intellect?: Maybe<Scalars['Float']>;
  skill_wild?: Maybe<Scalars['Float']>;
  skill_willpower?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Card_Variance_Fields = {
  __typename?: 'card_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  deck_limit?: Maybe<Scalars['Float']>;
  encounter_position?: Maybe<Scalars['Float']>;
  health?: Maybe<Scalars['Float']>;
  pack_position?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sanity?: Maybe<Scalars['Float']>;
  skill_agility?: Maybe<Scalars['Float']>;
  skill_combat?: Maybe<Scalars['Float']>;
  skill_intellect?: Maybe<Scalars['Float']>;
  skill_wild?: Maybe<Scalars['Float']>;
  skill_willpower?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "chaos_bag_result" */
export type Chaos_Bag_Result = {
  __typename?: 'chaos_bag_result';
  bless: Scalars['Int'];
  /** An object relationship */
  campaign: Campaign;
  created_at: Scalars['timestamptz'];
  curse: Scalars['Int'];
  drawn: Scalars['jsonb'];
  id: Scalars['Int'];
  sealed: Scalars['jsonb'];
  totalDrawn?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "chaos_bag_result" */
export type Chaos_Bag_ResultDrawnArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "chaos_bag_result" */
export type Chaos_Bag_ResultSealedArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "chaos_bag_result" */
export type Chaos_Bag_Result_Aggregate = {
  __typename?: 'chaos_bag_result_aggregate';
  aggregate?: Maybe<Chaos_Bag_Result_Aggregate_Fields>;
  nodes: Array<Chaos_Bag_Result>;
};

/** aggregate fields of "chaos_bag_result" */
export type Chaos_Bag_Result_Aggregate_Fields = {
  __typename?: 'chaos_bag_result_aggregate_fields';
  avg?: Maybe<Chaos_Bag_Result_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Chaos_Bag_Result_Max_Fields>;
  min?: Maybe<Chaos_Bag_Result_Min_Fields>;
  stddev?: Maybe<Chaos_Bag_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Chaos_Bag_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Chaos_Bag_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Chaos_Bag_Result_Sum_Fields>;
  var_pop?: Maybe<Chaos_Bag_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Chaos_Bag_Result_Var_Samp_Fields>;
  variance?: Maybe<Chaos_Bag_Result_Variance_Fields>;
};


/** aggregate fields of "chaos_bag_result" */
export type Chaos_Bag_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chaos_Bag_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chaos_bag_result" */
export type Chaos_Bag_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Chaos_Bag_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chaos_Bag_Result_Max_Order_By>;
  min?: InputMaybe<Chaos_Bag_Result_Min_Order_By>;
  stddev?: InputMaybe<Chaos_Bag_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Chaos_Bag_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Chaos_Bag_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Chaos_Bag_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Chaos_Bag_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Chaos_Bag_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Chaos_Bag_Result_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Chaos_Bag_Result_Append_Input = {
  drawn?: InputMaybe<Scalars['jsonb']>;
  sealed?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "chaos_bag_result" */
export type Chaos_Bag_Result_Arr_Rel_Insert_Input = {
  data: Array<Chaos_Bag_Result_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Chaos_Bag_Result_On_Conflict>;
};

/** aggregate avg on columns */
export type Chaos_Bag_Result_Avg_Fields = {
  __typename?: 'chaos_bag_result_avg_fields';
  bless?: Maybe<Scalars['Float']>;
  curse?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalDrawn?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Avg_Order_By = {
  bless?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chaos_bag_result". All fields are combined with a logical 'AND'. */
export type Chaos_Bag_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Chaos_Bag_Result_Bool_Exp>>;
  _not?: InputMaybe<Chaos_Bag_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Chaos_Bag_Result_Bool_Exp>>;
  bless?: InputMaybe<Int_Comparison_Exp>;
  campaign?: InputMaybe<Campaign_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  curse?: InputMaybe<Int_Comparison_Exp>;
  drawn?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  sealed?: InputMaybe<Jsonb_Comparison_Exp>;
  totalDrawn?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chaos_bag_result" */
export enum Chaos_Bag_Result_Constraint {
  /** unique or primary key constraint */
  ChaosBagPkey = 'chaos_bag_pkey',
  /** unique or primary key constraint */
  ChaosBagResultsCampaignIdKey = 'chaos_bag_results_campaign_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Chaos_Bag_Result_Delete_At_Path_Input = {
  drawn?: InputMaybe<Array<Scalars['String']>>;
  sealed?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Chaos_Bag_Result_Delete_Elem_Input = {
  drawn?: InputMaybe<Scalars['Int']>;
  sealed?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Chaos_Bag_Result_Delete_Key_Input = {
  drawn?: InputMaybe<Scalars['String']>;
  sealed?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "chaos_bag_result" */
export type Chaos_Bag_Result_Inc_Input = {
  bless?: InputMaybe<Scalars['Int']>;
  curse?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  totalDrawn?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "chaos_bag_result" */
export type Chaos_Bag_Result_Insert_Input = {
  bless?: InputMaybe<Scalars['Int']>;
  campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  curse?: InputMaybe<Scalars['Int']>;
  drawn?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  sealed?: InputMaybe<Scalars['jsonb']>;
  totalDrawn?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Chaos_Bag_Result_Max_Fields = {
  __typename?: 'chaos_bag_result_max_fields';
  bless?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  curse?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  totalDrawn?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Max_Order_By = {
  bless?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chaos_Bag_Result_Min_Fields = {
  __typename?: 'chaos_bag_result_min_fields';
  bless?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  curse?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  totalDrawn?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Min_Order_By = {
  bless?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chaos_bag_result" */
export type Chaos_Bag_Result_Mutation_Response = {
  __typename?: 'chaos_bag_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chaos_Bag_Result>;
};

/** on conflict condition type for table "chaos_bag_result" */
export type Chaos_Bag_Result_On_Conflict = {
  constraint: Chaos_Bag_Result_Constraint;
  update_columns: Array<Chaos_Bag_Result_Update_Column>;
  where?: InputMaybe<Chaos_Bag_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "chaos_bag_result". */
export type Chaos_Bag_Result_Order_By = {
  bless?: InputMaybe<Order_By>;
  campaign?: InputMaybe<Campaign_Order_By>;
  created_at?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  drawn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sealed?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chaos_bag_result */
export type Chaos_Bag_Result_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Chaos_Bag_Result_Prepend_Input = {
  drawn?: InputMaybe<Scalars['jsonb']>;
  sealed?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "chaos_bag_result" */
export enum Chaos_Bag_Result_Select_Column {
  /** column name */
  Bless = 'bless',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Curse = 'curse',
  /** column name */
  Drawn = 'drawn',
  /** column name */
  Id = 'id',
  /** column name */
  Sealed = 'sealed',
  /** column name */
  TotalDrawn = 'totalDrawn',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "chaos_bag_result" */
export type Chaos_Bag_Result_Set_Input = {
  bless?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  curse?: InputMaybe<Scalars['Int']>;
  drawn?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  sealed?: InputMaybe<Scalars['jsonb']>;
  totalDrawn?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Chaos_Bag_Result_Stddev_Fields = {
  __typename?: 'chaos_bag_result_stddev_fields';
  bless?: Maybe<Scalars['Float']>;
  curse?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalDrawn?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Stddev_Order_By = {
  bless?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Chaos_Bag_Result_Stddev_Pop_Fields = {
  __typename?: 'chaos_bag_result_stddev_pop_fields';
  bless?: Maybe<Scalars['Float']>;
  curse?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalDrawn?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Stddev_Pop_Order_By = {
  bless?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Chaos_Bag_Result_Stddev_Samp_Fields = {
  __typename?: 'chaos_bag_result_stddev_samp_fields';
  bless?: Maybe<Scalars['Float']>;
  curse?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalDrawn?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Stddev_Samp_Order_By = {
  bless?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Chaos_Bag_Result_Sum_Fields = {
  __typename?: 'chaos_bag_result_sum_fields';
  bless?: Maybe<Scalars['Int']>;
  curse?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  totalDrawn?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Sum_Order_By = {
  bless?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
};

/** update columns of table "chaos_bag_result" */
export enum Chaos_Bag_Result_Update_Column {
  /** column name */
  Bless = 'bless',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Curse = 'curse',
  /** column name */
  Drawn = 'drawn',
  /** column name */
  Id = 'id',
  /** column name */
  Sealed = 'sealed',
  /** column name */
  TotalDrawn = 'totalDrawn',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Chaos_Bag_Result_Var_Pop_Fields = {
  __typename?: 'chaos_bag_result_var_pop_fields';
  bless?: Maybe<Scalars['Float']>;
  curse?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalDrawn?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Var_Pop_Order_By = {
  bless?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Chaos_Bag_Result_Var_Samp_Fields = {
  __typename?: 'chaos_bag_result_var_samp_fields';
  bless?: Maybe<Scalars['Float']>;
  curse?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalDrawn?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Var_Samp_Order_By = {
  bless?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Chaos_Bag_Result_Variance_Fields = {
  __typename?: 'chaos_bag_result_variance_fields';
  bless?: Maybe<Scalars['Float']>;
  curse?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalDrawn?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "chaos_bag_result" */
export type Chaos_Bag_Result_Variance_Order_By = {
  bless?: InputMaybe<Order_By>;
  curse?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalDrawn?: InputMaybe<Order_By>;
};

/** columns and relationships of "faq" */
export type Faq = {
  __typename?: 'faq';
  code: Scalars['String'];
  /** An array relationship */
  faq_texts: Array<Faq_Text>;
  /** An aggregate relationship */
  faq_texts_aggregate: Faq_Text_Aggregate;
  text: Scalars['String'];
};


/** columns and relationships of "faq" */
export type FaqFaq_TextsArgs = {
  distinct_on?: InputMaybe<Array<Faq_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Text_Order_By>>;
  where?: InputMaybe<Faq_Text_Bool_Exp>;
};


/** columns and relationships of "faq" */
export type FaqFaq_Texts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faq_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Text_Order_By>>;
  where?: InputMaybe<Faq_Text_Bool_Exp>;
};

/** aggregated selection of "faq" */
export type Faq_Aggregate = {
  __typename?: 'faq_aggregate';
  aggregate?: Maybe<Faq_Aggregate_Fields>;
  nodes: Array<Faq>;
};

/** aggregate fields of "faq" */
export type Faq_Aggregate_Fields = {
  __typename?: 'faq_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Faq_Max_Fields>;
  min?: Maybe<Faq_Min_Fields>;
};


/** aggregate fields of "faq" */
export type Faq_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Faq_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "faq". All fields are combined with a logical 'AND'. */
export type Faq_Bool_Exp = {
  _and?: InputMaybe<Array<Faq_Bool_Exp>>;
  _not?: InputMaybe<Faq_Bool_Exp>;
  _or?: InputMaybe<Array<Faq_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  faq_texts?: InputMaybe<Faq_Text_Bool_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "faq" */
export enum Faq_Constraint {
  /** unique or primary key constraint */
  FaqPkey = 'faq_pkey'
}

/** input type for inserting data into table "faq" */
export type Faq_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  faq_texts?: InputMaybe<Faq_Text_Arr_Rel_Insert_Input>;
  text?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Faq_Max_Fields = {
  __typename?: 'faq_max_fields';
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Faq_Min_Fields = {
  __typename?: 'faq_min_fields';
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "faq" */
export type Faq_Mutation_Response = {
  __typename?: 'faq_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Faq>;
};

/** on conflict condition type for table "faq" */
export type Faq_On_Conflict = {
  constraint: Faq_Constraint;
  update_columns: Array<Faq_Update_Column>;
  where?: InputMaybe<Faq_Bool_Exp>;
};

/** Ordering options when selecting data from "faq". */
export type Faq_Order_By = {
  code?: InputMaybe<Order_By>;
  faq_texts_aggregate?: InputMaybe<Faq_Text_Aggregate_Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: faq */
export type Faq_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "faq" */
export enum Faq_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "faq" */
export type Faq_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "faq_text" */
export type Faq_Text = {
  __typename?: 'faq_text';
  code: Scalars['String'];
  locale: Scalars['String'];
  text: Scalars['String'];
};

/** aggregated selection of "faq_text" */
export type Faq_Text_Aggregate = {
  __typename?: 'faq_text_aggregate';
  aggregate?: Maybe<Faq_Text_Aggregate_Fields>;
  nodes: Array<Faq_Text>;
};

/** aggregate fields of "faq_text" */
export type Faq_Text_Aggregate_Fields = {
  __typename?: 'faq_text_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Faq_Text_Max_Fields>;
  min?: Maybe<Faq_Text_Min_Fields>;
};


/** aggregate fields of "faq_text" */
export type Faq_Text_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Faq_Text_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "faq_text" */
export type Faq_Text_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Faq_Text_Max_Order_By>;
  min?: InputMaybe<Faq_Text_Min_Order_By>;
};

/** input type for inserting array relation for remote table "faq_text" */
export type Faq_Text_Arr_Rel_Insert_Input = {
  data: Array<Faq_Text_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Faq_Text_On_Conflict>;
};

/** Boolean expression to filter rows from the table "faq_text". All fields are combined with a logical 'AND'. */
export type Faq_Text_Bool_Exp = {
  _and?: InputMaybe<Array<Faq_Text_Bool_Exp>>;
  _not?: InputMaybe<Faq_Text_Bool_Exp>;
  _or?: InputMaybe<Array<Faq_Text_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "faq_text" */
export enum Faq_Text_Constraint {
  /** unique or primary key constraint */
  FaqTextPkey = 'faq_text_pkey'
}

/** input type for inserting data into table "faq_text" */
export type Faq_Text_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Faq_Text_Max_Fields = {
  __typename?: 'faq_text_max_fields';
  code?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "faq_text" */
export type Faq_Text_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Faq_Text_Min_Fields = {
  __typename?: 'faq_text_min_fields';
  code?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "faq_text" */
export type Faq_Text_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "faq_text" */
export type Faq_Text_Mutation_Response = {
  __typename?: 'faq_text_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Faq_Text>;
};

/** on conflict condition type for table "faq_text" */
export type Faq_Text_On_Conflict = {
  constraint: Faq_Text_Constraint;
  update_columns: Array<Faq_Text_Update_Column>;
  where?: InputMaybe<Faq_Text_Bool_Exp>;
};

/** Ordering options when selecting data from "faq_text". */
export type Faq_Text_Order_By = {
  code?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: faq_text */
export type Faq_Text_Pk_Columns_Input = {
  code: Scalars['String'];
  locale: Scalars['String'];
};

/** select columns of table "faq_text" */
export enum Faq_Text_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Locale = 'locale',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "faq_text" */
export type Faq_Text_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** update columns of table "faq_text" */
export enum Faq_Text_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Locale = 'locale',
  /** column name */
  Text = 'text'
}

/** update columns of table "faq" */
export enum Faq_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Text = 'text'
}

/** columns and relationships of "friend_status" */
export type Friend_Status = {
  __typename?: 'friend_status';
  /** A computed field, executes function "friend_status_id" */
  id?: Maybe<Scalars['String']>;
  status: Friend_Status_Type_Enum;
  user_id_a: Scalars['String'];
  user_id_b: Scalars['String'];
};

/** aggregated selection of "friend_status" */
export type Friend_Status_Aggregate = {
  __typename?: 'friend_status_aggregate';
  aggregate?: Maybe<Friend_Status_Aggregate_Fields>;
  nodes: Array<Friend_Status>;
};

/** aggregate fields of "friend_status" */
export type Friend_Status_Aggregate_Fields = {
  __typename?: 'friend_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Friend_Status_Max_Fields>;
  min?: Maybe<Friend_Status_Min_Fields>;
};


/** aggregate fields of "friend_status" */
export type Friend_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Friend_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "friend_status". All fields are combined with a logical 'AND'. */
export type Friend_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Friend_Status_Bool_Exp>>;
  _not?: InputMaybe<Friend_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Friend_Status_Bool_Exp>>;
  status?: InputMaybe<Friend_Status_Type_Enum_Comparison_Exp>;
  user_id_a?: InputMaybe<String_Comparison_Exp>;
  user_id_b?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "friend_status" */
export enum Friend_Status_Constraint {
  /** unique or primary key constraint */
  FriendStatusPkey = 'friend_status_pkey'
}

/** input type for inserting data into table "friend_status" */
export type Friend_Status_Insert_Input = {
  status?: InputMaybe<Friend_Status_Type_Enum>;
  user_id_a?: InputMaybe<Scalars['String']>;
  user_id_b?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Friend_Status_Max_Fields = {
  __typename?: 'friend_status_max_fields';
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Friend_Status_Min_Fields = {
  __typename?: 'friend_status_min_fields';
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "friend_status" */
export type Friend_Status_Mutation_Response = {
  __typename?: 'friend_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Friend_Status>;
};

/** on conflict condition type for table "friend_status" */
export type Friend_Status_On_Conflict = {
  constraint: Friend_Status_Constraint;
  update_columns: Array<Friend_Status_Update_Column>;
  where?: InputMaybe<Friend_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "friend_status". */
export type Friend_Status_Order_By = {
  status?: InputMaybe<Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** primary key columns input for table: friend_status */
export type Friend_Status_Pk_Columns_Input = {
  user_id_a: Scalars['String'];
  user_id_b: Scalars['String'];
};

/** select columns of table "friend_status" */
export enum Friend_Status_Select_Column {
  /** column name */
  Status = 'status',
  /** column name */
  UserIdA = 'user_id_a',
  /** column name */
  UserIdB = 'user_id_b'
}

/** input type for updating data in table "friend_status" */
export type Friend_Status_Set_Input = {
  status?: InputMaybe<Friend_Status_Type_Enum>;
  user_id_a?: InputMaybe<Scalars['String']>;
  user_id_b?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "friend_status_type" */
export type Friend_Status_Type = {
  __typename?: 'friend_status_type';
  value: Scalars['String'];
};

/** aggregated selection of "friend_status_type" */
export type Friend_Status_Type_Aggregate = {
  __typename?: 'friend_status_type_aggregate';
  aggregate?: Maybe<Friend_Status_Type_Aggregate_Fields>;
  nodes: Array<Friend_Status_Type>;
};

/** aggregate fields of "friend_status_type" */
export type Friend_Status_Type_Aggregate_Fields = {
  __typename?: 'friend_status_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Friend_Status_Type_Max_Fields>;
  min?: Maybe<Friend_Status_Type_Min_Fields>;
};


/** aggregate fields of "friend_status_type" */
export type Friend_Status_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Friend_Status_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "friend_status_type". All fields are combined with a logical 'AND'. */
export type Friend_Status_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Friend_Status_Type_Bool_Exp>>;
  _not?: InputMaybe<Friend_Status_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Friend_Status_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "friend_status_type" */
export enum Friend_Status_Type_Constraint {
  /** unique or primary key constraint */
  FriendStatusTypePkey = 'friend_status_type_pkey'
}

export enum Friend_Status_Type_Enum {
  Friend = 'friend',
  None = 'none',
  Received = 'received',
  Sent = 'sent'
}

/** Boolean expression to compare columns of type "friend_status_type_enum". All fields are combined with logical 'AND'. */
export type Friend_Status_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Friend_Status_Type_Enum>;
  _in?: InputMaybe<Array<Friend_Status_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Friend_Status_Type_Enum>;
  _nin?: InputMaybe<Array<Friend_Status_Type_Enum>>;
};

/** input type for inserting data into table "friend_status_type" */
export type Friend_Status_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Friend_Status_Type_Max_Fields = {
  __typename?: 'friend_status_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Friend_Status_Type_Min_Fields = {
  __typename?: 'friend_status_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "friend_status_type" */
export type Friend_Status_Type_Mutation_Response = {
  __typename?: 'friend_status_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Friend_Status_Type>;
};

/** on conflict condition type for table "friend_status_type" */
export type Friend_Status_Type_On_Conflict = {
  constraint: Friend_Status_Type_Constraint;
  update_columns: Array<Friend_Status_Type_Update_Column>;
  where?: InputMaybe<Friend_Status_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "friend_status_type". */
export type Friend_Status_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: friend_status_type */
export type Friend_Status_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "friend_status_type" */
export enum Friend_Status_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "friend_status_type" */
export type Friend_Status_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "friend_status_type" */
export enum Friend_Status_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "friend_status" */
export enum Friend_Status_Update_Column {
  /** column name */
  Status = 'status',
  /** column name */
  UserIdA = 'user_id_a',
  /** column name */
  UserIdB = 'user_id_b'
}

/** columns and relationships of "guide_achievement" */
export type Guide_Achievement = {
  __typename?: 'guide_achievement';
  bool_value?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  campaign: Campaign;
  campaign_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "guide_achievement" */
export type Guide_Achievement_Aggregate = {
  __typename?: 'guide_achievement_aggregate';
  aggregate?: Maybe<Guide_Achievement_Aggregate_Fields>;
  nodes: Array<Guide_Achievement>;
};

/** aggregate fields of "guide_achievement" */
export type Guide_Achievement_Aggregate_Fields = {
  __typename?: 'guide_achievement_aggregate_fields';
  avg?: Maybe<Guide_Achievement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Guide_Achievement_Max_Fields>;
  min?: Maybe<Guide_Achievement_Min_Fields>;
  stddev?: Maybe<Guide_Achievement_Stddev_Fields>;
  stddev_pop?: Maybe<Guide_Achievement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Guide_Achievement_Stddev_Samp_Fields>;
  sum?: Maybe<Guide_Achievement_Sum_Fields>;
  var_pop?: Maybe<Guide_Achievement_Var_Pop_Fields>;
  var_samp?: Maybe<Guide_Achievement_Var_Samp_Fields>;
  variance?: Maybe<Guide_Achievement_Variance_Fields>;
};


/** aggregate fields of "guide_achievement" */
export type Guide_Achievement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Guide_Achievement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "guide_achievement" */
export type Guide_Achievement_Aggregate_Order_By = {
  avg?: InputMaybe<Guide_Achievement_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Guide_Achievement_Max_Order_By>;
  min?: InputMaybe<Guide_Achievement_Min_Order_By>;
  stddev?: InputMaybe<Guide_Achievement_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Guide_Achievement_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Guide_Achievement_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Guide_Achievement_Sum_Order_By>;
  var_pop?: InputMaybe<Guide_Achievement_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Guide_Achievement_Var_Samp_Order_By>;
  variance?: InputMaybe<Guide_Achievement_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "guide_achievement" */
export type Guide_Achievement_Arr_Rel_Insert_Input = {
  data: Array<Guide_Achievement_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Guide_Achievement_On_Conflict>;
};

/** aggregate avg on columns */
export type Guide_Achievement_Avg_Fields = {
  __typename?: 'guide_achievement_avg_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "guide_achievement" */
export type Guide_Achievement_Avg_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "guide_achievement". All fields are combined with a logical 'AND'. */
export type Guide_Achievement_Bool_Exp = {
  _and?: InputMaybe<Array<Guide_Achievement_Bool_Exp>>;
  _not?: InputMaybe<Guide_Achievement_Bool_Exp>;
  _or?: InputMaybe<Array<Guide_Achievement_Bool_Exp>>;
  bool_value?: InputMaybe<Boolean_Comparison_Exp>;
  campaign?: InputMaybe<Campaign_Bool_Exp>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "guide_achievement" */
export enum Guide_Achievement_Constraint {
  /** unique or primary key constraint */
  GuideAchievementPkey = 'guide_achievement_pkey'
}

/** input type for incrementing numeric columns in table "guide_achievement" */
export type Guide_Achievement_Inc_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "guide_achievement" */
export type Guide_Achievement_Insert_Input = {
  bool_value?: InputMaybe<Scalars['Boolean']>;
  campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Guide_Achievement_Max_Fields = {
  __typename?: 'guide_achievement_max_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "guide_achievement" */
export type Guide_Achievement_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Guide_Achievement_Min_Fields = {
  __typename?: 'guide_achievement_min_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "guide_achievement" */
export type Guide_Achievement_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "guide_achievement" */
export type Guide_Achievement_Mutation_Response = {
  __typename?: 'guide_achievement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Guide_Achievement>;
};

/** on conflict condition type for table "guide_achievement" */
export type Guide_Achievement_On_Conflict = {
  constraint: Guide_Achievement_Constraint;
  update_columns: Array<Guide_Achievement_Update_Column>;
  where?: InputMaybe<Guide_Achievement_Bool_Exp>;
};

/** Ordering options when selecting data from "guide_achievement". */
export type Guide_Achievement_Order_By = {
  bool_value?: InputMaybe<Order_By>;
  campaign?: InputMaybe<Campaign_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: guide_achievement */
export type Guide_Achievement_Pk_Columns_Input = {
  campaign_id: Scalars['Int'];
  id: Scalars['String'];
};

/** select columns of table "guide_achievement" */
export enum Guide_Achievement_Select_Column {
  /** column name */
  BoolValue = 'bool_value',
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "guide_achievement" */
export type Guide_Achievement_Set_Input = {
  bool_value?: InputMaybe<Scalars['Boolean']>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Guide_Achievement_Stddev_Fields = {
  __typename?: 'guide_achievement_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "guide_achievement" */
export type Guide_Achievement_Stddev_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Guide_Achievement_Stddev_Pop_Fields = {
  __typename?: 'guide_achievement_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "guide_achievement" */
export type Guide_Achievement_Stddev_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Guide_Achievement_Stddev_Samp_Fields = {
  __typename?: 'guide_achievement_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "guide_achievement" */
export type Guide_Achievement_Stddev_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Guide_Achievement_Sum_Fields = {
  __typename?: 'guide_achievement_sum_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "guide_achievement" */
export type Guide_Achievement_Sum_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "guide_achievement" */
export enum Guide_Achievement_Update_Column {
  /** column name */
  BoolValue = 'bool_value',
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Guide_Achievement_Var_Pop_Fields = {
  __typename?: 'guide_achievement_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "guide_achievement" */
export type Guide_Achievement_Var_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Guide_Achievement_Var_Samp_Fields = {
  __typename?: 'guide_achievement_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "guide_achievement" */
export type Guide_Achievement_Var_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Guide_Achievement_Variance_Fields = {
  __typename?: 'guide_achievement_variance_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "guide_achievement" */
export type Guide_Achievement_Variance_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "guide_input" */
export type Guide_Input = {
  __typename?: 'guide_input';
  /** An object relationship */
  campaign: Campaign;
  campaign_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['String'];
  payload?: Maybe<Scalars['jsonb']>;
  scenario?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};


/** columns and relationships of "guide_input" */
export type Guide_InputPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "guide_input" */
export type Guide_Input_Aggregate = {
  __typename?: 'guide_input_aggregate';
  aggregate?: Maybe<Guide_Input_Aggregate_Fields>;
  nodes: Array<Guide_Input>;
};

/** aggregate fields of "guide_input" */
export type Guide_Input_Aggregate_Fields = {
  __typename?: 'guide_input_aggregate_fields';
  avg?: Maybe<Guide_Input_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Guide_Input_Max_Fields>;
  min?: Maybe<Guide_Input_Min_Fields>;
  stddev?: Maybe<Guide_Input_Stddev_Fields>;
  stddev_pop?: Maybe<Guide_Input_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Guide_Input_Stddev_Samp_Fields>;
  sum?: Maybe<Guide_Input_Sum_Fields>;
  var_pop?: Maybe<Guide_Input_Var_Pop_Fields>;
  var_samp?: Maybe<Guide_Input_Var_Samp_Fields>;
  variance?: Maybe<Guide_Input_Variance_Fields>;
};


/** aggregate fields of "guide_input" */
export type Guide_Input_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Guide_Input_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "guide_input" */
export type Guide_Input_Aggregate_Order_By = {
  avg?: InputMaybe<Guide_Input_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Guide_Input_Max_Order_By>;
  min?: InputMaybe<Guide_Input_Min_Order_By>;
  stddev?: InputMaybe<Guide_Input_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Guide_Input_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Guide_Input_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Guide_Input_Sum_Order_By>;
  var_pop?: InputMaybe<Guide_Input_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Guide_Input_Var_Samp_Order_By>;
  variance?: InputMaybe<Guide_Input_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Guide_Input_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "guide_input" */
export type Guide_Input_Arr_Rel_Insert_Input = {
  data: Array<Guide_Input_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Guide_Input_On_Conflict>;
};

/** aggregate avg on columns */
export type Guide_Input_Avg_Fields = {
  __typename?: 'guide_input_avg_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "guide_input" */
export type Guide_Input_Avg_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "guide_input". All fields are combined with a logical 'AND'. */
export type Guide_Input_Bool_Exp = {
  _and?: InputMaybe<Array<Guide_Input_Bool_Exp>>;
  _not?: InputMaybe<Guide_Input_Bool_Exp>;
  _or?: InputMaybe<Array<Guide_Input_Bool_Exp>>;
  campaign?: InputMaybe<Campaign_Bool_Exp>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  scenario?: InputMaybe<String_Comparison_Exp>;
  step?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "guide_input" */
export enum Guide_Input_Constraint {
  /** unique or primary key constraint */
  GuideInputPkey = 'guide_input_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Guide_Input_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Guide_Input_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Guide_Input_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "guide_input" */
export type Guide_Input_Inc_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "guide_input" */
export type Guide_Input_Insert_Input = {
  campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  scenario?: InputMaybe<Scalars['String']>;
  step?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Guide_Input_Max_Fields = {
  __typename?: 'guide_input_max_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  scenario?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "guide_input" */
export type Guide_Input_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  scenario?: InputMaybe<Order_By>;
  step?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Guide_Input_Min_Fields = {
  __typename?: 'guide_input_min_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  scenario?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "guide_input" */
export type Guide_Input_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  scenario?: InputMaybe<Order_By>;
  step?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "guide_input" */
export type Guide_Input_Mutation_Response = {
  __typename?: 'guide_input_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Guide_Input>;
};

/** on conflict condition type for table "guide_input" */
export type Guide_Input_On_Conflict = {
  constraint: Guide_Input_Constraint;
  update_columns: Array<Guide_Input_Update_Column>;
  where?: InputMaybe<Guide_Input_Bool_Exp>;
};

/** Ordering options when selecting data from "guide_input". */
export type Guide_Input_Order_By = {
  campaign?: InputMaybe<Campaign_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  scenario?: InputMaybe<Order_By>;
  step?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: guide_input */
export type Guide_Input_Pk_Columns_Input = {
  campaign_id: Scalars['Int'];
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Guide_Input_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "guide_input" */
export enum Guide_Input_Select_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Scenario = 'scenario',
  /** column name */
  Step = 'step',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "guide_input" */
export type Guide_Input_Set_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  scenario?: InputMaybe<Scalars['String']>;
  step?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Guide_Input_Stddev_Fields = {
  __typename?: 'guide_input_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "guide_input" */
export type Guide_Input_Stddev_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Guide_Input_Stddev_Pop_Fields = {
  __typename?: 'guide_input_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "guide_input" */
export type Guide_Input_Stddev_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Guide_Input_Stddev_Samp_Fields = {
  __typename?: 'guide_input_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "guide_input" */
export type Guide_Input_Stddev_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Guide_Input_Sum_Fields = {
  __typename?: 'guide_input_sum_fields';
  campaign_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "guide_input" */
export type Guide_Input_Sum_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** update columns of table "guide_input" */
export enum Guide_Input_Update_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Scenario = 'scenario',
  /** column name */
  Step = 'step',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Guide_Input_Var_Pop_Fields = {
  __typename?: 'guide_input_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "guide_input" */
export type Guide_Input_Var_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Guide_Input_Var_Samp_Fields = {
  __typename?: 'guide_input_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "guide_input" */
export type Guide_Input_Var_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Guide_Input_Variance_Fields = {
  __typename?: 'guide_input_variance_fields';
  campaign_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "guide_input" */
export type Guide_Input_Variance_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "investigator_data" */
export type Investigator_Data = {
  __typename?: 'investigator_data';
  addedCards?: Maybe<Scalars['jsonb']>;
  availableXp?: Maybe<Scalars['Int']>;
  /** An object relationship */
  campaign_data: Campaign;
  campaign_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** A computed field, executes function "investigator_data_id" */
  id?: Maybe<Scalars['String']>;
  ignoreStoryAssets?: Maybe<Scalars['jsonb']>;
  insane?: Maybe<Scalars['Boolean']>;
  investigator: Scalars['String'];
  killed?: Maybe<Scalars['Boolean']>;
  mental?: Maybe<Scalars['Int']>;
  physical?: Maybe<Scalars['Int']>;
  removedCards?: Maybe<Scalars['jsonb']>;
  specialXp?: Maybe<Scalars['jsonb']>;
  spentXp?: Maybe<Scalars['Int']>;
  storyAssets?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "investigator_data" */
export type Investigator_DataAddedCardsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "investigator_data" */
export type Investigator_DataIgnoreStoryAssetsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "investigator_data" */
export type Investigator_DataRemovedCardsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "investigator_data" */
export type Investigator_DataSpecialXpArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "investigator_data" */
export type Investigator_DataStoryAssetsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "investigator_data" */
export type Investigator_Data_Aggregate = {
  __typename?: 'investigator_data_aggregate';
  aggregate?: Maybe<Investigator_Data_Aggregate_Fields>;
  nodes: Array<Investigator_Data>;
};

/** aggregate fields of "investigator_data" */
export type Investigator_Data_Aggregate_Fields = {
  __typename?: 'investigator_data_aggregate_fields';
  avg?: Maybe<Investigator_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Investigator_Data_Max_Fields>;
  min?: Maybe<Investigator_Data_Min_Fields>;
  stddev?: Maybe<Investigator_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Investigator_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investigator_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Investigator_Data_Sum_Fields>;
  var_pop?: Maybe<Investigator_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Investigator_Data_Var_Samp_Fields>;
  variance?: Maybe<Investigator_Data_Variance_Fields>;
};


/** aggregate fields of "investigator_data" */
export type Investigator_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investigator_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "investigator_data" */
export type Investigator_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Investigator_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Investigator_Data_Max_Order_By>;
  min?: InputMaybe<Investigator_Data_Min_Order_By>;
  stddev?: InputMaybe<Investigator_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Investigator_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Investigator_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Investigator_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Investigator_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Investigator_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Investigator_Data_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Investigator_Data_Append_Input = {
  addedCards?: InputMaybe<Scalars['jsonb']>;
  ignoreStoryAssets?: InputMaybe<Scalars['jsonb']>;
  removedCards?: InputMaybe<Scalars['jsonb']>;
  specialXp?: InputMaybe<Scalars['jsonb']>;
  storyAssets?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "investigator_data" */
export type Investigator_Data_Arr_Rel_Insert_Input = {
  data: Array<Investigator_Data_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Investigator_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Investigator_Data_Avg_Fields = {
  __typename?: 'investigator_data_avg_fields';
  availableXp?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  mental?: Maybe<Scalars['Float']>;
  physical?: Maybe<Scalars['Float']>;
  spentXp?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "investigator_data" */
export type Investigator_Data_Avg_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "investigator_data". All fields are combined with a logical 'AND'. */
export type Investigator_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Investigator_Data_Bool_Exp>>;
  _not?: InputMaybe<Investigator_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Investigator_Data_Bool_Exp>>;
  addedCards?: InputMaybe<Jsonb_Comparison_Exp>;
  availableXp?: InputMaybe<Int_Comparison_Exp>;
  campaign_data?: InputMaybe<Campaign_Bool_Exp>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ignoreStoryAssets?: InputMaybe<Jsonb_Comparison_Exp>;
  insane?: InputMaybe<Boolean_Comparison_Exp>;
  investigator?: InputMaybe<String_Comparison_Exp>;
  killed?: InputMaybe<Boolean_Comparison_Exp>;
  mental?: InputMaybe<Int_Comparison_Exp>;
  physical?: InputMaybe<Int_Comparison_Exp>;
  removedCards?: InputMaybe<Jsonb_Comparison_Exp>;
  specialXp?: InputMaybe<Jsonb_Comparison_Exp>;
  spentXp?: InputMaybe<Int_Comparison_Exp>;
  storyAssets?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "investigator_data" */
export enum Investigator_Data_Constraint {
  /** unique or primary key constraint */
  InvestigatorDataPkey = 'investigator_data_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Investigator_Data_Delete_At_Path_Input = {
  addedCards?: InputMaybe<Array<Scalars['String']>>;
  ignoreStoryAssets?: InputMaybe<Array<Scalars['String']>>;
  removedCards?: InputMaybe<Array<Scalars['String']>>;
  specialXp?: InputMaybe<Array<Scalars['String']>>;
  storyAssets?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Investigator_Data_Delete_Elem_Input = {
  addedCards?: InputMaybe<Scalars['Int']>;
  ignoreStoryAssets?: InputMaybe<Scalars['Int']>;
  removedCards?: InputMaybe<Scalars['Int']>;
  specialXp?: InputMaybe<Scalars['Int']>;
  storyAssets?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Investigator_Data_Delete_Key_Input = {
  addedCards?: InputMaybe<Scalars['String']>;
  ignoreStoryAssets?: InputMaybe<Scalars['String']>;
  removedCards?: InputMaybe<Scalars['String']>;
  specialXp?: InputMaybe<Scalars['String']>;
  storyAssets?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "investigator_data" */
export type Investigator_Data_Inc_Input = {
  availableXp?: InputMaybe<Scalars['Int']>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  mental?: InputMaybe<Scalars['Int']>;
  physical?: InputMaybe<Scalars['Int']>;
  spentXp?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "investigator_data" */
export type Investigator_Data_Insert_Input = {
  addedCards?: InputMaybe<Scalars['jsonb']>;
  availableXp?: InputMaybe<Scalars['Int']>;
  campaign_data?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ignoreStoryAssets?: InputMaybe<Scalars['jsonb']>;
  insane?: InputMaybe<Scalars['Boolean']>;
  investigator?: InputMaybe<Scalars['String']>;
  killed?: InputMaybe<Scalars['Boolean']>;
  mental?: InputMaybe<Scalars['Int']>;
  physical?: InputMaybe<Scalars['Int']>;
  removedCards?: InputMaybe<Scalars['jsonb']>;
  specialXp?: InputMaybe<Scalars['jsonb']>;
  spentXp?: InputMaybe<Scalars['Int']>;
  storyAssets?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Investigator_Data_Max_Fields = {
  __typename?: 'investigator_data_max_fields';
  availableXp?: Maybe<Scalars['Int']>;
  campaign_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  investigator?: Maybe<Scalars['String']>;
  mental?: Maybe<Scalars['Int']>;
  physical?: Maybe<Scalars['Int']>;
  spentXp?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "investigator_data" */
export type Investigator_Data_Max_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  investigator?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Investigator_Data_Min_Fields = {
  __typename?: 'investigator_data_min_fields';
  availableXp?: Maybe<Scalars['Int']>;
  campaign_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  investigator?: Maybe<Scalars['String']>;
  mental?: Maybe<Scalars['Int']>;
  physical?: Maybe<Scalars['Int']>;
  spentXp?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "investigator_data" */
export type Investigator_Data_Min_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  investigator?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "investigator_data" */
export type Investigator_Data_Mutation_Response = {
  __typename?: 'investigator_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Investigator_Data>;
};

/** input type for inserting object relation for remote table "investigator_data" */
export type Investigator_Data_Obj_Rel_Insert_Input = {
  data: Investigator_Data_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Investigator_Data_On_Conflict>;
};

/** on conflict condition type for table "investigator_data" */
export type Investigator_Data_On_Conflict = {
  constraint: Investigator_Data_Constraint;
  update_columns: Array<Investigator_Data_Update_Column>;
  where?: InputMaybe<Investigator_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "investigator_data". */
export type Investigator_Data_Order_By = {
  addedCards?: InputMaybe<Order_By>;
  availableXp?: InputMaybe<Order_By>;
  campaign_data?: InputMaybe<Campaign_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ignoreStoryAssets?: InputMaybe<Order_By>;
  insane?: InputMaybe<Order_By>;
  investigator?: InputMaybe<Order_By>;
  killed?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  removedCards?: InputMaybe<Order_By>;
  specialXp?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
  storyAssets?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: investigator_data */
export type Investigator_Data_Pk_Columns_Input = {
  campaign_id: Scalars['Int'];
  investigator: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Investigator_Data_Prepend_Input = {
  addedCards?: InputMaybe<Scalars['jsonb']>;
  ignoreStoryAssets?: InputMaybe<Scalars['jsonb']>;
  removedCards?: InputMaybe<Scalars['jsonb']>;
  specialXp?: InputMaybe<Scalars['jsonb']>;
  storyAssets?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "investigator_data" */
export enum Investigator_Data_Select_Column {
  /** column name */
  AddedCards = 'addedCards',
  /** column name */
  AvailableXp = 'availableXp',
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IgnoreStoryAssets = 'ignoreStoryAssets',
  /** column name */
  Insane = 'insane',
  /** column name */
  Investigator = 'investigator',
  /** column name */
  Killed = 'killed',
  /** column name */
  Mental = 'mental',
  /** column name */
  Physical = 'physical',
  /** column name */
  RemovedCards = 'removedCards',
  /** column name */
  SpecialXp = 'specialXp',
  /** column name */
  SpentXp = 'spentXp',
  /** column name */
  StoryAssets = 'storyAssets',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "investigator_data" */
export type Investigator_Data_Set_Input = {
  addedCards?: InputMaybe<Scalars['jsonb']>;
  availableXp?: InputMaybe<Scalars['Int']>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ignoreStoryAssets?: InputMaybe<Scalars['jsonb']>;
  insane?: InputMaybe<Scalars['Boolean']>;
  investigator?: InputMaybe<Scalars['String']>;
  killed?: InputMaybe<Scalars['Boolean']>;
  mental?: InputMaybe<Scalars['Int']>;
  physical?: InputMaybe<Scalars['Int']>;
  removedCards?: InputMaybe<Scalars['jsonb']>;
  specialXp?: InputMaybe<Scalars['jsonb']>;
  spentXp?: InputMaybe<Scalars['Int']>;
  storyAssets?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Investigator_Data_Stddev_Fields = {
  __typename?: 'investigator_data_stddev_fields';
  availableXp?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  mental?: Maybe<Scalars['Float']>;
  physical?: Maybe<Scalars['Float']>;
  spentXp?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "investigator_data" */
export type Investigator_Data_Stddev_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Investigator_Data_Stddev_Pop_Fields = {
  __typename?: 'investigator_data_stddev_pop_fields';
  availableXp?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  mental?: Maybe<Scalars['Float']>;
  physical?: Maybe<Scalars['Float']>;
  spentXp?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "investigator_data" */
export type Investigator_Data_Stddev_Pop_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Investigator_Data_Stddev_Samp_Fields = {
  __typename?: 'investigator_data_stddev_samp_fields';
  availableXp?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  mental?: Maybe<Scalars['Float']>;
  physical?: Maybe<Scalars['Float']>;
  spentXp?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "investigator_data" */
export type Investigator_Data_Stddev_Samp_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Investigator_Data_Sum_Fields = {
  __typename?: 'investigator_data_sum_fields';
  availableXp?: Maybe<Scalars['Int']>;
  campaign_id?: Maybe<Scalars['Int']>;
  mental?: Maybe<Scalars['Int']>;
  physical?: Maybe<Scalars['Int']>;
  spentXp?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "investigator_data" */
export type Investigator_Data_Sum_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
};

/** update columns of table "investigator_data" */
export enum Investigator_Data_Update_Column {
  /** column name */
  AddedCards = 'addedCards',
  /** column name */
  AvailableXp = 'availableXp',
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IgnoreStoryAssets = 'ignoreStoryAssets',
  /** column name */
  Insane = 'insane',
  /** column name */
  Investigator = 'investigator',
  /** column name */
  Killed = 'killed',
  /** column name */
  Mental = 'mental',
  /** column name */
  Physical = 'physical',
  /** column name */
  RemovedCards = 'removedCards',
  /** column name */
  SpecialXp = 'specialXp',
  /** column name */
  SpentXp = 'spentXp',
  /** column name */
  StoryAssets = 'storyAssets',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Investigator_Data_Var_Pop_Fields = {
  __typename?: 'investigator_data_var_pop_fields';
  availableXp?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  mental?: Maybe<Scalars['Float']>;
  physical?: Maybe<Scalars['Float']>;
  spentXp?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "investigator_data" */
export type Investigator_Data_Var_Pop_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Investigator_Data_Var_Samp_Fields = {
  __typename?: 'investigator_data_var_samp_fields';
  availableXp?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  mental?: Maybe<Scalars['Float']>;
  physical?: Maybe<Scalars['Float']>;
  spentXp?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "investigator_data" */
export type Investigator_Data_Var_Samp_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Investigator_Data_Variance_Fields = {
  __typename?: 'investigator_data_variance_fields';
  availableXp?: Maybe<Scalars['Float']>;
  campaign_id?: Maybe<Scalars['Float']>;
  mental?: Maybe<Scalars['Float']>;
  physical?: Maybe<Scalars['Float']>;
  spentXp?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "investigator_data" */
export type Investigator_Data_Variance_Order_By = {
  availableXp?: InputMaybe<Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  mental?: InputMaybe<Order_By>;
  physical?: InputMaybe<Order_By>;
  spentXp?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "latest_decks" */
export type Latest_Decks = {
  __typename?: 'latest_decks';
  /** An object relationship */
  campaign?: Maybe<Campaign>;
  campaign_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  deck?: Maybe<Campaign_Deck>;
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "latest_decks" */
export type Latest_Decks_Aggregate = {
  __typename?: 'latest_decks_aggregate';
  aggregate?: Maybe<Latest_Decks_Aggregate_Fields>;
  nodes: Array<Latest_Decks>;
};

/** aggregate fields of "latest_decks" */
export type Latest_Decks_Aggregate_Fields = {
  __typename?: 'latest_decks_aggregate_fields';
  avg?: Maybe<Latest_Decks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Latest_Decks_Max_Fields>;
  min?: Maybe<Latest_Decks_Min_Fields>;
  stddev?: Maybe<Latest_Decks_Stddev_Fields>;
  stddev_pop?: Maybe<Latest_Decks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Latest_Decks_Stddev_Samp_Fields>;
  sum?: Maybe<Latest_Decks_Sum_Fields>;
  var_pop?: Maybe<Latest_Decks_Var_Pop_Fields>;
  var_samp?: Maybe<Latest_Decks_Var_Samp_Fields>;
  variance?: Maybe<Latest_Decks_Variance_Fields>;
};


/** aggregate fields of "latest_decks" */
export type Latest_Decks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Latest_Decks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "latest_decks" */
export type Latest_Decks_Aggregate_Order_By = {
  avg?: InputMaybe<Latest_Decks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Latest_Decks_Max_Order_By>;
  min?: InputMaybe<Latest_Decks_Min_Order_By>;
  stddev?: InputMaybe<Latest_Decks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Latest_Decks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Latest_Decks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Latest_Decks_Sum_Order_By>;
  var_pop?: InputMaybe<Latest_Decks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Latest_Decks_Var_Samp_Order_By>;
  variance?: InputMaybe<Latest_Decks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "latest_decks" */
export type Latest_Decks_Arr_Rel_Insert_Input = {
  data: Array<Latest_Decks_Insert_Input>;
};

/** aggregate avg on columns */
export type Latest_Decks_Avg_Fields = {
  __typename?: 'latest_decks_avg_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "latest_decks" */
export type Latest_Decks_Avg_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "latest_decks". All fields are combined with a logical 'AND'. */
export type Latest_Decks_Bool_Exp = {
  _and?: InputMaybe<Array<Latest_Decks_Bool_Exp>>;
  _not?: InputMaybe<Latest_Decks_Bool_Exp>;
  _or?: InputMaybe<Array<Latest_Decks_Bool_Exp>>;
  campaign?: InputMaybe<Campaign_Bool_Exp>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  deck?: InputMaybe<Campaign_Deck_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  owner_id?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "latest_decks" */
export type Latest_Decks_Inc_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "latest_decks" */
export type Latest_Decks_Insert_Input = {
  campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  deck?: InputMaybe<Campaign_Deck_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Latest_Decks_Max_Fields = {
  __typename?: 'latest_decks_max_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "latest_decks" */
export type Latest_Decks_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Latest_Decks_Min_Fields = {
  __typename?: 'latest_decks_min_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "latest_decks" */
export type Latest_Decks_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "latest_decks" */
export type Latest_Decks_Mutation_Response = {
  __typename?: 'latest_decks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Latest_Decks>;
};

/** Ordering options when selecting data from "latest_decks". */
export type Latest_Decks_Order_By = {
  campaign?: InputMaybe<Campaign_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  deck?: InputMaybe<Campaign_Deck_Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** select columns of table "latest_decks" */
export enum Latest_Decks_Select_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id'
}

/** input type for updating data in table "latest_decks" */
export type Latest_Decks_Set_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Latest_Decks_Stddev_Fields = {
  __typename?: 'latest_decks_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "latest_decks" */
export type Latest_Decks_Stddev_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Latest_Decks_Stddev_Pop_Fields = {
  __typename?: 'latest_decks_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "latest_decks" */
export type Latest_Decks_Stddev_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Latest_Decks_Stddev_Samp_Fields = {
  __typename?: 'latest_decks_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "latest_decks" */
export type Latest_Decks_Stddev_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Latest_Decks_Sum_Fields = {
  __typename?: 'latest_decks_sum_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "latest_decks" */
export type Latest_Decks_Sum_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Latest_Decks_Var_Pop_Fields = {
  __typename?: 'latest_decks_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "latest_decks" */
export type Latest_Decks_Var_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Latest_Decks_Var_Samp_Fields = {
  __typename?: 'latest_decks_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "latest_decks" */
export type Latest_Decks_Var_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Latest_Decks_Variance_Fields = {
  __typename?: 'latest_decks_variance_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "latest_decks" */
export type Latest_Decks_Variance_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "local_decks" */
export type Local_Decks = {
  __typename?: 'local_decks';
  /** An object relationship */
  campaign?: Maybe<Campaign>;
  campaign_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  deck?: Maybe<Campaign_Deck>;
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "local_decks" */
export type Local_Decks_Aggregate = {
  __typename?: 'local_decks_aggregate';
  aggregate?: Maybe<Local_Decks_Aggregate_Fields>;
  nodes: Array<Local_Decks>;
};

/** aggregate fields of "local_decks" */
export type Local_Decks_Aggregate_Fields = {
  __typename?: 'local_decks_aggregate_fields';
  avg?: Maybe<Local_Decks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Local_Decks_Max_Fields>;
  min?: Maybe<Local_Decks_Min_Fields>;
  stddev?: Maybe<Local_Decks_Stddev_Fields>;
  stddev_pop?: Maybe<Local_Decks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Local_Decks_Stddev_Samp_Fields>;
  sum?: Maybe<Local_Decks_Sum_Fields>;
  var_pop?: Maybe<Local_Decks_Var_Pop_Fields>;
  var_samp?: Maybe<Local_Decks_Var_Samp_Fields>;
  variance?: Maybe<Local_Decks_Variance_Fields>;
};


/** aggregate fields of "local_decks" */
export type Local_Decks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Local_Decks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "local_decks" */
export type Local_Decks_Aggregate_Order_By = {
  avg?: InputMaybe<Local_Decks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Local_Decks_Max_Order_By>;
  min?: InputMaybe<Local_Decks_Min_Order_By>;
  stddev?: InputMaybe<Local_Decks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Local_Decks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Local_Decks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Local_Decks_Sum_Order_By>;
  var_pop?: InputMaybe<Local_Decks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Local_Decks_Var_Samp_Order_By>;
  variance?: InputMaybe<Local_Decks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "local_decks" */
export type Local_Decks_Arr_Rel_Insert_Input = {
  data: Array<Local_Decks_Insert_Input>;
};

/** aggregate avg on columns */
export type Local_Decks_Avg_Fields = {
  __typename?: 'local_decks_avg_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "local_decks" */
export type Local_Decks_Avg_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "local_decks". All fields are combined with a logical 'AND'. */
export type Local_Decks_Bool_Exp = {
  _and?: InputMaybe<Array<Local_Decks_Bool_Exp>>;
  _not?: InputMaybe<Local_Decks_Bool_Exp>;
  _or?: InputMaybe<Array<Local_Decks_Bool_Exp>>;
  campaign?: InputMaybe<Campaign_Bool_Exp>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  deck?: InputMaybe<Campaign_Deck_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  owner_id?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "local_decks" */
export type Local_Decks_Inc_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "local_decks" */
export type Local_Decks_Insert_Input = {
  campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  deck?: InputMaybe<Campaign_Deck_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Local_Decks_Max_Fields = {
  __typename?: 'local_decks_max_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "local_decks" */
export type Local_Decks_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Local_Decks_Min_Fields = {
  __typename?: 'local_decks_min_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "local_decks" */
export type Local_Decks_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "local_decks" */
export type Local_Decks_Mutation_Response = {
  __typename?: 'local_decks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Local_Decks>;
};

/** Ordering options when selecting data from "local_decks". */
export type Local_Decks_Order_By = {
  campaign?: InputMaybe<Campaign_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  deck?: InputMaybe<Campaign_Deck_Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** select columns of table "local_decks" */
export enum Local_Decks_Select_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id'
}

/** input type for updating data in table "local_decks" */
export type Local_Decks_Set_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Local_Decks_Stddev_Fields = {
  __typename?: 'local_decks_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "local_decks" */
export type Local_Decks_Stddev_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Local_Decks_Stddev_Pop_Fields = {
  __typename?: 'local_decks_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "local_decks" */
export type Local_Decks_Stddev_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Local_Decks_Stddev_Samp_Fields = {
  __typename?: 'local_decks_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "local_decks" */
export type Local_Decks_Stddev_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Local_Decks_Sum_Fields = {
  __typename?: 'local_decks_sum_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "local_decks" */
export type Local_Decks_Sum_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Local_Decks_Var_Pop_Fields = {
  __typename?: 'local_decks_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "local_decks" */
export type Local_Decks_Var_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Local_Decks_Var_Samp_Fields = {
  __typename?: 'local_decks_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "local_decks" */
export type Local_Decks_Var_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Local_Decks_Variance_Fields = {
  __typename?: 'local_decks_variance_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "local_decks" */
export type Local_Decks_Variance_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "base_decks" */
  delete_base_decks?: Maybe<Base_Decks_Mutation_Response>;
  /** delete data from the table: "campaign" */
  delete_campaign?: Maybe<Campaign_Mutation_Response>;
  /** delete data from the table: "campaign_access" */
  delete_campaign_access?: Maybe<Campaign_Access_Mutation_Response>;
  /** delete single row from the table: "campaign_access" */
  delete_campaign_access_by_pk?: Maybe<Campaign_Access>;
  /** delete single row from the table: "campaign" */
  delete_campaign_by_pk?: Maybe<Campaign>;
  /** delete data from the table: "campaign_deck" */
  delete_campaign_deck?: Maybe<Campaign_Deck_Mutation_Response>;
  /** delete single row from the table: "campaign_deck" */
  delete_campaign_deck_by_pk?: Maybe<Campaign_Deck>;
  /** delete data from the table: "campaign_guide" */
  delete_campaign_guide?: Maybe<Campaign_Guide_Mutation_Response>;
  /** delete data from the table: "campaign_investigator" */
  delete_campaign_investigator?: Maybe<Campaign_Investigator_Mutation_Response>;
  /** delete single row from the table: "campaign_investigator" */
  delete_campaign_investigator_by_pk?: Maybe<Campaign_Investigator>;
  /** delete data from the table: "card" */
  delete_card?: Maybe<Card_Mutation_Response>;
  /** delete single row from the table: "card" */
  delete_card_by_pk?: Maybe<Card>;
  /** delete data from the table: "card_pack" */
  delete_card_pack?: Maybe<Card_Pack_Mutation_Response>;
  /** delete single row from the table: "card_pack" */
  delete_card_pack_by_pk?: Maybe<Card_Pack>;
  /** delete data from the table: "card_text" */
  delete_card_text?: Maybe<Card_Text_Mutation_Response>;
  /** delete single row from the table: "card_text" */
  delete_card_text_by_pk?: Maybe<Card_Text>;
  /** delete data from the table: "chaos_bag_result" */
  delete_chaos_bag_result?: Maybe<Chaos_Bag_Result_Mutation_Response>;
  /** delete single row from the table: "chaos_bag_result" */
  delete_chaos_bag_result_by_pk?: Maybe<Chaos_Bag_Result>;
  /** delete data from the table: "faq" */
  delete_faq?: Maybe<Faq_Mutation_Response>;
  /** delete single row from the table: "faq" */
  delete_faq_by_pk?: Maybe<Faq>;
  /** delete data from the table: "faq_text" */
  delete_faq_text?: Maybe<Faq_Text_Mutation_Response>;
  /** delete single row from the table: "faq_text" */
  delete_faq_text_by_pk?: Maybe<Faq_Text>;
  /** delete data from the table: "friend_status" */
  delete_friend_status?: Maybe<Friend_Status_Mutation_Response>;
  /** delete single row from the table: "friend_status" */
  delete_friend_status_by_pk?: Maybe<Friend_Status>;
  /** delete data from the table: "friend_status_type" */
  delete_friend_status_type?: Maybe<Friend_Status_Type_Mutation_Response>;
  /** delete single row from the table: "friend_status_type" */
  delete_friend_status_type_by_pk?: Maybe<Friend_Status_Type>;
  /** delete data from the table: "guide_achievement" */
  delete_guide_achievement?: Maybe<Guide_Achievement_Mutation_Response>;
  /** delete single row from the table: "guide_achievement" */
  delete_guide_achievement_by_pk?: Maybe<Guide_Achievement>;
  /** delete data from the table: "guide_input" */
  delete_guide_input?: Maybe<Guide_Input_Mutation_Response>;
  /** delete single row from the table: "guide_input" */
  delete_guide_input_by_pk?: Maybe<Guide_Input>;
  /** delete data from the table: "investigator_data" */
  delete_investigator_data?: Maybe<Investigator_Data_Mutation_Response>;
  /** delete single row from the table: "investigator_data" */
  delete_investigator_data_by_pk?: Maybe<Investigator_Data>;
  /** delete data from the table: "latest_decks" */
  delete_latest_decks?: Maybe<Latest_Decks_Mutation_Response>;
  /** delete data from the table: "local_decks" */
  delete_local_decks?: Maybe<Local_Decks_Mutation_Response>;
  /** delete data from the table: "user_campaigns" */
  delete_user_campaigns?: Maybe<User_Campaigns_Mutation_Response>;
  /** delete data from the table: "user_flag" */
  delete_user_flag?: Maybe<User_Flag_Mutation_Response>;
  /** delete single row from the table: "user_flag" */
  delete_user_flag_by_pk?: Maybe<User_Flag>;
  /** delete data from the table: "user_flag_type" */
  delete_user_flag_type?: Maybe<User_Flag_Type_Mutation_Response>;
  /** delete single row from the table: "user_flag_type" */
  delete_user_flag_type_by_pk?: Maybe<User_Flag_Type>;
  /** delete data from the table: "user_friends" */
  delete_user_friends?: Maybe<User_Friends_Mutation_Response>;
  /** delete data from the table: "user_received_friend_requests" */
  delete_user_received_friend_requests?: Maybe<User_Received_Friend_Requests_Mutation_Response>;
  /** delete data from the table: "user_sent_friend_requests" */
  delete_user_sent_friend_requests?: Maybe<User_Sent_Friend_Requests_Mutation_Response>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "base_decks" */
  insert_base_decks?: Maybe<Base_Decks_Mutation_Response>;
  /** insert a single row into the table: "base_decks" */
  insert_base_decks_one?: Maybe<Base_Decks>;
  /** insert data into the table: "campaign" */
  insert_campaign?: Maybe<Campaign_Mutation_Response>;
  /** insert data into the table: "campaign_access" */
  insert_campaign_access?: Maybe<Campaign_Access_Mutation_Response>;
  /** insert a single row into the table: "campaign_access" */
  insert_campaign_access_one?: Maybe<Campaign_Access>;
  /** insert data into the table: "campaign_deck" */
  insert_campaign_deck?: Maybe<Campaign_Deck_Mutation_Response>;
  /** insert a single row into the table: "campaign_deck" */
  insert_campaign_deck_one?: Maybe<Campaign_Deck>;
  /** insert data into the table: "campaign_guide" */
  insert_campaign_guide?: Maybe<Campaign_Guide_Mutation_Response>;
  /** insert a single row into the table: "campaign_guide" */
  insert_campaign_guide_one?: Maybe<Campaign_Guide>;
  /** insert data into the table: "campaign_investigator" */
  insert_campaign_investigator?: Maybe<Campaign_Investigator_Mutation_Response>;
  /** insert a single row into the table: "campaign_investigator" */
  insert_campaign_investigator_one?: Maybe<Campaign_Investigator>;
  /** insert a single row into the table: "campaign" */
  insert_campaign_one?: Maybe<Campaign>;
  /** insert data into the table: "card" */
  insert_card?: Maybe<Card_Mutation_Response>;
  /** insert a single row into the table: "card" */
  insert_card_one?: Maybe<Card>;
  /** insert data into the table: "card_pack" */
  insert_card_pack?: Maybe<Card_Pack_Mutation_Response>;
  /** insert a single row into the table: "card_pack" */
  insert_card_pack_one?: Maybe<Card_Pack>;
  /** insert data into the table: "card_text" */
  insert_card_text?: Maybe<Card_Text_Mutation_Response>;
  /** insert a single row into the table: "card_text" */
  insert_card_text_one?: Maybe<Card_Text>;
  /** insert data into the table: "chaos_bag_result" */
  insert_chaos_bag_result?: Maybe<Chaos_Bag_Result_Mutation_Response>;
  /** insert a single row into the table: "chaos_bag_result" */
  insert_chaos_bag_result_one?: Maybe<Chaos_Bag_Result>;
  /** insert data into the table: "faq" */
  insert_faq?: Maybe<Faq_Mutation_Response>;
  /** insert a single row into the table: "faq" */
  insert_faq_one?: Maybe<Faq>;
  /** insert data into the table: "faq_text" */
  insert_faq_text?: Maybe<Faq_Text_Mutation_Response>;
  /** insert a single row into the table: "faq_text" */
  insert_faq_text_one?: Maybe<Faq_Text>;
  /** insert data into the table: "friend_status" */
  insert_friend_status?: Maybe<Friend_Status_Mutation_Response>;
  /** insert a single row into the table: "friend_status" */
  insert_friend_status_one?: Maybe<Friend_Status>;
  /** insert data into the table: "friend_status_type" */
  insert_friend_status_type?: Maybe<Friend_Status_Type_Mutation_Response>;
  /** insert a single row into the table: "friend_status_type" */
  insert_friend_status_type_one?: Maybe<Friend_Status_Type>;
  /** insert data into the table: "guide_achievement" */
  insert_guide_achievement?: Maybe<Guide_Achievement_Mutation_Response>;
  /** insert a single row into the table: "guide_achievement" */
  insert_guide_achievement_one?: Maybe<Guide_Achievement>;
  /** insert data into the table: "guide_input" */
  insert_guide_input?: Maybe<Guide_Input_Mutation_Response>;
  /** insert a single row into the table: "guide_input" */
  insert_guide_input_one?: Maybe<Guide_Input>;
  /** insert data into the table: "investigator_data" */
  insert_investigator_data?: Maybe<Investigator_Data_Mutation_Response>;
  /** insert a single row into the table: "investigator_data" */
  insert_investigator_data_one?: Maybe<Investigator_Data>;
  /** insert data into the table: "latest_decks" */
  insert_latest_decks?: Maybe<Latest_Decks_Mutation_Response>;
  /** insert a single row into the table: "latest_decks" */
  insert_latest_decks_one?: Maybe<Latest_Decks>;
  /** insert data into the table: "local_decks" */
  insert_local_decks?: Maybe<Local_Decks_Mutation_Response>;
  /** insert a single row into the table: "local_decks" */
  insert_local_decks_one?: Maybe<Local_Decks>;
  /** insert data into the table: "user_campaigns" */
  insert_user_campaigns?: Maybe<User_Campaigns_Mutation_Response>;
  /** insert a single row into the table: "user_campaigns" */
  insert_user_campaigns_one?: Maybe<User_Campaigns>;
  /** insert data into the table: "user_flag" */
  insert_user_flag?: Maybe<User_Flag_Mutation_Response>;
  /** insert a single row into the table: "user_flag" */
  insert_user_flag_one?: Maybe<User_Flag>;
  /** insert data into the table: "user_flag_type" */
  insert_user_flag_type?: Maybe<User_Flag_Type_Mutation_Response>;
  /** insert a single row into the table: "user_flag_type" */
  insert_user_flag_type_one?: Maybe<User_Flag_Type>;
  /** insert data into the table: "user_friends" */
  insert_user_friends?: Maybe<User_Friends_Mutation_Response>;
  /** insert a single row into the table: "user_friends" */
  insert_user_friends_one?: Maybe<User_Friends>;
  /** insert data into the table: "user_received_friend_requests" */
  insert_user_received_friend_requests?: Maybe<User_Received_Friend_Requests_Mutation_Response>;
  /** insert a single row into the table: "user_received_friend_requests" */
  insert_user_received_friend_requests_one?: Maybe<User_Received_Friend_Requests>;
  /** insert data into the table: "user_sent_friend_requests" */
  insert_user_sent_friend_requests?: Maybe<User_Sent_Friend_Requests_Mutation_Response>;
  /** insert a single row into the table: "user_sent_friend_requests" */
  insert_user_sent_friend_requests_one?: Maybe<User_Sent_Friend_Requests>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "base_decks" */
  update_base_decks?: Maybe<Base_Decks_Mutation_Response>;
  /** update data of the table: "campaign" */
  update_campaign?: Maybe<Campaign_Mutation_Response>;
  /** update data of the table: "campaign_access" */
  update_campaign_access?: Maybe<Campaign_Access_Mutation_Response>;
  /** update single row of the table: "campaign_access" */
  update_campaign_access_by_pk?: Maybe<Campaign_Access>;
  /** update single row of the table: "campaign" */
  update_campaign_by_pk?: Maybe<Campaign>;
  /** update data of the table: "campaign_deck" */
  update_campaign_deck?: Maybe<Campaign_Deck_Mutation_Response>;
  /** update single row of the table: "campaign_deck" */
  update_campaign_deck_by_pk?: Maybe<Campaign_Deck>;
  /** update data of the table: "campaign_guide" */
  update_campaign_guide?: Maybe<Campaign_Guide_Mutation_Response>;
  /** update data of the table: "campaign_investigator" */
  update_campaign_investigator?: Maybe<Campaign_Investigator_Mutation_Response>;
  /** update single row of the table: "campaign_investigator" */
  update_campaign_investigator_by_pk?: Maybe<Campaign_Investigator>;
  /** update data of the table: "card" */
  update_card?: Maybe<Card_Mutation_Response>;
  /** update single row of the table: "card" */
  update_card_by_pk?: Maybe<Card>;
  /** update data of the table: "card_pack" */
  update_card_pack?: Maybe<Card_Pack_Mutation_Response>;
  /** update single row of the table: "card_pack" */
  update_card_pack_by_pk?: Maybe<Card_Pack>;
  /** update data of the table: "card_text" */
  update_card_text?: Maybe<Card_Text_Mutation_Response>;
  /** update single row of the table: "card_text" */
  update_card_text_by_pk?: Maybe<Card_Text>;
  /** update data of the table: "chaos_bag_result" */
  update_chaos_bag_result?: Maybe<Chaos_Bag_Result_Mutation_Response>;
  /** update single row of the table: "chaos_bag_result" */
  update_chaos_bag_result_by_pk?: Maybe<Chaos_Bag_Result>;
  /** update data of the table: "faq" */
  update_faq?: Maybe<Faq_Mutation_Response>;
  /** update single row of the table: "faq" */
  update_faq_by_pk?: Maybe<Faq>;
  /** update data of the table: "faq_text" */
  update_faq_text?: Maybe<Faq_Text_Mutation_Response>;
  /** update single row of the table: "faq_text" */
  update_faq_text_by_pk?: Maybe<Faq_Text>;
  /** update data of the table: "friend_status" */
  update_friend_status?: Maybe<Friend_Status_Mutation_Response>;
  /** update single row of the table: "friend_status" */
  update_friend_status_by_pk?: Maybe<Friend_Status>;
  /** update data of the table: "friend_status_type" */
  update_friend_status_type?: Maybe<Friend_Status_Type_Mutation_Response>;
  /** update single row of the table: "friend_status_type" */
  update_friend_status_type_by_pk?: Maybe<Friend_Status_Type>;
  /** update data of the table: "guide_achievement" */
  update_guide_achievement?: Maybe<Guide_Achievement_Mutation_Response>;
  /** update single row of the table: "guide_achievement" */
  update_guide_achievement_by_pk?: Maybe<Guide_Achievement>;
  /** update data of the table: "guide_input" */
  update_guide_input?: Maybe<Guide_Input_Mutation_Response>;
  /** update single row of the table: "guide_input" */
  update_guide_input_by_pk?: Maybe<Guide_Input>;
  /** update data of the table: "investigator_data" */
  update_investigator_data?: Maybe<Investigator_Data_Mutation_Response>;
  /** update single row of the table: "investigator_data" */
  update_investigator_data_by_pk?: Maybe<Investigator_Data>;
  /** update data of the table: "latest_decks" */
  update_latest_decks?: Maybe<Latest_Decks_Mutation_Response>;
  /** update data of the table: "local_decks" */
  update_local_decks?: Maybe<Local_Decks_Mutation_Response>;
  /** update data of the table: "user_campaigns" */
  update_user_campaigns?: Maybe<User_Campaigns_Mutation_Response>;
  /** update data of the table: "user_flag" */
  update_user_flag?: Maybe<User_Flag_Mutation_Response>;
  /** update single row of the table: "user_flag" */
  update_user_flag_by_pk?: Maybe<User_Flag>;
  /** update data of the table: "user_flag_type" */
  update_user_flag_type?: Maybe<User_Flag_Type_Mutation_Response>;
  /** update single row of the table: "user_flag_type" */
  update_user_flag_type_by_pk?: Maybe<User_Flag_Type>;
  /** update data of the table: "user_friends" */
  update_user_friends?: Maybe<User_Friends_Mutation_Response>;
  /** update data of the table: "user_received_friend_requests" */
  update_user_received_friend_requests?: Maybe<User_Received_Friend_Requests_Mutation_Response>;
  /** update data of the table: "user_sent_friend_requests" */
  update_user_sent_friend_requests?: Maybe<User_Sent_Friend_Requests_Mutation_Response>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootDelete_Base_DecksArgs = {
  where: Base_Decks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CampaignArgs = {
  where: Campaign_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_AccessArgs = {
  where: Campaign_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Access_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Campaign_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Campaign_DeckArgs = {
  where: Campaign_Deck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Deck_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Campaign_GuideArgs = {
  where: Campaign_Guide_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_InvestigatorArgs = {
  where: Campaign_Investigator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Investigator_By_PkArgs = {
  campaign_id: Scalars['Int'];
  investigator: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CardArgs = {
  where: Card_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Card_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Card_PackArgs = {
  where: Card_Pack_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Card_Pack_By_PkArgs = {
  code: Scalars['String'];
  locale: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Card_TextArgs = {
  where: Card_Text_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Card_Text_By_PkArgs = {
  code: Scalars['String'];
  locale: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Chaos_Bag_ResultArgs = {
  where: Chaos_Bag_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chaos_Bag_Result_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FaqArgs = {
  where: Faq_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Faq_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Faq_TextArgs = {
  where: Faq_Text_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Faq_Text_By_PkArgs = {
  code: Scalars['String'];
  locale: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Friend_StatusArgs = {
  where: Friend_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Friend_Status_By_PkArgs = {
  user_id_a: Scalars['String'];
  user_id_b: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Friend_Status_TypeArgs = {
  where: Friend_Status_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Friend_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Guide_AchievementArgs = {
  where: Guide_Achievement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Guide_Achievement_By_PkArgs = {
  campaign_id: Scalars['Int'];
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Guide_InputArgs = {
  where: Guide_Input_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Guide_Input_By_PkArgs = {
  campaign_id: Scalars['Int'];
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Investigator_DataArgs = {
  where: Investigator_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Investigator_Data_By_PkArgs = {
  campaign_id: Scalars['Int'];
  investigator: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Latest_DecksArgs = {
  where: Latest_Decks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Local_DecksArgs = {
  where: Local_Decks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_CampaignsArgs = {
  where: User_Campaigns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_FlagArgs = {
  where: User_Flag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Flag_By_PkArgs = {
  flag: User_Flag_Type_Enum;
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_Flag_TypeArgs = {
  where: User_Flag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Flag_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_FriendsArgs = {
  where: User_Friends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Received_Friend_RequestsArgs = {
  where: User_Received_Friend_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Sent_Friend_RequestsArgs = {
  where: User_Sent_Friend_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Base_DecksArgs = {
  objects: Array<Base_Decks_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Decks_OneArgs = {
  object: Base_Decks_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CampaignArgs = {
  objects: Array<Campaign_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_AccessArgs = {
  objects: Array<Campaign_Access_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Access_OneArgs = {
  object: Campaign_Access_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_DeckArgs = {
  objects: Array<Campaign_Deck_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Deck_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Deck_OneArgs = {
  object: Campaign_Deck_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Deck_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_GuideArgs = {
  objects: Array<Campaign_Guide_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Guide_OneArgs = {
  object: Campaign_Guide_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_InvestigatorArgs = {
  objects: Array<Campaign_Investigator_Insert_Input>;
  on_conflict?: InputMaybe<Campaign_Investigator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Investigator_OneArgs = {
  object: Campaign_Investigator_Insert_Input;
  on_conflict?: InputMaybe<Campaign_Investigator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_OneArgs = {
  object: Campaign_Insert_Input;
  on_conflict?: InputMaybe<Campaign_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CardArgs = {
  objects: Array<Card_Insert_Input>;
  on_conflict?: InputMaybe<Card_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Card_OneArgs = {
  object: Card_Insert_Input;
  on_conflict?: InputMaybe<Card_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Card_PackArgs = {
  objects: Array<Card_Pack_Insert_Input>;
  on_conflict?: InputMaybe<Card_Pack_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Card_Pack_OneArgs = {
  object: Card_Pack_Insert_Input;
  on_conflict?: InputMaybe<Card_Pack_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Card_TextArgs = {
  objects: Array<Card_Text_Insert_Input>;
  on_conflict?: InputMaybe<Card_Text_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Card_Text_OneArgs = {
  object: Card_Text_Insert_Input;
  on_conflict?: InputMaybe<Card_Text_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chaos_Bag_ResultArgs = {
  objects: Array<Chaos_Bag_Result_Insert_Input>;
  on_conflict?: InputMaybe<Chaos_Bag_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chaos_Bag_Result_OneArgs = {
  object: Chaos_Bag_Result_Insert_Input;
  on_conflict?: InputMaybe<Chaos_Bag_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FaqArgs = {
  objects: Array<Faq_Insert_Input>;
  on_conflict?: InputMaybe<Faq_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faq_OneArgs = {
  object: Faq_Insert_Input;
  on_conflict?: InputMaybe<Faq_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faq_TextArgs = {
  objects: Array<Faq_Text_Insert_Input>;
  on_conflict?: InputMaybe<Faq_Text_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faq_Text_OneArgs = {
  object: Faq_Text_Insert_Input;
  on_conflict?: InputMaybe<Faq_Text_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friend_StatusArgs = {
  objects: Array<Friend_Status_Insert_Input>;
  on_conflict?: InputMaybe<Friend_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friend_Status_OneArgs = {
  object: Friend_Status_Insert_Input;
  on_conflict?: InputMaybe<Friend_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friend_Status_TypeArgs = {
  objects: Array<Friend_Status_Type_Insert_Input>;
  on_conflict?: InputMaybe<Friend_Status_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friend_Status_Type_OneArgs = {
  object: Friend_Status_Type_Insert_Input;
  on_conflict?: InputMaybe<Friend_Status_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Guide_AchievementArgs = {
  objects: Array<Guide_Achievement_Insert_Input>;
  on_conflict?: InputMaybe<Guide_Achievement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Guide_Achievement_OneArgs = {
  object: Guide_Achievement_Insert_Input;
  on_conflict?: InputMaybe<Guide_Achievement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Guide_InputArgs = {
  objects: Array<Guide_Input_Insert_Input>;
  on_conflict?: InputMaybe<Guide_Input_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Guide_Input_OneArgs = {
  object: Guide_Input_Insert_Input;
  on_conflict?: InputMaybe<Guide_Input_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investigator_DataArgs = {
  objects: Array<Investigator_Data_Insert_Input>;
  on_conflict?: InputMaybe<Investigator_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investigator_Data_OneArgs = {
  object: Investigator_Data_Insert_Input;
  on_conflict?: InputMaybe<Investigator_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Latest_DecksArgs = {
  objects: Array<Latest_Decks_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Latest_Decks_OneArgs = {
  object: Latest_Decks_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Local_DecksArgs = {
  objects: Array<Local_Decks_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Local_Decks_OneArgs = {
  object: Local_Decks_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_User_CampaignsArgs = {
  objects: Array<User_Campaigns_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_User_Campaigns_OneArgs = {
  object: User_Campaigns_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_User_FlagArgs = {
  objects: Array<User_Flag_Insert_Input>;
  on_conflict?: InputMaybe<User_Flag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Flag_OneArgs = {
  object: User_Flag_Insert_Input;
  on_conflict?: InputMaybe<User_Flag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Flag_TypeArgs = {
  objects: Array<User_Flag_Type_Insert_Input>;
  on_conflict?: InputMaybe<User_Flag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Flag_Type_OneArgs = {
  object: User_Flag_Type_Insert_Input;
  on_conflict?: InputMaybe<User_Flag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_FriendsArgs = {
  objects: Array<User_Friends_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_User_Friends_OneArgs = {
  object: User_Friends_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_User_Received_Friend_RequestsArgs = {
  objects: Array<User_Received_Friend_Requests_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_User_Received_Friend_Requests_OneArgs = {
  object: User_Received_Friend_Requests_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_User_Sent_Friend_RequestsArgs = {
  objects: Array<User_Sent_Friend_Requests_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_User_Sent_Friend_Requests_OneArgs = {
  object: User_Sent_Friend_Requests_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_DecksArgs = {
  _inc?: InputMaybe<Base_Decks_Inc_Input>;
  _set?: InputMaybe<Base_Decks_Set_Input>;
  where: Base_Decks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CampaignArgs = {
  _append?: InputMaybe<Campaign_Append_Input>;
  _delete_at_path?: InputMaybe<Campaign_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Campaign_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Campaign_Delete_Key_Input>;
  _inc?: InputMaybe<Campaign_Inc_Input>;
  _prepend?: InputMaybe<Campaign_Prepend_Input>;
  _set?: InputMaybe<Campaign_Set_Input>;
  where: Campaign_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_AccessArgs = {
  _inc?: InputMaybe<Campaign_Access_Inc_Input>;
  _set?: InputMaybe<Campaign_Access_Set_Input>;
  where: Campaign_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Access_By_PkArgs = {
  _inc?: InputMaybe<Campaign_Access_Inc_Input>;
  _set?: InputMaybe<Campaign_Access_Set_Input>;
  pk_columns: Campaign_Access_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_By_PkArgs = {
  _append?: InputMaybe<Campaign_Append_Input>;
  _delete_at_path?: InputMaybe<Campaign_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Campaign_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Campaign_Delete_Key_Input>;
  _inc?: InputMaybe<Campaign_Inc_Input>;
  _prepend?: InputMaybe<Campaign_Prepend_Input>;
  _set?: InputMaybe<Campaign_Set_Input>;
  pk_columns: Campaign_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_DeckArgs = {
  _append?: InputMaybe<Campaign_Deck_Append_Input>;
  _delete_at_path?: InputMaybe<Campaign_Deck_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Campaign_Deck_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Campaign_Deck_Delete_Key_Input>;
  _inc?: InputMaybe<Campaign_Deck_Inc_Input>;
  _prepend?: InputMaybe<Campaign_Deck_Prepend_Input>;
  _set?: InputMaybe<Campaign_Deck_Set_Input>;
  where: Campaign_Deck_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Deck_By_PkArgs = {
  _append?: InputMaybe<Campaign_Deck_Append_Input>;
  _delete_at_path?: InputMaybe<Campaign_Deck_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Campaign_Deck_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Campaign_Deck_Delete_Key_Input>;
  _inc?: InputMaybe<Campaign_Deck_Inc_Input>;
  _prepend?: InputMaybe<Campaign_Deck_Prepend_Input>;
  _set?: InputMaybe<Campaign_Deck_Set_Input>;
  pk_columns: Campaign_Deck_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_GuideArgs = {
  _inc?: InputMaybe<Campaign_Guide_Inc_Input>;
  _set?: InputMaybe<Campaign_Guide_Set_Input>;
  where: Campaign_Guide_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_InvestigatorArgs = {
  _inc?: InputMaybe<Campaign_Investigator_Inc_Input>;
  _set?: InputMaybe<Campaign_Investigator_Set_Input>;
  where: Campaign_Investigator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Investigator_By_PkArgs = {
  _inc?: InputMaybe<Campaign_Investigator_Inc_Input>;
  _set?: InputMaybe<Campaign_Investigator_Set_Input>;
  pk_columns: Campaign_Investigator_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CardArgs = {
  _inc?: InputMaybe<Card_Inc_Input>;
  _set?: InputMaybe<Card_Set_Input>;
  where: Card_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Card_By_PkArgs = {
  _inc?: InputMaybe<Card_Inc_Input>;
  _set?: InputMaybe<Card_Set_Input>;
  pk_columns: Card_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Card_PackArgs = {
  _set?: InputMaybe<Card_Pack_Set_Input>;
  where: Card_Pack_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Card_Pack_By_PkArgs = {
  _set?: InputMaybe<Card_Pack_Set_Input>;
  pk_columns: Card_Pack_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Card_TextArgs = {
  _set?: InputMaybe<Card_Text_Set_Input>;
  where: Card_Text_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Card_Text_By_PkArgs = {
  _set?: InputMaybe<Card_Text_Set_Input>;
  pk_columns: Card_Text_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chaos_Bag_ResultArgs = {
  _append?: InputMaybe<Chaos_Bag_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Chaos_Bag_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chaos_Bag_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chaos_Bag_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Chaos_Bag_Result_Inc_Input>;
  _prepend?: InputMaybe<Chaos_Bag_Result_Prepend_Input>;
  _set?: InputMaybe<Chaos_Bag_Result_Set_Input>;
  where: Chaos_Bag_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chaos_Bag_Result_By_PkArgs = {
  _append?: InputMaybe<Chaos_Bag_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Chaos_Bag_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chaos_Bag_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chaos_Bag_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Chaos_Bag_Result_Inc_Input>;
  _prepend?: InputMaybe<Chaos_Bag_Result_Prepend_Input>;
  _set?: InputMaybe<Chaos_Bag_Result_Set_Input>;
  pk_columns: Chaos_Bag_Result_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FaqArgs = {
  _set?: InputMaybe<Faq_Set_Input>;
  where: Faq_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_By_PkArgs = {
  _set?: InputMaybe<Faq_Set_Input>;
  pk_columns: Faq_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_TextArgs = {
  _set?: InputMaybe<Faq_Text_Set_Input>;
  where: Faq_Text_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_Text_By_PkArgs = {
  _set?: InputMaybe<Faq_Text_Set_Input>;
  pk_columns: Faq_Text_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Friend_StatusArgs = {
  _set?: InputMaybe<Friend_Status_Set_Input>;
  where: Friend_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Friend_Status_By_PkArgs = {
  _set?: InputMaybe<Friend_Status_Set_Input>;
  pk_columns: Friend_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Friend_Status_TypeArgs = {
  _set?: InputMaybe<Friend_Status_Type_Set_Input>;
  where: Friend_Status_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Friend_Status_Type_By_PkArgs = {
  _set?: InputMaybe<Friend_Status_Type_Set_Input>;
  pk_columns: Friend_Status_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Guide_AchievementArgs = {
  _inc?: InputMaybe<Guide_Achievement_Inc_Input>;
  _set?: InputMaybe<Guide_Achievement_Set_Input>;
  where: Guide_Achievement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Guide_Achievement_By_PkArgs = {
  _inc?: InputMaybe<Guide_Achievement_Inc_Input>;
  _set?: InputMaybe<Guide_Achievement_Set_Input>;
  pk_columns: Guide_Achievement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Guide_InputArgs = {
  _append?: InputMaybe<Guide_Input_Append_Input>;
  _delete_at_path?: InputMaybe<Guide_Input_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Guide_Input_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Guide_Input_Delete_Key_Input>;
  _inc?: InputMaybe<Guide_Input_Inc_Input>;
  _prepend?: InputMaybe<Guide_Input_Prepend_Input>;
  _set?: InputMaybe<Guide_Input_Set_Input>;
  where: Guide_Input_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Guide_Input_By_PkArgs = {
  _append?: InputMaybe<Guide_Input_Append_Input>;
  _delete_at_path?: InputMaybe<Guide_Input_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Guide_Input_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Guide_Input_Delete_Key_Input>;
  _inc?: InputMaybe<Guide_Input_Inc_Input>;
  _prepend?: InputMaybe<Guide_Input_Prepend_Input>;
  _set?: InputMaybe<Guide_Input_Set_Input>;
  pk_columns: Guide_Input_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Investigator_DataArgs = {
  _append?: InputMaybe<Investigator_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Investigator_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investigator_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investigator_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Investigator_Data_Inc_Input>;
  _prepend?: InputMaybe<Investigator_Data_Prepend_Input>;
  _set?: InputMaybe<Investigator_Data_Set_Input>;
  where: Investigator_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Investigator_Data_By_PkArgs = {
  _append?: InputMaybe<Investigator_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Investigator_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investigator_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investigator_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Investigator_Data_Inc_Input>;
  _prepend?: InputMaybe<Investigator_Data_Prepend_Input>;
  _set?: InputMaybe<Investigator_Data_Set_Input>;
  pk_columns: Investigator_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Latest_DecksArgs = {
  _inc?: InputMaybe<Latest_Decks_Inc_Input>;
  _set?: InputMaybe<Latest_Decks_Set_Input>;
  where: Latest_Decks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Local_DecksArgs = {
  _inc?: InputMaybe<Local_Decks_Inc_Input>;
  _set?: InputMaybe<Local_Decks_Set_Input>;
  where: Local_Decks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_CampaignsArgs = {
  _inc?: InputMaybe<User_Campaigns_Inc_Input>;
  _set?: InputMaybe<User_Campaigns_Set_Input>;
  where: User_Campaigns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_FlagArgs = {
  _set?: InputMaybe<User_Flag_Set_Input>;
  where: User_Flag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Flag_By_PkArgs = {
  _set?: InputMaybe<User_Flag_Set_Input>;
  pk_columns: User_Flag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Flag_TypeArgs = {
  _set?: InputMaybe<User_Flag_Type_Set_Input>;
  where: User_Flag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Flag_Type_By_PkArgs = {
  _set?: InputMaybe<User_Flag_Type_Set_Input>;
  pk_columns: User_Flag_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_FriendsArgs = {
  _set?: InputMaybe<User_Friends_Set_Input>;
  where: User_Friends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Received_Friend_RequestsArgs = {
  _set?: InputMaybe<User_Received_Friend_Requests_Set_Input>;
  where: User_Received_Friend_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Sent_Friend_RequestsArgs = {
  _set?: InputMaybe<User_Sent_Friend_Requests_Set_Input>;
  where: User_Sent_Friend_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  base_decks: Array<Base_Decks>;
  /** An aggregate relationship */
  base_decks_aggregate: Base_Decks_Aggregate;
  /** fetch data from the table: "campaign" */
  campaign: Array<Campaign>;
  /** fetch data from the table: "campaign_access" */
  campaign_access: Array<Campaign_Access>;
  /** fetch aggregated fields from the table: "campaign_access" */
  campaign_access_aggregate: Campaign_Access_Aggregate;
  /** fetch data from the table: "campaign_access" using primary key columns */
  campaign_access_by_pk?: Maybe<Campaign_Access>;
  /** fetch aggregated fields from the table: "campaign" */
  campaign_aggregate: Campaign_Aggregate;
  /** fetch data from the table: "campaign" using primary key columns */
  campaign_by_pk?: Maybe<Campaign>;
  /** fetch data from the table: "campaign_deck" */
  campaign_deck: Array<Campaign_Deck>;
  /** fetch aggregated fields from the table: "campaign_deck" */
  campaign_deck_aggregate: Campaign_Deck_Aggregate;
  /** fetch data from the table: "campaign_deck" using primary key columns */
  campaign_deck_by_pk?: Maybe<Campaign_Deck>;
  /** fetch data from the table: "campaign_guide" */
  campaign_guide: Array<Campaign_Guide>;
  /** fetch aggregated fields from the table: "campaign_guide" */
  campaign_guide_aggregate: Campaign_Guide_Aggregate;
  /** fetch data from the table: "campaign_investigator" */
  campaign_investigator: Array<Campaign_Investigator>;
  /** fetch aggregated fields from the table: "campaign_investigator" */
  campaign_investigator_aggregate: Campaign_Investigator_Aggregate;
  /** fetch data from the table: "campaign_investigator" using primary key columns */
  campaign_investigator_by_pk?: Maybe<Campaign_Investigator>;
  /** fetch data from the table: "card" */
  card: Array<Card>;
  /** fetch aggregated fields from the table: "card" */
  card_aggregate: Card_Aggregate;
  /** fetch data from the table: "card" using primary key columns */
  card_by_pk?: Maybe<Card>;
  /** fetch data from the table: "card_pack" */
  card_pack: Array<Card_Pack>;
  /** fetch aggregated fields from the table: "card_pack" */
  card_pack_aggregate: Card_Pack_Aggregate;
  /** fetch data from the table: "card_pack" using primary key columns */
  card_pack_by_pk?: Maybe<Card_Pack>;
  /** fetch data from the table: "card_text" */
  card_text: Array<Card_Text>;
  /** fetch aggregated fields from the table: "card_text" */
  card_text_aggregate: Card_Text_Aggregate;
  /** fetch data from the table: "card_text" using primary key columns */
  card_text_by_pk?: Maybe<Card_Text>;
  /** An array relationship */
  chaos_bag_result: Array<Chaos_Bag_Result>;
  /** An aggregate relationship */
  chaos_bag_result_aggregate: Chaos_Bag_Result_Aggregate;
  /** fetch data from the table: "chaos_bag_result" using primary key columns */
  chaos_bag_result_by_pk?: Maybe<Chaos_Bag_Result>;
  /** fetch data from the table: "faq" */
  faq: Array<Faq>;
  /** fetch aggregated fields from the table: "faq" */
  faq_aggregate: Faq_Aggregate;
  /** fetch data from the table: "faq" using primary key columns */
  faq_by_pk?: Maybe<Faq>;
  /** fetch data from the table: "faq_text" */
  faq_text: Array<Faq_Text>;
  /** fetch aggregated fields from the table: "faq_text" */
  faq_text_aggregate: Faq_Text_Aggregate;
  /** fetch data from the table: "faq_text" using primary key columns */
  faq_text_by_pk?: Maybe<Faq_Text>;
  /** fetch data from the table: "friend_status" */
  friend_status: Array<Friend_Status>;
  /** fetch aggregated fields from the table: "friend_status" */
  friend_status_aggregate: Friend_Status_Aggregate;
  /** fetch data from the table: "friend_status" using primary key columns */
  friend_status_by_pk?: Maybe<Friend_Status>;
  /** fetch data from the table: "friend_status_type" */
  friend_status_type: Array<Friend_Status_Type>;
  /** fetch aggregated fields from the table: "friend_status_type" */
  friend_status_type_aggregate: Friend_Status_Type_Aggregate;
  /** fetch data from the table: "friend_status_type" using primary key columns */
  friend_status_type_by_pk?: Maybe<Friend_Status_Type>;
  /** fetch data from the table: "guide_achievement" */
  guide_achievement: Array<Guide_Achievement>;
  /** fetch aggregated fields from the table: "guide_achievement" */
  guide_achievement_aggregate: Guide_Achievement_Aggregate;
  /** fetch data from the table: "guide_achievement" using primary key columns */
  guide_achievement_by_pk?: Maybe<Guide_Achievement>;
  /** fetch data from the table: "guide_input" */
  guide_input: Array<Guide_Input>;
  /** fetch aggregated fields from the table: "guide_input" */
  guide_input_aggregate: Guide_Input_Aggregate;
  /** fetch data from the table: "guide_input" using primary key columns */
  guide_input_by_pk?: Maybe<Guide_Input>;
  /** An array relationship */
  investigator_data: Array<Investigator_Data>;
  /** An aggregate relationship */
  investigator_data_aggregate: Investigator_Data_Aggregate;
  /** fetch data from the table: "investigator_data" using primary key columns */
  investigator_data_by_pk?: Maybe<Investigator_Data>;
  /** An array relationship */
  latest_decks: Array<Latest_Decks>;
  /** An aggregate relationship */
  latest_decks_aggregate: Latest_Decks_Aggregate;
  /** An array relationship */
  local_decks: Array<Local_Decks>;
  /** An aggregate relationship */
  local_decks_aggregate: Local_Decks_Aggregate;
  /** fetch data from the table: "user_campaigns" */
  user_campaigns: Array<User_Campaigns>;
  /** fetch aggregated fields from the table: "user_campaigns" */
  user_campaigns_aggregate: User_Campaigns_Aggregate;
  /** fetch data from the table: "user_flag" */
  user_flag: Array<User_Flag>;
  /** fetch aggregated fields from the table: "user_flag" */
  user_flag_aggregate: User_Flag_Aggregate;
  /** fetch data from the table: "user_flag" using primary key columns */
  user_flag_by_pk?: Maybe<User_Flag>;
  /** fetch data from the table: "user_flag_type" */
  user_flag_type: Array<User_Flag_Type>;
  /** fetch aggregated fields from the table: "user_flag_type" */
  user_flag_type_aggregate: User_Flag_Type_Aggregate;
  /** fetch data from the table: "user_flag_type" using primary key columns */
  user_flag_type_by_pk?: Maybe<User_Flag_Type>;
  /** fetch data from the table: "user_friends" */
  user_friends: Array<User_Friends>;
  /** fetch aggregated fields from the table: "user_friends" */
  user_friends_aggregate: User_Friends_Aggregate;
  /** fetch data from the table: "user_received_friend_requests" */
  user_received_friend_requests: Array<User_Received_Friend_Requests>;
  /** fetch aggregated fields from the table: "user_received_friend_requests" */
  user_received_friend_requests_aggregate: User_Received_Friend_Requests_Aggregate;
  /** fetch data from the table: "user_sent_friend_requests" */
  user_sent_friend_requests: Array<User_Sent_Friend_Requests>;
  /** fetch aggregated fields from the table: "user_sent_friend_requests" */
  user_sent_friend_requests_aggregate: User_Sent_Friend_Requests_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootBase_DecksArgs = {
  distinct_on?: InputMaybe<Array<Base_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Base_Decks_Order_By>>;
  where?: InputMaybe<Base_Decks_Bool_Exp>;
};


export type Query_RootBase_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Base_Decks_Order_By>>;
  where?: InputMaybe<Base_Decks_Bool_Exp>;
};


export type Query_RootCampaignArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Order_By>>;
  where?: InputMaybe<Campaign_Bool_Exp>;
};


export type Query_RootCampaign_AccessArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Access_Order_By>>;
  where?: InputMaybe<Campaign_Access_Bool_Exp>;
};


export type Query_RootCampaign_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Access_Order_By>>;
  where?: InputMaybe<Campaign_Access_Bool_Exp>;
};


export type Query_RootCampaign_Access_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCampaign_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Order_By>>;
  where?: InputMaybe<Campaign_Bool_Exp>;
};


export type Query_RootCampaign_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCampaign_DeckArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};


export type Query_RootCampaign_Deck_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};


export type Query_RootCampaign_Deck_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCampaign_GuideArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Guide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Guide_Order_By>>;
  where?: InputMaybe<Campaign_Guide_Bool_Exp>;
};


export type Query_RootCampaign_Guide_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Guide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Guide_Order_By>>;
  where?: InputMaybe<Campaign_Guide_Bool_Exp>;
};


export type Query_RootCampaign_InvestigatorArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Investigator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Investigator_Order_By>>;
  where?: InputMaybe<Campaign_Investigator_Bool_Exp>;
};


export type Query_RootCampaign_Investigator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Investigator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Investigator_Order_By>>;
  where?: InputMaybe<Campaign_Investigator_Bool_Exp>;
};


export type Query_RootCampaign_Investigator_By_PkArgs = {
  campaign_id: Scalars['Int'];
  investigator: Scalars['String'];
};


export type Query_RootCardArgs = {
  distinct_on?: InputMaybe<Array<Card_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Order_By>>;
  where?: InputMaybe<Card_Bool_Exp>;
};


export type Query_RootCard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Card_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Order_By>>;
  where?: InputMaybe<Card_Bool_Exp>;
};


export type Query_RootCard_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootCard_PackArgs = {
  distinct_on?: InputMaybe<Array<Card_Pack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Pack_Order_By>>;
  where?: InputMaybe<Card_Pack_Bool_Exp>;
};


export type Query_RootCard_Pack_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Card_Pack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Pack_Order_By>>;
  where?: InputMaybe<Card_Pack_Bool_Exp>;
};


export type Query_RootCard_Pack_By_PkArgs = {
  code: Scalars['String'];
  locale: Scalars['String'];
};


export type Query_RootCard_TextArgs = {
  distinct_on?: InputMaybe<Array<Card_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Text_Order_By>>;
  where?: InputMaybe<Card_Text_Bool_Exp>;
};


export type Query_RootCard_Text_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Card_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Text_Order_By>>;
  where?: InputMaybe<Card_Text_Bool_Exp>;
};


export type Query_RootCard_Text_By_PkArgs = {
  code: Scalars['String'];
  locale: Scalars['String'];
};


export type Query_RootChaos_Bag_ResultArgs = {
  distinct_on?: InputMaybe<Array<Chaos_Bag_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chaos_Bag_Result_Order_By>>;
  where?: InputMaybe<Chaos_Bag_Result_Bool_Exp>;
};


export type Query_RootChaos_Bag_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chaos_Bag_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chaos_Bag_Result_Order_By>>;
  where?: InputMaybe<Chaos_Bag_Result_Bool_Exp>;
};


export type Query_RootChaos_Bag_Result_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFaqArgs = {
  distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Order_By>>;
  where?: InputMaybe<Faq_Bool_Exp>;
};


export type Query_RootFaq_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Order_By>>;
  where?: InputMaybe<Faq_Bool_Exp>;
};


export type Query_RootFaq_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootFaq_TextArgs = {
  distinct_on?: InputMaybe<Array<Faq_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Text_Order_By>>;
  where?: InputMaybe<Faq_Text_Bool_Exp>;
};


export type Query_RootFaq_Text_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faq_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Text_Order_By>>;
  where?: InputMaybe<Faq_Text_Bool_Exp>;
};


export type Query_RootFaq_Text_By_PkArgs = {
  code: Scalars['String'];
  locale: Scalars['String'];
};


export type Query_RootFriend_StatusArgs = {
  distinct_on?: InputMaybe<Array<Friend_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Friend_Status_Order_By>>;
  where?: InputMaybe<Friend_Status_Bool_Exp>;
};


export type Query_RootFriend_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friend_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Friend_Status_Order_By>>;
  where?: InputMaybe<Friend_Status_Bool_Exp>;
};


export type Query_RootFriend_Status_By_PkArgs = {
  user_id_a: Scalars['String'];
  user_id_b: Scalars['String'];
};


export type Query_RootFriend_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Friend_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Friend_Status_Type_Order_By>>;
  where?: InputMaybe<Friend_Status_Type_Bool_Exp>;
};


export type Query_RootFriend_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friend_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Friend_Status_Type_Order_By>>;
  where?: InputMaybe<Friend_Status_Type_Bool_Exp>;
};


export type Query_RootFriend_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootGuide_AchievementArgs = {
  distinct_on?: InputMaybe<Array<Guide_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Achievement_Order_By>>;
  where?: InputMaybe<Guide_Achievement_Bool_Exp>;
};


export type Query_RootGuide_Achievement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Guide_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Achievement_Order_By>>;
  where?: InputMaybe<Guide_Achievement_Bool_Exp>;
};


export type Query_RootGuide_Achievement_By_PkArgs = {
  campaign_id: Scalars['Int'];
  id: Scalars['String'];
};


export type Query_RootGuide_InputArgs = {
  distinct_on?: InputMaybe<Array<Guide_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Input_Order_By>>;
  where?: InputMaybe<Guide_Input_Bool_Exp>;
};


export type Query_RootGuide_Input_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Guide_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Input_Order_By>>;
  where?: InputMaybe<Guide_Input_Bool_Exp>;
};


export type Query_RootGuide_Input_By_PkArgs = {
  campaign_id: Scalars['Int'];
  id: Scalars['String'];
};


export type Query_RootInvestigator_DataArgs = {
  distinct_on?: InputMaybe<Array<Investigator_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investigator_Data_Order_By>>;
  where?: InputMaybe<Investigator_Data_Bool_Exp>;
};


export type Query_RootInvestigator_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investigator_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investigator_Data_Order_By>>;
  where?: InputMaybe<Investigator_Data_Bool_Exp>;
};


export type Query_RootInvestigator_Data_By_PkArgs = {
  campaign_id: Scalars['Int'];
  investigator: Scalars['String'];
};


export type Query_RootLatest_DecksArgs = {
  distinct_on?: InputMaybe<Array<Latest_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Latest_Decks_Order_By>>;
  where?: InputMaybe<Latest_Decks_Bool_Exp>;
};


export type Query_RootLatest_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Latest_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Latest_Decks_Order_By>>;
  where?: InputMaybe<Latest_Decks_Bool_Exp>;
};


export type Query_RootLocal_DecksArgs = {
  distinct_on?: InputMaybe<Array<Local_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Local_Decks_Order_By>>;
  where?: InputMaybe<Local_Decks_Bool_Exp>;
};


export type Query_RootLocal_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Local_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Local_Decks_Order_By>>;
  where?: InputMaybe<Local_Decks_Bool_Exp>;
};


export type Query_RootUser_CampaignsArgs = {
  distinct_on?: InputMaybe<Array<User_Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Campaigns_Order_By>>;
  where?: InputMaybe<User_Campaigns_Bool_Exp>;
};


export type Query_RootUser_Campaigns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Campaigns_Order_By>>;
  where?: InputMaybe<User_Campaigns_Bool_Exp>;
};


export type Query_RootUser_FlagArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Order_By>>;
  where?: InputMaybe<User_Flag_Bool_Exp>;
};


export type Query_RootUser_Flag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Order_By>>;
  where?: InputMaybe<User_Flag_Bool_Exp>;
};


export type Query_RootUser_Flag_By_PkArgs = {
  flag: User_Flag_Type_Enum;
  user_id: Scalars['String'];
};


export type Query_RootUser_Flag_TypeArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Type_Order_By>>;
  where?: InputMaybe<User_Flag_Type_Bool_Exp>;
};


export type Query_RootUser_Flag_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Type_Order_By>>;
  where?: InputMaybe<User_Flag_Type_Bool_Exp>;
};


export type Query_RootUser_Flag_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUser_FriendsArgs = {
  distinct_on?: InputMaybe<Array<User_Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Friends_Order_By>>;
  where?: InputMaybe<User_Friends_Bool_Exp>;
};


export type Query_RootUser_Friends_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Friends_Order_By>>;
  where?: InputMaybe<User_Friends_Bool_Exp>;
};


export type Query_RootUser_Received_Friend_RequestsArgs = {
  distinct_on?: InputMaybe<Array<User_Received_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Received_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Received_Friend_Requests_Bool_Exp>;
};


export type Query_RootUser_Received_Friend_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Received_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Received_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Received_Friend_Requests_Bool_Exp>;
};


export type Query_RootUser_Sent_Friend_RequestsArgs = {
  distinct_on?: InputMaybe<Array<User_Sent_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sent_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Sent_Friend_Requests_Bool_Exp>;
};


export type Query_RootUser_Sent_Friend_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sent_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sent_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Sent_Friend_Requests_Bool_Exp>;
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  base_decks: Array<Base_Decks>;
  /** An aggregate relationship */
  base_decks_aggregate: Base_Decks_Aggregate;
  /** fetch data from the table: "campaign" */
  campaign: Array<Campaign>;
  /** fetch data from the table: "campaign_access" */
  campaign_access: Array<Campaign_Access>;
  /** fetch aggregated fields from the table: "campaign_access" */
  campaign_access_aggregate: Campaign_Access_Aggregate;
  /** fetch data from the table: "campaign_access" using primary key columns */
  campaign_access_by_pk?: Maybe<Campaign_Access>;
  /** fetch aggregated fields from the table: "campaign" */
  campaign_aggregate: Campaign_Aggregate;
  /** fetch data from the table: "campaign" using primary key columns */
  campaign_by_pk?: Maybe<Campaign>;
  /** fetch data from the table: "campaign_deck" */
  campaign_deck: Array<Campaign_Deck>;
  /** fetch aggregated fields from the table: "campaign_deck" */
  campaign_deck_aggregate: Campaign_Deck_Aggregate;
  /** fetch data from the table: "campaign_deck" using primary key columns */
  campaign_deck_by_pk?: Maybe<Campaign_Deck>;
  /** fetch data from the table: "campaign_guide" */
  campaign_guide: Array<Campaign_Guide>;
  /** fetch aggregated fields from the table: "campaign_guide" */
  campaign_guide_aggregate: Campaign_Guide_Aggregate;
  /** fetch data from the table: "campaign_investigator" */
  campaign_investigator: Array<Campaign_Investigator>;
  /** fetch aggregated fields from the table: "campaign_investigator" */
  campaign_investigator_aggregate: Campaign_Investigator_Aggregate;
  /** fetch data from the table: "campaign_investigator" using primary key columns */
  campaign_investigator_by_pk?: Maybe<Campaign_Investigator>;
  /** fetch data from the table: "card" */
  card: Array<Card>;
  /** fetch aggregated fields from the table: "card" */
  card_aggregate: Card_Aggregate;
  /** fetch data from the table: "card" using primary key columns */
  card_by_pk?: Maybe<Card>;
  /** fetch data from the table: "card_pack" */
  card_pack: Array<Card_Pack>;
  /** fetch aggregated fields from the table: "card_pack" */
  card_pack_aggregate: Card_Pack_Aggregate;
  /** fetch data from the table: "card_pack" using primary key columns */
  card_pack_by_pk?: Maybe<Card_Pack>;
  /** fetch data from the table: "card_text" */
  card_text: Array<Card_Text>;
  /** fetch aggregated fields from the table: "card_text" */
  card_text_aggregate: Card_Text_Aggregate;
  /** fetch data from the table: "card_text" using primary key columns */
  card_text_by_pk?: Maybe<Card_Text>;
  /** An array relationship */
  chaos_bag_result: Array<Chaos_Bag_Result>;
  /** An aggregate relationship */
  chaos_bag_result_aggregate: Chaos_Bag_Result_Aggregate;
  /** fetch data from the table: "chaos_bag_result" using primary key columns */
  chaos_bag_result_by_pk?: Maybe<Chaos_Bag_Result>;
  /** fetch data from the table: "faq" */
  faq: Array<Faq>;
  /** fetch aggregated fields from the table: "faq" */
  faq_aggregate: Faq_Aggregate;
  /** fetch data from the table: "faq" using primary key columns */
  faq_by_pk?: Maybe<Faq>;
  /** fetch data from the table: "faq_text" */
  faq_text: Array<Faq_Text>;
  /** fetch aggregated fields from the table: "faq_text" */
  faq_text_aggregate: Faq_Text_Aggregate;
  /** fetch data from the table: "faq_text" using primary key columns */
  faq_text_by_pk?: Maybe<Faq_Text>;
  /** fetch data from the table: "friend_status" */
  friend_status: Array<Friend_Status>;
  /** fetch aggregated fields from the table: "friend_status" */
  friend_status_aggregate: Friend_Status_Aggregate;
  /** fetch data from the table: "friend_status" using primary key columns */
  friend_status_by_pk?: Maybe<Friend_Status>;
  /** fetch data from the table: "friend_status_type" */
  friend_status_type: Array<Friend_Status_Type>;
  /** fetch aggregated fields from the table: "friend_status_type" */
  friend_status_type_aggregate: Friend_Status_Type_Aggregate;
  /** fetch data from the table: "friend_status_type" using primary key columns */
  friend_status_type_by_pk?: Maybe<Friend_Status_Type>;
  /** fetch data from the table: "guide_achievement" */
  guide_achievement: Array<Guide_Achievement>;
  /** fetch aggregated fields from the table: "guide_achievement" */
  guide_achievement_aggregate: Guide_Achievement_Aggregate;
  /** fetch data from the table: "guide_achievement" using primary key columns */
  guide_achievement_by_pk?: Maybe<Guide_Achievement>;
  /** fetch data from the table: "guide_input" */
  guide_input: Array<Guide_Input>;
  /** fetch aggregated fields from the table: "guide_input" */
  guide_input_aggregate: Guide_Input_Aggregate;
  /** fetch data from the table: "guide_input" using primary key columns */
  guide_input_by_pk?: Maybe<Guide_Input>;
  /** An array relationship */
  investigator_data: Array<Investigator_Data>;
  /** An aggregate relationship */
  investigator_data_aggregate: Investigator_Data_Aggregate;
  /** fetch data from the table: "investigator_data" using primary key columns */
  investigator_data_by_pk?: Maybe<Investigator_Data>;
  /** An array relationship */
  latest_decks: Array<Latest_Decks>;
  /** An aggregate relationship */
  latest_decks_aggregate: Latest_Decks_Aggregate;
  /** An array relationship */
  local_decks: Array<Local_Decks>;
  /** An aggregate relationship */
  local_decks_aggregate: Local_Decks_Aggregate;
  /** fetch data from the table: "user_campaigns" */
  user_campaigns: Array<User_Campaigns>;
  /** fetch aggregated fields from the table: "user_campaigns" */
  user_campaigns_aggregate: User_Campaigns_Aggregate;
  /** fetch data from the table: "user_flag" */
  user_flag: Array<User_Flag>;
  /** fetch aggregated fields from the table: "user_flag" */
  user_flag_aggregate: User_Flag_Aggregate;
  /** fetch data from the table: "user_flag" using primary key columns */
  user_flag_by_pk?: Maybe<User_Flag>;
  /** fetch data from the table: "user_flag_type" */
  user_flag_type: Array<User_Flag_Type>;
  /** fetch aggregated fields from the table: "user_flag_type" */
  user_flag_type_aggregate: User_Flag_Type_Aggregate;
  /** fetch data from the table: "user_flag_type" using primary key columns */
  user_flag_type_by_pk?: Maybe<User_Flag_Type>;
  /** fetch data from the table: "user_friends" */
  user_friends: Array<User_Friends>;
  /** fetch aggregated fields from the table: "user_friends" */
  user_friends_aggregate: User_Friends_Aggregate;
  /** fetch data from the table: "user_received_friend_requests" */
  user_received_friend_requests: Array<User_Received_Friend_Requests>;
  /** fetch aggregated fields from the table: "user_received_friend_requests" */
  user_received_friend_requests_aggregate: User_Received_Friend_Requests_Aggregate;
  /** fetch data from the table: "user_sent_friend_requests" */
  user_sent_friend_requests: Array<User_Sent_Friend_Requests>;
  /** fetch aggregated fields from the table: "user_sent_friend_requests" */
  user_sent_friend_requests_aggregate: User_Sent_Friend_Requests_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Subscription_RootBase_DecksArgs = {
  distinct_on?: InputMaybe<Array<Base_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Base_Decks_Order_By>>;
  where?: InputMaybe<Base_Decks_Bool_Exp>;
};


export type Subscription_RootBase_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Base_Decks_Order_By>>;
  where?: InputMaybe<Base_Decks_Bool_Exp>;
};


export type Subscription_RootCampaignArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Order_By>>;
  where?: InputMaybe<Campaign_Bool_Exp>;
};


export type Subscription_RootCampaign_AccessArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Access_Order_By>>;
  where?: InputMaybe<Campaign_Access_Bool_Exp>;
};


export type Subscription_RootCampaign_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Access_Order_By>>;
  where?: InputMaybe<Campaign_Access_Bool_Exp>;
};


export type Subscription_RootCampaign_Access_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCampaign_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Order_By>>;
  where?: InputMaybe<Campaign_Bool_Exp>;
};


export type Subscription_RootCampaign_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCampaign_DeckArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};


export type Subscription_RootCampaign_Deck_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};


export type Subscription_RootCampaign_Deck_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCampaign_GuideArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Guide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Guide_Order_By>>;
  where?: InputMaybe<Campaign_Guide_Bool_Exp>;
};


export type Subscription_RootCampaign_Guide_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Guide_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Guide_Order_By>>;
  where?: InputMaybe<Campaign_Guide_Bool_Exp>;
};


export type Subscription_RootCampaign_InvestigatorArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Investigator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Investigator_Order_By>>;
  where?: InputMaybe<Campaign_Investigator_Bool_Exp>;
};


export type Subscription_RootCampaign_Investigator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Investigator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Investigator_Order_By>>;
  where?: InputMaybe<Campaign_Investigator_Bool_Exp>;
};


export type Subscription_RootCampaign_Investigator_By_PkArgs = {
  campaign_id: Scalars['Int'];
  investigator: Scalars['String'];
};


export type Subscription_RootCardArgs = {
  distinct_on?: InputMaybe<Array<Card_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Order_By>>;
  where?: InputMaybe<Card_Bool_Exp>;
};


export type Subscription_RootCard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Card_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Order_By>>;
  where?: InputMaybe<Card_Bool_Exp>;
};


export type Subscription_RootCard_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootCard_PackArgs = {
  distinct_on?: InputMaybe<Array<Card_Pack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Pack_Order_By>>;
  where?: InputMaybe<Card_Pack_Bool_Exp>;
};


export type Subscription_RootCard_Pack_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Card_Pack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Pack_Order_By>>;
  where?: InputMaybe<Card_Pack_Bool_Exp>;
};


export type Subscription_RootCard_Pack_By_PkArgs = {
  code: Scalars['String'];
  locale: Scalars['String'];
};


export type Subscription_RootCard_TextArgs = {
  distinct_on?: InputMaybe<Array<Card_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Text_Order_By>>;
  where?: InputMaybe<Card_Text_Bool_Exp>;
};


export type Subscription_RootCard_Text_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Card_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Card_Text_Order_By>>;
  where?: InputMaybe<Card_Text_Bool_Exp>;
};


export type Subscription_RootCard_Text_By_PkArgs = {
  code: Scalars['String'];
  locale: Scalars['String'];
};


export type Subscription_RootChaos_Bag_ResultArgs = {
  distinct_on?: InputMaybe<Array<Chaos_Bag_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chaos_Bag_Result_Order_By>>;
  where?: InputMaybe<Chaos_Bag_Result_Bool_Exp>;
};


export type Subscription_RootChaos_Bag_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chaos_Bag_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chaos_Bag_Result_Order_By>>;
  where?: InputMaybe<Chaos_Bag_Result_Bool_Exp>;
};


export type Subscription_RootChaos_Bag_Result_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFaqArgs = {
  distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Order_By>>;
  where?: InputMaybe<Faq_Bool_Exp>;
};


export type Subscription_RootFaq_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faq_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Order_By>>;
  where?: InputMaybe<Faq_Bool_Exp>;
};


export type Subscription_RootFaq_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootFaq_TextArgs = {
  distinct_on?: InputMaybe<Array<Faq_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Text_Order_By>>;
  where?: InputMaybe<Faq_Text_Bool_Exp>;
};


export type Subscription_RootFaq_Text_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faq_Text_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Text_Order_By>>;
  where?: InputMaybe<Faq_Text_Bool_Exp>;
};


export type Subscription_RootFaq_Text_By_PkArgs = {
  code: Scalars['String'];
  locale: Scalars['String'];
};


export type Subscription_RootFriend_StatusArgs = {
  distinct_on?: InputMaybe<Array<Friend_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Friend_Status_Order_By>>;
  where?: InputMaybe<Friend_Status_Bool_Exp>;
};


export type Subscription_RootFriend_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friend_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Friend_Status_Order_By>>;
  where?: InputMaybe<Friend_Status_Bool_Exp>;
};


export type Subscription_RootFriend_Status_By_PkArgs = {
  user_id_a: Scalars['String'];
  user_id_b: Scalars['String'];
};


export type Subscription_RootFriend_Status_TypeArgs = {
  distinct_on?: InputMaybe<Array<Friend_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Friend_Status_Type_Order_By>>;
  where?: InputMaybe<Friend_Status_Type_Bool_Exp>;
};


export type Subscription_RootFriend_Status_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friend_Status_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Friend_Status_Type_Order_By>>;
  where?: InputMaybe<Friend_Status_Type_Bool_Exp>;
};


export type Subscription_RootFriend_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGuide_AchievementArgs = {
  distinct_on?: InputMaybe<Array<Guide_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Achievement_Order_By>>;
  where?: InputMaybe<Guide_Achievement_Bool_Exp>;
};


export type Subscription_RootGuide_Achievement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Guide_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Achievement_Order_By>>;
  where?: InputMaybe<Guide_Achievement_Bool_Exp>;
};


export type Subscription_RootGuide_Achievement_By_PkArgs = {
  campaign_id: Scalars['Int'];
  id: Scalars['String'];
};


export type Subscription_RootGuide_InputArgs = {
  distinct_on?: InputMaybe<Array<Guide_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Input_Order_By>>;
  where?: InputMaybe<Guide_Input_Bool_Exp>;
};


export type Subscription_RootGuide_Input_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Guide_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Guide_Input_Order_By>>;
  where?: InputMaybe<Guide_Input_Bool_Exp>;
};


export type Subscription_RootGuide_Input_By_PkArgs = {
  campaign_id: Scalars['Int'];
  id: Scalars['String'];
};


export type Subscription_RootInvestigator_DataArgs = {
  distinct_on?: InputMaybe<Array<Investigator_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investigator_Data_Order_By>>;
  where?: InputMaybe<Investigator_Data_Bool_Exp>;
};


export type Subscription_RootInvestigator_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investigator_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investigator_Data_Order_By>>;
  where?: InputMaybe<Investigator_Data_Bool_Exp>;
};


export type Subscription_RootInvestigator_Data_By_PkArgs = {
  campaign_id: Scalars['Int'];
  investigator: Scalars['String'];
};


export type Subscription_RootLatest_DecksArgs = {
  distinct_on?: InputMaybe<Array<Latest_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Latest_Decks_Order_By>>;
  where?: InputMaybe<Latest_Decks_Bool_Exp>;
};


export type Subscription_RootLatest_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Latest_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Latest_Decks_Order_By>>;
  where?: InputMaybe<Latest_Decks_Bool_Exp>;
};


export type Subscription_RootLocal_DecksArgs = {
  distinct_on?: InputMaybe<Array<Local_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Local_Decks_Order_By>>;
  where?: InputMaybe<Local_Decks_Bool_Exp>;
};


export type Subscription_RootLocal_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Local_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Local_Decks_Order_By>>;
  where?: InputMaybe<Local_Decks_Bool_Exp>;
};


export type Subscription_RootUser_CampaignsArgs = {
  distinct_on?: InputMaybe<Array<User_Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Campaigns_Order_By>>;
  where?: InputMaybe<User_Campaigns_Bool_Exp>;
};


export type Subscription_RootUser_Campaigns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Campaigns_Order_By>>;
  where?: InputMaybe<User_Campaigns_Bool_Exp>;
};


export type Subscription_RootUser_FlagArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Order_By>>;
  where?: InputMaybe<User_Flag_Bool_Exp>;
};


export type Subscription_RootUser_Flag_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Order_By>>;
  where?: InputMaybe<User_Flag_Bool_Exp>;
};


export type Subscription_RootUser_Flag_By_PkArgs = {
  flag: User_Flag_Type_Enum;
  user_id: Scalars['String'];
};


export type Subscription_RootUser_Flag_TypeArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Type_Order_By>>;
  where?: InputMaybe<User_Flag_Type_Bool_Exp>;
};


export type Subscription_RootUser_Flag_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Type_Order_By>>;
  where?: InputMaybe<User_Flag_Type_Bool_Exp>;
};


export type Subscription_RootUser_Flag_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUser_FriendsArgs = {
  distinct_on?: InputMaybe<Array<User_Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Friends_Order_By>>;
  where?: InputMaybe<User_Friends_Bool_Exp>;
};


export type Subscription_RootUser_Friends_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Friends_Order_By>>;
  where?: InputMaybe<User_Friends_Bool_Exp>;
};


export type Subscription_RootUser_Received_Friend_RequestsArgs = {
  distinct_on?: InputMaybe<Array<User_Received_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Received_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Received_Friend_Requests_Bool_Exp>;
};


export type Subscription_RootUser_Received_Friend_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Received_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Received_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Received_Friend_Requests_Bool_Exp>;
};


export type Subscription_RootUser_Sent_Friend_RequestsArgs = {
  distinct_on?: InputMaybe<Array<User_Sent_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sent_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Sent_Friend_Requests_Bool_Exp>;
};


export type Subscription_RootUser_Sent_Friend_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sent_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sent_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Sent_Friend_Requests_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_campaigns" */
export type User_Campaigns = {
  __typename?: 'user_campaigns';
  /** An object relationship */
  campaign?: Maybe<Campaign>;
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_campaigns" */
export type User_Campaigns_Aggregate = {
  __typename?: 'user_campaigns_aggregate';
  aggregate?: Maybe<User_Campaigns_Aggregate_Fields>;
  nodes: Array<User_Campaigns>;
};

/** aggregate fields of "user_campaigns" */
export type User_Campaigns_Aggregate_Fields = {
  __typename?: 'user_campaigns_aggregate_fields';
  avg?: Maybe<User_Campaigns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Campaigns_Max_Fields>;
  min?: Maybe<User_Campaigns_Min_Fields>;
  stddev?: Maybe<User_Campaigns_Stddev_Fields>;
  stddev_pop?: Maybe<User_Campaigns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Campaigns_Stddev_Samp_Fields>;
  sum?: Maybe<User_Campaigns_Sum_Fields>;
  var_pop?: Maybe<User_Campaigns_Var_Pop_Fields>;
  var_samp?: Maybe<User_Campaigns_Var_Samp_Fields>;
  variance?: Maybe<User_Campaigns_Variance_Fields>;
};


/** aggregate fields of "user_campaigns" */
export type User_Campaigns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Campaigns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_campaigns" */
export type User_Campaigns_Aggregate_Order_By = {
  avg?: InputMaybe<User_Campaigns_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Campaigns_Max_Order_By>;
  min?: InputMaybe<User_Campaigns_Min_Order_By>;
  stddev?: InputMaybe<User_Campaigns_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Campaigns_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Campaigns_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Campaigns_Sum_Order_By>;
  var_pop?: InputMaybe<User_Campaigns_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Campaigns_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Campaigns_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_campaigns" */
export type User_Campaigns_Arr_Rel_Insert_Input = {
  data: Array<User_Campaigns_Insert_Input>;
};

/** aggregate avg on columns */
export type User_Campaigns_Avg_Fields = {
  __typename?: 'user_campaigns_avg_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_campaigns" */
export type User_Campaigns_Avg_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_campaigns". All fields are combined with a logical 'AND'. */
export type User_Campaigns_Bool_Exp = {
  _and?: InputMaybe<Array<User_Campaigns_Bool_Exp>>;
  _not?: InputMaybe<User_Campaigns_Bool_Exp>;
  _or?: InputMaybe<Array<User_Campaigns_Bool_Exp>>;
  campaign?: InputMaybe<Campaign_Bool_Exp>;
  campaign_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "user_campaigns" */
export type User_Campaigns_Inc_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_campaigns" */
export type User_Campaigns_Insert_Input = {
  campaign?: InputMaybe<Campaign_Obj_Rel_Insert_Input>;
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Campaigns_Max_Fields = {
  __typename?: 'user_campaigns_max_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_campaigns" */
export type User_Campaigns_Max_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Campaigns_Min_Fields = {
  __typename?: 'user_campaigns_min_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_campaigns" */
export type User_Campaigns_Min_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_campaigns" */
export type User_Campaigns_Mutation_Response = {
  __typename?: 'user_campaigns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Campaigns>;
};

/** Ordering options when selecting data from "user_campaigns". */
export type User_Campaigns_Order_By = {
  campaign?: InputMaybe<Campaign_Order_By>;
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_campaigns" */
export enum User_Campaigns_Select_Column {
  /** column name */
  CampaignId = 'campaign_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_campaigns" */
export type User_Campaigns_Set_Input = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Campaigns_Stddev_Fields = {
  __typename?: 'user_campaigns_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_campaigns" */
export type User_Campaigns_Stddev_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Campaigns_Stddev_Pop_Fields = {
  __typename?: 'user_campaigns_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_campaigns" */
export type User_Campaigns_Stddev_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Campaigns_Stddev_Samp_Fields = {
  __typename?: 'user_campaigns_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_campaigns" */
export type User_Campaigns_Stddev_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Campaigns_Sum_Fields = {
  __typename?: 'user_campaigns_sum_fields';
  campaign_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_campaigns" */
export type User_Campaigns_Sum_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type User_Campaigns_Var_Pop_Fields = {
  __typename?: 'user_campaigns_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_campaigns" */
export type User_Campaigns_Var_Pop_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Campaigns_Var_Samp_Fields = {
  __typename?: 'user_campaigns_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_campaigns" */
export type User_Campaigns_Var_Samp_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Campaigns_Variance_Fields = {
  __typename?: 'user_campaigns_variance_fields';
  campaign_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_campaigns" */
export type User_Campaigns_Variance_Order_By = {
  campaign_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_flag" */
export type User_Flag = {
  __typename?: 'user_flag';
  flag: User_Flag_Type_Enum;
  user_id: Scalars['String'];
};

/** aggregated selection of "user_flag" */
export type User_Flag_Aggregate = {
  __typename?: 'user_flag_aggregate';
  aggregate?: Maybe<User_Flag_Aggregate_Fields>;
  nodes: Array<User_Flag>;
};

/** aggregate fields of "user_flag" */
export type User_Flag_Aggregate_Fields = {
  __typename?: 'user_flag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Flag_Max_Fields>;
  min?: Maybe<User_Flag_Min_Fields>;
};


/** aggregate fields of "user_flag" */
export type User_Flag_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Flag_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_flag" */
export type User_Flag_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Flag_Max_Order_By>;
  min?: InputMaybe<User_Flag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_flag" */
export type User_Flag_Arr_Rel_Insert_Input = {
  data: Array<User_Flag_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<User_Flag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_flag". All fields are combined with a logical 'AND'. */
export type User_Flag_Bool_Exp = {
  _and?: InputMaybe<Array<User_Flag_Bool_Exp>>;
  _not?: InputMaybe<User_Flag_Bool_Exp>;
  _or?: InputMaybe<Array<User_Flag_Bool_Exp>>;
  flag?: InputMaybe<User_Flag_Type_Enum_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_flag" */
export enum User_Flag_Constraint {
  /** unique or primary key constraint */
  UserFlagPkey = 'user_flag_pkey'
}

/** input type for inserting data into table "user_flag" */
export type User_Flag_Insert_Input = {
  flag?: InputMaybe<User_Flag_Type_Enum>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Flag_Max_Fields = {
  __typename?: 'user_flag_max_fields';
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_flag" */
export type User_Flag_Max_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Flag_Min_Fields = {
  __typename?: 'user_flag_min_fields';
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_flag" */
export type User_Flag_Min_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_flag" */
export type User_Flag_Mutation_Response = {
  __typename?: 'user_flag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Flag>;
};

/** on conflict condition type for table "user_flag" */
export type User_Flag_On_Conflict = {
  constraint: User_Flag_Constraint;
  update_columns: Array<User_Flag_Update_Column>;
  where?: InputMaybe<User_Flag_Bool_Exp>;
};

/** Ordering options when selecting data from "user_flag". */
export type User_Flag_Order_By = {
  flag?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_flag */
export type User_Flag_Pk_Columns_Input = {
  flag: User_Flag_Type_Enum;
  user_id: Scalars['String'];
};

/** select columns of table "user_flag" */
export enum User_Flag_Select_Column {
  /** column name */
  Flag = 'flag',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_flag" */
export type User_Flag_Set_Input = {
  flag?: InputMaybe<User_Flag_Type_Enum>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "user_flag_type" */
export type User_Flag_Type = {
  __typename?: 'user_flag_type';
  value: Scalars['String'];
};

/** aggregated selection of "user_flag_type" */
export type User_Flag_Type_Aggregate = {
  __typename?: 'user_flag_type_aggregate';
  aggregate?: Maybe<User_Flag_Type_Aggregate_Fields>;
  nodes: Array<User_Flag_Type>;
};

/** aggregate fields of "user_flag_type" */
export type User_Flag_Type_Aggregate_Fields = {
  __typename?: 'user_flag_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Flag_Type_Max_Fields>;
  min?: Maybe<User_Flag_Type_Min_Fields>;
};


/** aggregate fields of "user_flag_type" */
export type User_Flag_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Flag_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_flag_type". All fields are combined with a logical 'AND'. */
export type User_Flag_Type_Bool_Exp = {
  _and?: InputMaybe<Array<User_Flag_Type_Bool_Exp>>;
  _not?: InputMaybe<User_Flag_Type_Bool_Exp>;
  _or?: InputMaybe<Array<User_Flag_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_flag_type" */
export enum User_Flag_Type_Constraint {
  /** unique or primary key constraint */
  UserFlagTypePkey = 'user_flag_type_pkey'
}

export enum User_Flag_Type_Enum {
  Admin = 'admin',
  EsDv = 'es_dv',
  EsDvAdmin = 'es_dv_admin'
}

/** Boolean expression to compare columns of type "user_flag_type_enum". All fields are combined with logical 'AND'. */
export type User_Flag_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Flag_Type_Enum>;
  _in?: InputMaybe<Array<User_Flag_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Flag_Type_Enum>;
  _nin?: InputMaybe<Array<User_Flag_Type_Enum>>;
};

/** input type for inserting data into table "user_flag_type" */
export type User_Flag_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Flag_Type_Max_Fields = {
  __typename?: 'user_flag_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Flag_Type_Min_Fields = {
  __typename?: 'user_flag_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_flag_type" */
export type User_Flag_Type_Mutation_Response = {
  __typename?: 'user_flag_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Flag_Type>;
};

/** on conflict condition type for table "user_flag_type" */
export type User_Flag_Type_On_Conflict = {
  constraint: User_Flag_Type_Constraint;
  update_columns: Array<User_Flag_Type_Update_Column>;
  where?: InputMaybe<User_Flag_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "user_flag_type". */
export type User_Flag_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_flag_type */
export type User_Flag_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_flag_type" */
export enum User_Flag_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_flag_type" */
export type User_Flag_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_flag_type" */
export enum User_Flag_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "user_flag" */
export enum User_Flag_Update_Column {
  /** column name */
  Flag = 'flag',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_friends" */
export type User_Friends = {
  __typename?: 'user_friends';
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_friends" */
export type User_Friends_Aggregate = {
  __typename?: 'user_friends_aggregate';
  aggregate?: Maybe<User_Friends_Aggregate_Fields>;
  nodes: Array<User_Friends>;
};

/** aggregate fields of "user_friends" */
export type User_Friends_Aggregate_Fields = {
  __typename?: 'user_friends_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Friends_Max_Fields>;
  min?: Maybe<User_Friends_Min_Fields>;
};


/** aggregate fields of "user_friends" */
export type User_Friends_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Friends_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_friends" */
export type User_Friends_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Friends_Max_Order_By>;
  min?: InputMaybe<User_Friends_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_friends" */
export type User_Friends_Arr_Rel_Insert_Input = {
  data: Array<User_Friends_Insert_Input>;
};

/** Boolean expression to filter rows from the table "user_friends". All fields are combined with a logical 'AND'. */
export type User_Friends_Bool_Exp = {
  _and?: InputMaybe<Array<User_Friends_Bool_Exp>>;
  _not?: InputMaybe<User_Friends_Bool_Exp>;
  _or?: InputMaybe<Array<User_Friends_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id_a?: InputMaybe<String_Comparison_Exp>;
  user_id_b?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "user_friends" */
export type User_Friends_Insert_Input = {
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id_a?: InputMaybe<Scalars['String']>;
  user_id_b?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Friends_Max_Fields = {
  __typename?: 'user_friends_max_fields';
  status?: Maybe<Scalars['String']>;
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_friends" */
export type User_Friends_Max_Order_By = {
  status?: InputMaybe<Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Friends_Min_Fields = {
  __typename?: 'user_friends_min_fields';
  status?: Maybe<Scalars['String']>;
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_friends" */
export type User_Friends_Min_Order_By = {
  status?: InputMaybe<Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_friends" */
export type User_Friends_Mutation_Response = {
  __typename?: 'user_friends_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Friends>;
};

/** Ordering options when selecting data from "user_friends". */
export type User_Friends_Order_By = {
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** select columns of table "user_friends" */
export enum User_Friends_Select_Column {
  /** column name */
  Status = 'status',
  /** column name */
  UserIdA = 'user_id_a',
  /** column name */
  UserIdB = 'user_id_b'
}

/** input type for updating data in table "user_friends" */
export type User_Friends_Set_Input = {
  status?: InputMaybe<Scalars['String']>;
  user_id_a?: InputMaybe<Scalars['String']>;
  user_id_b?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "user_received_friend_requests" */
export type User_Received_Friend_Requests = {
  __typename?: 'user_received_friend_requests';
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_received_friend_requests" */
export type User_Received_Friend_Requests_Aggregate = {
  __typename?: 'user_received_friend_requests_aggregate';
  aggregate?: Maybe<User_Received_Friend_Requests_Aggregate_Fields>;
  nodes: Array<User_Received_Friend_Requests>;
};

/** aggregate fields of "user_received_friend_requests" */
export type User_Received_Friend_Requests_Aggregate_Fields = {
  __typename?: 'user_received_friend_requests_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Received_Friend_Requests_Max_Fields>;
  min?: Maybe<User_Received_Friend_Requests_Min_Fields>;
};


/** aggregate fields of "user_received_friend_requests" */
export type User_Received_Friend_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Received_Friend_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_received_friend_requests" */
export type User_Received_Friend_Requests_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Received_Friend_Requests_Max_Order_By>;
  min?: InputMaybe<User_Received_Friend_Requests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_received_friend_requests" */
export type User_Received_Friend_Requests_Arr_Rel_Insert_Input = {
  data: Array<User_Received_Friend_Requests_Insert_Input>;
};

/** Boolean expression to filter rows from the table "user_received_friend_requests". All fields are combined with a logical 'AND'. */
export type User_Received_Friend_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<User_Received_Friend_Requests_Bool_Exp>>;
  _not?: InputMaybe<User_Received_Friend_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<User_Received_Friend_Requests_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id_a?: InputMaybe<String_Comparison_Exp>;
  user_id_b?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "user_received_friend_requests" */
export type User_Received_Friend_Requests_Insert_Input = {
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id_a?: InputMaybe<Scalars['String']>;
  user_id_b?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Received_Friend_Requests_Max_Fields = {
  __typename?: 'user_received_friend_requests_max_fields';
  status?: Maybe<Scalars['String']>;
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_received_friend_requests" */
export type User_Received_Friend_Requests_Max_Order_By = {
  status?: InputMaybe<Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Received_Friend_Requests_Min_Fields = {
  __typename?: 'user_received_friend_requests_min_fields';
  status?: Maybe<Scalars['String']>;
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_received_friend_requests" */
export type User_Received_Friend_Requests_Min_Order_By = {
  status?: InputMaybe<Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_received_friend_requests" */
export type User_Received_Friend_Requests_Mutation_Response = {
  __typename?: 'user_received_friend_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Received_Friend_Requests>;
};

/** Ordering options when selecting data from "user_received_friend_requests". */
export type User_Received_Friend_Requests_Order_By = {
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** select columns of table "user_received_friend_requests" */
export enum User_Received_Friend_Requests_Select_Column {
  /** column name */
  Status = 'status',
  /** column name */
  UserIdA = 'user_id_a',
  /** column name */
  UserIdB = 'user_id_b'
}

/** input type for updating data in table "user_received_friend_requests" */
export type User_Received_Friend_Requests_Set_Input = {
  status?: InputMaybe<Scalars['String']>;
  user_id_a?: InputMaybe<Scalars['String']>;
  user_id_b?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "user_sent_friend_requests" */
export type User_Sent_Friend_Requests = {
  __typename?: 'user_sent_friend_requests';
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Aggregate = {
  __typename?: 'user_sent_friend_requests_aggregate';
  aggregate?: Maybe<User_Sent_Friend_Requests_Aggregate_Fields>;
  nodes: Array<User_Sent_Friend_Requests>;
};

/** aggregate fields of "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Aggregate_Fields = {
  __typename?: 'user_sent_friend_requests_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Sent_Friend_Requests_Max_Fields>;
  min?: Maybe<User_Sent_Friend_Requests_Min_Fields>;
};


/** aggregate fields of "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Sent_Friend_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Sent_Friend_Requests_Max_Order_By>;
  min?: InputMaybe<User_Sent_Friend_Requests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Arr_Rel_Insert_Input = {
  data: Array<User_Sent_Friend_Requests_Insert_Input>;
};

/** Boolean expression to filter rows from the table "user_sent_friend_requests". All fields are combined with a logical 'AND'. */
export type User_Sent_Friend_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<User_Sent_Friend_Requests_Bool_Exp>>;
  _not?: InputMaybe<User_Sent_Friend_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<User_Sent_Friend_Requests_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id_a?: InputMaybe<String_Comparison_Exp>;
  user_id_b?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Insert_Input = {
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id_a?: InputMaybe<Scalars['String']>;
  user_id_b?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Sent_Friend_Requests_Max_Fields = {
  __typename?: 'user_sent_friend_requests_max_fields';
  status?: Maybe<Scalars['String']>;
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Max_Order_By = {
  status?: InputMaybe<Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Sent_Friend_Requests_Min_Fields = {
  __typename?: 'user_sent_friend_requests_min_fields';
  status?: Maybe<Scalars['String']>;
  user_id_a?: Maybe<Scalars['String']>;
  user_id_b?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Min_Order_By = {
  status?: InputMaybe<Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Mutation_Response = {
  __typename?: 'user_sent_friend_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Sent_Friend_Requests>;
};

/** Ordering options when selecting data from "user_sent_friend_requests". */
export type User_Sent_Friend_Requests_Order_By = {
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id_a?: InputMaybe<Order_By>;
  user_id_b?: InputMaybe<Order_By>;
};

/** select columns of table "user_sent_friend_requests" */
export enum User_Sent_Friend_Requests_Select_Column {
  /** column name */
  Status = 'status',
  /** column name */
  UserIdA = 'user_id_a',
  /** column name */
  UserIdB = 'user_id_b'
}

/** input type for updating data in table "user_sent_friend_requests" */
export type User_Sent_Friend_Requests_Set_Input = {
  status?: InputMaybe<Scalars['String']>;
  user_id_a?: InputMaybe<Scalars['String']>;
  user_id_b?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  all_decks: Array<Campaign_Deck>;
  /** An aggregate relationship */
  all_decks_aggregate: Campaign_Deck_Aggregate;
  /** An array relationship */
  campaigns: Array<User_Campaigns>;
  /** An aggregate relationship */
  campaigns_aggregate: User_Campaigns_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  decks: Array<Latest_Decks>;
  /** An aggregate relationship */
  decks_aggregate: Latest_Decks_Aggregate;
  /** An array relationship */
  flags: Array<User_Flag>;
  /** An aggregate relationship */
  flags_aggregate: User_Flag_Aggregate;
  /** An array relationship */
  friends: Array<User_Friends>;
  /** An aggregate relationship */
  friends_aggregate: User_Friends_Aggregate;
  handle?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An array relationship */
  local_decks: Array<Local_Decks>;
  /** An aggregate relationship */
  local_decks_aggregate: Local_Decks_Aggregate;
  /** An array relationship */
  received_requests: Array<User_Received_Friend_Requests>;
  /** An aggregate relationship */
  received_requests_aggregate: User_Received_Friend_Requests_Aggregate;
  /** An array relationship */
  sent_requests: Array<User_Sent_Friend_Requests>;
  /** An aggregate relationship */
  sent_requests_aggregate: User_Sent_Friend_Requests_Aggregate;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "users" */
export type UsersAll_DecksArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAll_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Campaign_Deck_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Campaign_Deck_Order_By>>;
  where?: InputMaybe<Campaign_Deck_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCampaignsArgs = {
  distinct_on?: InputMaybe<Array<User_Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Campaigns_Order_By>>;
  where?: InputMaybe<User_Campaigns_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCampaigns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Campaigns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Campaigns_Order_By>>;
  where?: InputMaybe<User_Campaigns_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDecksArgs = {
  distinct_on?: InputMaybe<Array<Latest_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Latest_Decks_Order_By>>;
  where?: InputMaybe<Latest_Decks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDecks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Latest_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Latest_Decks_Order_By>>;
  where?: InputMaybe<Latest_Decks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFlagsArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Order_By>>;
  where?: InputMaybe<User_Flag_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flag_Order_By>>;
  where?: InputMaybe<User_Flag_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFriendsArgs = {
  distinct_on?: InputMaybe<Array<User_Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Friends_Order_By>>;
  where?: InputMaybe<User_Friends_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFriends_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Friends_Order_By>>;
  where?: InputMaybe<User_Friends_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLocal_DecksArgs = {
  distinct_on?: InputMaybe<Array<Local_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Local_Decks_Order_By>>;
  where?: InputMaybe<Local_Decks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLocal_Decks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Local_Decks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Local_Decks_Order_By>>;
  where?: InputMaybe<Local_Decks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReceived_RequestsArgs = {
  distinct_on?: InputMaybe<Array<User_Received_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Received_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Received_Friend_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReceived_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Received_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Received_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Received_Friend_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSent_RequestsArgs = {
  distinct_on?: InputMaybe<Array<User_Sent_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sent_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Sent_Friend_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSent_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Sent_Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Sent_Friend_Requests_Order_By>>;
  where?: InputMaybe<User_Sent_Friend_Requests_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  all_decks?: InputMaybe<Campaign_Deck_Bool_Exp>;
  campaigns?: InputMaybe<User_Campaigns_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  decks?: InputMaybe<Latest_Decks_Bool_Exp>;
  flags?: InputMaybe<User_Flag_Bool_Exp>;
  friends?: InputMaybe<User_Friends_Bool_Exp>;
  handle?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  local_decks?: InputMaybe<Local_Decks_Bool_Exp>;
  received_requests?: InputMaybe<User_Received_Friend_Requests_Bool_Exp>;
  sent_requests?: InputMaybe<User_Sent_Friend_Requests_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  all_decks?: InputMaybe<Campaign_Deck_Arr_Rel_Insert_Input>;
  campaigns?: InputMaybe<User_Campaigns_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  decks?: InputMaybe<Latest_Decks_Arr_Rel_Insert_Input>;
  flags?: InputMaybe<User_Flag_Arr_Rel_Insert_Input>;
  friends?: InputMaybe<User_Friends_Arr_Rel_Insert_Input>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  local_decks?: InputMaybe<Local_Decks_Arr_Rel_Insert_Input>;
  received_requests?: InputMaybe<User_Received_Friend_Requests_Arr_Rel_Insert_Input>;
  sent_requests?: InputMaybe<User_Sent_Friend_Requests_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  all_decks_aggregate?: InputMaybe<Campaign_Deck_Aggregate_Order_By>;
  campaigns_aggregate?: InputMaybe<User_Campaigns_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  decks_aggregate?: InputMaybe<Latest_Decks_Aggregate_Order_By>;
  flags_aggregate?: InputMaybe<User_Flag_Aggregate_Order_By>;
  friends_aggregate?: InputMaybe<User_Friends_Aggregate_Order_By>;
  handle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  local_decks_aggregate?: InputMaybe<Local_Decks_Aggregate_Order_By>;
  received_requests_aggregate?: InputMaybe<User_Received_Friend_Requests_Aggregate_Order_By>;
  sent_requests_aggregate?: InputMaybe<User_Sent_Friend_Requests_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type UserInfoFragment = { __typename?: 'users', id: string, handle?: string | null | undefined };

export type UserProfileFragment = { __typename?: 'users', id: string, handle?: string | null | undefined, friends: Array<{ __typename?: 'user_friends', user?: { __typename?: 'users', id: string, handle?: string | null | undefined } | null | undefined }>, sent_requests: Array<{ __typename?: 'user_sent_friend_requests', user?: { __typename?: 'users', id: string, handle?: string | null | undefined } | null | undefined }>, received_requests: Array<{ __typename?: 'user_received_friend_requests', user?: { __typename?: 'users', id: string, handle?: string | null | undefined } | null | undefined }> };

export type GetHandlesQueryVariables = Exact<{
  userIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetHandlesQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, handle?: string | null | undefined }> };

export type GetProfileQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetProfileQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: string, handle?: string | null | undefined, friends: Array<{ __typename?: 'user_friends', user?: { __typename?: 'users', id: string, handle?: string | null | undefined } | null | undefined }>, sent_requests: Array<{ __typename?: 'user_sent_friend_requests', user?: { __typename?: 'users', id: string, handle?: string | null | undefined } | null | undefined }>, received_requests: Array<{ __typename?: 'user_received_friend_requests', user?: { __typename?: 'users', id: string, handle?: string | null | undefined } | null | undefined }> } | null | undefined };

export const UserInfoFragmentDoc = gql`
    fragment UserInfo on users {
  id
  handle
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on users {
  id
  handle
  friends {
    user {
      ...UserInfo
    }
  }
  sent_requests {
    user {
      ...UserInfo
    }
  }
  received_requests {
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;
export const GetHandlesDocument = gql`
    query getHandles($userIds: [String!]!) {
  users(where: {id: {_in: $userIds}}) {
    id
    handle
  }
}
    `;

/**
 * __useGetHandlesQuery__
 *
 * To run a query within a React component, call `useGetHandlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandlesQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetHandlesQuery(baseOptions: Apollo.QueryHookOptions<GetHandlesQuery, GetHandlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHandlesQuery, GetHandlesQueryVariables>(GetHandlesDocument, options);
      }
export function useGetHandlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHandlesQuery, GetHandlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHandlesQuery, GetHandlesQueryVariables>(GetHandlesDocument, options);
        }
export type GetHandlesQueryHookResult = ReturnType<typeof useGetHandlesQuery>;
export type GetHandlesLazyQueryHookResult = ReturnType<typeof useGetHandlesLazyQuery>;
export type GetHandlesQueryResult = Apollo.QueryResult<GetHandlesQuery, GetHandlesQueryVariables>;
export const GetProfileDocument = gql`
    query getProfile($userId: String!) {
  users_by_pk(id: $userId) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    