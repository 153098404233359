import React, { useState, MouseEvent, useMemo } from 'react';
import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import { t } from 'ttag.macro';
import { find, map } from 'lodash';

import { firebaseAuth } from '../firebase';
import { useAuth } from '../AuthContext';

const LANGUAGES = [
  {
    code: 'en',
    lang: 'English',
  },
  {
    code: 'es',
    lang: 'Español',
  },
  {
    code: 'de',
    lang: 'Deutsche',
  },
  {
    code: 'fr',
    lang: 'Français',
  },
  {
    code: 'ko',
    lang: '한국어',
  },
  {
    code: 'ru',
    lang: 'Русский',
  }
];
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function NavBar() {
  const { pathname, search } = useLocation();
  const lang = useMemo(() => {
    const split = window.location.host.split('.');
    if (split.length < 3) {
      return 'en';
    }
    return split[0];
  }, []);
  const language = find(LANGUAGES, ({ code }) => code === lang)?.lang;
  const [anchorLangEl, setAnchorLangEl] = useState<Element | null>(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const classes = useStyles();
  const handleCloseLang = () => {
    setAnchorLangEl(null);
  };
  const handleLangOpen = (event: MouseEvent) => {
    setAnchorLangEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const menuLogout = () => {
    firebaseAuth.signOut();
    setAnchorEl(null);
  };
  const setLang = (code: string) => {
    const subDomain = code === 'en' ? '' : `${code}.`;
    window.location.href = `https://${subDomain}arkhamcards.com/${pathname}${search ? ('?' + search) : ''}`;
  };
  const { loading, authUser } = useAuth();
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={anchorEl ? handleClose : handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Arkham Cards
        </Typography>

        <Button
          color="inherit"
          className={classes.menuButton}
          aria-label="language"
          onClick={anchorLangEl ? handleCloseLang : handleLangOpen}
        >
          { t`Language (${language})` }
        </Button>
        <Menu
          id="nav-lang"
          anchorEl={anchorLangEl}
          keepMounted
          open={!!anchorLangEl}
          onClose={handleCloseLang}
        >
          { map(LANGUAGES, ({ code, lang }) => (
            <MenuItem key={code} onClick={() => setLang(code)}>{lang}</MenuItem>
          )) }
        </Menu>
        { !loading && !authUser && (
          <Button color="inherit" component={Link} to="/login">
            {t`Login`}
          </Button>
        ) }
      </Toolbar>
      <Menu
        id="nav-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to="/">{t`Home`}</MenuItem>
        { !loading && !!authUser ? (
          <>
            <MenuItem onClick={handleClose} component={Link} to={`/u?uid=${authUser.uid}`}>
              {t`Profile`}
            </MenuItem>
            <MenuItem onClick={menuLogout}>{t`Logout`}</MenuItem>
          </>
        ) : (
          <MenuItem onClick={handleClose} component={Link} to="/login">{t`Login`}</MenuItem>
        ) }
        <MenuItem onClick={handleClose} component={Link} to="/about">{t`About`}</MenuItem>
      </Menu>
    </AppBar>
  );
}

export default NavBar;
