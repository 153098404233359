import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  closeButton: {
    color: 'white',
  },
  row: {
    flexDirection: 'row',
  },
  truncatedList: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 250,
  },
  card: {
    minWidth: 350,
    maxWidth: 400,
  },
  media: {
    height: 120,
  },
  paper: {
    position: 'absolute',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    minWidth: 200,
  },
  greenContent: {
    'background-color': "#cae0d4",
    borderRadius: 8,
  },
  blueContent: {
    'background-color': "#cbe1f2",
    borderRadius: 8,
  },
  redContent: {
    'background-color': "#dbc6c5",
    borderRadius: 8,
  },
}));