import React from 'react';
import { ArkhamEvent } from '../../../lib/schema';
import PendingGameControls from './PendingGameControls';
import BlobGameControls from './BlobGameControls';
import WarGameControls from './WarGameControls';

interface Props {
  event: ArkhamEvent;
  eventId: string;
  uid?: string;
  isOrganizer: boolean;
}
export default function GameControls({ event, eventId, uid, isOrganizer }: Props) {

  switch (event.gameType) {
    case 'war':
      if (!event.gameState) {
        return (
          <PendingGameControls
            event={event}
            eventId={eventId}
            uid={uid}
            isOrganizer={isOrganizer}
          />
        );
      }
      return (
        <WarGameControls
          event={event}
          eventId={eventId}
          gameState={event.gameState}
          isOrganizer={isOrganizer}
          uid={uid}
        />
      );
    case 'blob':
    case 'blobg':
    default:
      if (!event.gameState) {
        return (
          <PendingGameControls
            event={event}
            eventId={eventId}
            uid={uid}
            isOrganizer={isOrganizer}
          />
        );
      }
      return (
        <BlobGameControls
          event={event}
          eventId={eventId}
          gameState={event.gameState}
          isOrganizer={isOrganizer}
          uid={uid}
        />
      );
    }
}