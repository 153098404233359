import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';

import { firebaseAuth } from './firebase';

async function getHasuraToken() {
  const user = firebaseAuth.currentUser;
  if (user) {
    return await user.getIdToken();
  }
  return undefined;

}
const authLink = setContext(async(req, { headers }) => {
  const hasuraToken = await getHasuraToken();
  if (hasuraToken) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${hasuraToken}`,
      },
    };
  }
  return { headers };
});

const wsLink = new WebSocketLink(
  new SubscriptionClient(
    `wss://gapi.arkhamcards.com/v1/graphql`, {
      reconnect: true,
      lazy: true,
      connectionParams: async() => {
        const hasuraToken = await getHasuraToken();
        if (!hasuraToken) {
          console.log('*********\nno hasura token for subscription....\n**********');
        }
        return {
          headers: hasuraToken ? {
            Authorization: `Bearer ${hasuraToken}`,
          } : {},
        };
      },
    }
  ),
);

const httpsLink = authLink.concat(new HttpLink({
  uri: `https://gapi.arkhamcards.com/v1/graphql`,
}));

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpsLink
);
const client = new ApolloClient({
  link: ApolloLink.from([
    link,
  ]),
  cache: new InMemoryCache(),
  assumeImmutableResults: true,
});

export default client;
