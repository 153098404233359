import React, { useState } from 'react';
import { find, flatMap, map, values } from 'lodash';
import { child, ref, set, push, remove } from 'firebase/database';
import { Box, Button, Divider, Grid, List, ListItem, ListItemIcon,
  ListItemText, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility'
import { t } from 'ttag.macro';

import { firebaseDatabase } from '../../../firebase';
import { ArkhamEvent, BlobEvent, BlobGameState, GroupBlobUpdateType } from '../../../lib/schema';
import MediaCard from '../../core/MediaCard';
import SubmitButton from '../../core/SubmitButton';
import Announcements from './Announcements';
import { apiFunction } from '../../../lib/api';

interface Props {
  eventId: string;
  event: BlobEvent;
  isOrganizer: boolean;
  gameState: BlobGameState;
  uid?: string;
}

function GameInputControl({ eventId, label, updateType, disabled, fixedValue, noDivider }: {
  eventId: string;
  label: string;
  updateType: GroupBlobUpdateType;
  disabled?: boolean;
  fixedValue?: number;
  noDivider?: boolean;
}) {
  const [value, setValue] = useState(0);
  const send = async () => {
    const data = {
      eventId,
      updateType,
      value: fixedValue || value,
    };
    const recordGroupBlobProgress = apiFunction('event-recordGroupBlobProgress');
    const result = await recordGroupBlobProgress(data);
    const {
      error,
      message,
    } = result.data;
    if (error) {
      throw new Error(error);
    }
    setValue(0);
    return message;
  };
  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value, 10));
  };
  return (
    <Box mb={2} pt={noDivider ? 2 : 0}>
      { !noDivider && <Box mt={2} mb={3}><Divider /></Box> }
      <Grid container alignItems="flex-start" direction="column" spacing={0}>
        { !fixedValue && (
          <Grid item xs={12} sm={6}>
            <Typography color="textSecondary">
              { label }
            </Typography>
          </Grid>
        ) }
        <Grid item container direction="row" alignItems="flex-end" spacing={4}>
          { !fixedValue && (
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={value}
                onChange={handleFormChange}
              />
            </Grid>
          ) }
          <Grid item xs={12} sm={fixedValue ? 12 : 6}>
            <SubmitButton
              variant={fixedValue ? 'outlined' : 'contained'}
              color="secondary"
              onSubmit={send}
              fullWidth
              disabled={disabled || (!fixedValue && value === 0)}
            >
              {fixedValue ? label : 'Send'}
            </SubmitButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
const SIDE_MISSIONS = [
  t`Rescue the Chemist`,
  t`Recover the Sample`,
  t`Drive Off the Mi-Go`,
  t`Diffuse the Explosives`,
  t`Escort the Car`,
  t`Reclaim the Brain`,
  t`Prevent Their Research`,
];

const CUSTOM_SIDE_MISSIONS = [
  t`Inside the Blob`,
  t`Dance of the Bricks`,
  t`The Great Ooze Flood`,
  t`Devourer of Ages`,
];

function HiddenSideMission({ selection, isOrganizer, missions }: {
  selection: number;
  isOrganizer: boolean;
  missions: string[];
}) {
  const [revealed, setRevealed] = useState(isOrganizer);
  const handleClick = () => {
    setRevealed(true);
  };
  if (revealed) {
    return (
      <ListItem>
        <ListItemText
          primary={missions[selection]}
        />
      </ListItem>
    );
  }
  return (
    <ListItem button component={Button} onClick={handleClick}>
      <ListItemText
        primary={t`Reveal Act 3b story`}
      />
      <ListItemIcon>
        <VisibilityIcon color="secondary" />
      </ListItemIcon>
    </ListItem>
  );
}

function SideMissionSection({ event, eventId, gameState, isOrganizer, uid }: Props) {
  const allMissions = event.customGame ? CUSTOM_SIDE_MISSIONS : SIDE_MISSIONS;
  const [nextMission, setNextMission] = useState<number>(-1);
  const handleMissionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNextMission(event.target.value as number);
  };
  const sendMission = async () => {
    if (!gameState.missions) {
      await set(child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'missions'), [nextMission]);
    } else {
      await push(child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'missions'), nextMission);
    }
    setNextMission(1)
    return undefined;
  };

  const missions = values(gameState.missions || {});
  const usedMissions = new Set(missions);
  if (event.gameType === 'blobg') {
    return null;
  }
  return (
    <>
      { (!!missions.length || isOrganizer) && (
        <Box my={2}>
          <Divider />
        </Box>
      ) }
      { !!missions.length && (
        <>
          <Typography gutterBottom color="textSecondary">
            { t`Act 3b Story Cards` }
          </Typography>
          <List>
            { map(missions, (mission, idx) => (
              <HiddenSideMission key={idx} selection={mission} isOrganizer={isOrganizer} missions={allMissions} />
            )) }
          </List>
        </>
      ) }
      { isOrganizer && (
        <>
          <Box my={1}>
            <Typography color="textSecondary">
              { t`Choose Act 3b Story` }
            </Typography>
          </Box>
          <Box mb={2}>
            <Select
              value={nextMission}
              fullWidth
              onChange={handleMissionChange}
            >
              <MenuItem value={-1}>
                <em>None</em>
              </MenuItem>
              { flatMap(allMissions, (mission, idx) =>
                usedMissions.has(idx) ? null : (
                  <MenuItem key={idx} value={idx}>{mission}</MenuItem>
                )
              ) }
            </Select>
          </Box>
          { nextMission !== -1 && (
            <SubmitButton
              variant="outlined"
              color="secondary"
              onSubmit={sendMission}
              fullWidth
            >
              { t`Send Selection` }
            </SubmitButton>
          ) }
        </>
      ) }
    </>
  );
}

function ClueBlock({ event, eventId, gameState, isOrganizer, uid }: Props) {
  const advanceAct1 = async () => {
    if (gameState.clueGoal) {
      if (!gameState.completedClueGoal) {
        await set(child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'completedClueGoal'), [gameState.clueGoal]);
      } else {
        await push(child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'completedClueGoal'), gameState.clueGoal);
      }
      await remove(child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'clueGoal'));
    }
    const sendAnnouncementFunction = apiFunction('event-sendAnnouncement');
    await sendAnnouncementFunction({ eventId, message: t`Act 1 global clue threshold has been reached! Make sure to advance act 1 when you start your next round.`, });
    return undefined;
  };

  const resetAct1 = async () => {
    if (!gameState.clueGoal) {
      await set(child(child(child(ref(firebaseDatabase, '/blobs'), eventId), 'gameState'), 'clueGoal'), {
        goal: (gameState.playerCount || 1) * 2,
        clues: 0,
      });
    }
    return undefined;
  };

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Box m={2}>
        <MediaCard image="/img/blob_science.png" imageTitle={t`Blob being examined with scientified instruments.`} title={t`Act 1 Clues`}>
          { gameState.clueGoal ? (
            <>
              <Typography>
                {gameState.clueGoal.clues} / {gameState.clueGoal.goal}
              </Typography>
              { isOrganizer && gameState.clueGoal.clues === gameState.clueGoal.goal && (
                <Box my={2}>
                  <SubmitButton
                    variant="contained"
                    color="secondary"
                    onSubmit={advanceAct1}
                    fullWidth
                  >
                    { t`Send announcement to advance act 1` }
                  </SubmitButton>
                </Box>
              ) }
              { uid && (
                <GameInputControl
                  eventId={eventId}
                  label={t`Add act 1 clues`}
                  updateType={GroupBlobUpdateType.CLUE}
                  disabled={!gameState.clueGoal}
                />
              ) }
            </>
          ) : (
            <>
              <Typography gutterBottom>
                { t`No current shared clue goal` }
              </Typography>
              { isOrganizer && (
                <>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Box my={2}>
                    <SubmitButton
                      variant="outlined"
                      color="secondary"
                      onSubmit={resetAct1}
                      fullWidth
                    >
                      { t`Reset shared act 1 clue goal` }
                    </SubmitButton>
                  </Box>
                </>
              ) }
            </>
          ) }
          <SideMissionSection event={event} eventId={eventId} gameState={gameState} isOrganizer={isOrganizer} uid={uid} />
        </MediaCard>
      </Box>
    </Grid>
  );
}

function DamageBlock({ eventId, gameState, uid }: { eventId: string; gameState: BlobGameState; uid?: string }) {
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Box m={2}>
        <MediaCard image="/img/blob_header.png" imageTitle={t`A blob consuming a car and a house.`} title={t`8L-08 Damage`}>
          <Typography>
            {gameState.damage} / {gameState.health}
          </Typography>
          { !!uid && (
            <GameInputControl
              eventId={eventId}
              label={t`Deal damage`}
              updateType={GroupBlobUpdateType.DAMAGE}
            />
          ) }
        </MediaCard>
      </Box>
    </Grid>
  );
}


function CountermeasuresBlock({ eventId, gameState, uid }: { eventId: string; gameState: BlobGameState; uid?: string }) {
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Box m={2}>
        <MediaCard image="/img/blob_man.png" imageTitle={t`A man tries to escape the grasp of a blob.`} title={t`Available Countermeasures`}>
          <Typography>
            {gameState.countermeasures}
          </Typography>
          { !!uid && (
            <GameInputControl
              eventId={eventId}
              label={t`Add countermeasures`}
              updateType={GroupBlobUpdateType.COUNTERMEASURE}
            />
          ) }
          { !!uid && (
            <GameInputControl
              eventId={eventId}
              fixedValue={-1}
              noDivider
              label={gameState.countermeasures === 0 ? t`No countermeasures available` : t`Use a countermeasure`}
              updateType={GroupBlobUpdateType.COUNTERMEASURE}
              disabled={gameState.countermeasures === 0}
            />
          ) }
        </MediaCard>
      </Box>
    </Grid>
  );
}

export default function BlobGameControls({ event, eventId, gameState, isOrganizer, uid }: Props) {
  const isParticipant = !!find(values(event.participants || {}), participant => participant.user === uid);
  const participantUid = isParticipant || isOrganizer ? uid : undefined;
  return (
    <Grid container>
      <Announcements event={event} eventId={eventId} gameState={gameState} isOrganizer={isOrganizer} uid={participantUid} />
      <DamageBlock eventId={eventId} gameState={gameState} uid={participantUid} />
      <ClueBlock event={event} eventId={eventId} gameState={gameState} isOrganizer={isOrganizer} uid={participantUid} />
      { event.gameType !== 'blobg' && (
        <CountermeasuresBlock eventId={eventId} gameState={gameState} uid={participantUid} />
      )}
    </Grid>
  );
}
